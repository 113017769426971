import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import '@ant-design/pro-form/dist/form.css' ;
import '@ant-design/pro-layout/dist/layout.css' ;
import './styleEditarIntegrador.scss';

import React, {useState, useEffect, useRef} from 'react';
import {
    notificacaoErroRequest, notificacaoErroValidForm,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao,
    useStateRef,
    useWindowSize
} from "../../lib/iy2b-react";
import Promessas from "../../lib/Promessas";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {postJSON} from "../../lib/RequestAPI";
import {campoInformado, ObjectUtils} from "../../lib/iy2b-javascript";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {useFormState} from "../../lib/useFormState";
import {Button, notification, Popconfirm, Space} from "antd";
import ProForm, {ProFormText, ProFormTextArea} from "@ant-design/pro-form";
import NavegadorRegistro from "../../componentes/NavegadorRegistro/navegadorRegistro";
import ComboBox from "../../componentes/proform/ComboBox/comboBox";
import IY2BReactModal from "../../componentes/IY2BReactModal";
import RadioGroup from "../../componentes/proform/RadioGroup/radioGroup";

const EditarIntegrador = ( props ) => {

    const { exibePopUp, onRequestClose, salvar, excluir, dataProvider } = props;

    const { registros } = props;

    const windowSize = useWindowSize();
    const [ gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.compare(window._SM_)<=0) ? 10 : 30;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = 66;
    const MARGIN_BOTTOM = 24;

    const modalRefApi = useRef();
    const formRef = useRef();
    const navegadorRefApi = useRef();

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const [, setRegistroDaTela, refRegistroDaTela] = useStateRef( null);
    const [, setTelaProtegida, refTelaProtegida] = useStateRef(false);

    const forceUpdate = useForceUpdate();
    const formState = useFormState({ });

    useEffect(() => {

        if(campoInformado(registros)) {

            if(exibePopUp) {

                if(registros.length > 0) {

                    pesquisarPk(registros[0]);

                } else {

                    novoRegistro();

                }

            }

        } else {

            if(exibePopUp) {

                novoRegistro();

            }

        }

        return () => {

        };

    }, [props.registros]);

    const pesquisarPk = async (registro) => {

        try {

            let lModo = "INC";

            novoRegistro();

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: {
                    cdPessoaEmp: dataProvider.cdPessoaEmpAtual,
                    "cdIntegrador": registro.cdIntegrador
                },
            };

            let registroDaTela = {
            };

            const promessas = new Promessas();

            promessas.add(async (resolve, reject) => {
                try {

                    const resultado = await postJSON({
                        acaoWebAExecutar: "pesquisarPk",
                        sistema: "sgr",
                        projeto: "mintegradorapiemp"
                    }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

                    resultado.mensagens.forEach(msg => {

                        if(msg.id === "modelo") {

                            registroDaTela = {
                                ...registroDaTela,
                                ...msg.modeloPadrao
                            };

                            lModo = "ALT";

                        } else {

                            console.log(msg);

                        }

                    });

                    resolve(true);

                } catch (e) {

                    if(e.data?.id === "pkNaoEncontrada") {

                        registroDaTela = {
                            cdPessoaEmp: dataProvider.cdPessoaEmpAtual,
                            "cdIntegrador": registro.cdIntegrador,
                            "token_type": "API KEY"
                        }

                        resolve(true);

                    } else {

                        console.log(e);

                        notificacaoErroRequest(e);

                        reject (e);

                    }

                }
            });

            await promessas.run();

            setRegistroNaTela(registroDaTela);

            if(lModo === "INC") {

                modoExecucao.setModoExecInclusao();

            }

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e);

        }

    }

    const setRegistroNaTela = (registro) => {

        console.log(registro);

        const registroDaTela = {
            ...ObjectUtils.valuesFrom(registro, ["access_token", "scopes", "token_type"]),
        };

        registroDaTela.cmbCdIntegrador = dataProvider.dpIntegrador.getBy("value", registro.cdIntegrador);

        setRegistroDaTela ( registroDaTela );

        formRef.current?.setFieldsValue( registroDaTela );

        forceUpdate();

        controleExibicao ( registroDaTela );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    };

    const controleExibicao = (registro) => {


    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

        }, 1);

    }

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, ["access_token", "scopes", "token_type"]),
            cdPessoaEmp: dataProvider.cdPessoaEmpAtual,
            cdIntegrador: ffValues.cmbCdIntegrador.value,
        }

        console.log(registro);

        return registro;

    }

    const doExcluir = async () => {

        const registro = getRegistroDaTela();

        const { cdIntegrador, cdPessoaEmp } = registro;

        await excluir ({
            cdPessoaEmp,
            cdIntegrador
        });

    }

    const novoRegistro = () => {

        console.log("novoRegistro");

        formRef?.current?.resetFields();

        setTelaProtegida ( false ) ;

        setRegistroDaTela ( {
        });

        formState.clear({ });

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const validaTela = async (params) => {

        let resultado = true;

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notification.warning({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const resultado = await salvar (registro);

            if(resultado === false) return;

            onRequestClose();

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e);

        }

    }

    const navegaParaRegistro = async (posicao) => {

        await pesquisarPk(registros[posicao - 1]);

    }

    const popupTitle = "Configurar integração";

    const fiValues = {
        "token_type": "API KEY"
    }

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={MARGIN_BOTTOM}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"editar-integrador-api"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-editar-integrador-api"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={fiValues}
                        onFinish = { async ( ) => {
                            if(refTelaProtegida.current === true) return;
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {

                        }}
                        onFieldsChange = {(changedFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <ComboBox
                                    width={"lg"}
                                    formRef = { formRef }
                                    name = "cmbCdIntegrador"
                                    label = "Integração"
                                    tooltip = "Configuração desta integração"
                                    placeholder={"Integração"}
                                    options={dataProvider.dpIntegrador}
                                    rules={[
                                        {required: true, message:"Informe "},
                                    ]}
                                    disabled
                                />
                            </ProForm.Group>
                            <ProForm.Group>
                                <RadioGroup
                                    width = {"sm"}
                                    name="token_type"
                                    label="Tipo"
                                    tooltip = { "Tipo de acesso" }
                                    rules={[
                                        {required: true, message: 'Escolha um tipo de acesso'},
                                    ]}
                                    forceUpdate={forceUpdate}
                                    options={[
                                        {
                                            label: 'API Key',
                                            value: 'API KEY',
                                        }
                                    ]}
                                />
                            </ProForm.Group>
                            <ProForm.Group>
                                <ProFormText
                                    width={"xl"}
                                    name = "access_token"
                                    label = "Token de acesso"
                                    tooltip = "Token de acesso"
                                    placeholder = "Digite o token de acesso a este integrador"
                                    rules={[
                                        {required: true, message:"Informe o nome impresso no cartão de crédito"},
                                        {message:"No máximo 256 caracteres", max:256}
                                    ]}
                                />
                            </ProForm.Group>
                            <ProForm.Group>
                                <ProFormTextArea
                                    width={"xl"}
                                    name = "scopes"
                                    label = "Permissões"
                                    tooltip = "Informe as permissões separadas por vírgula"
                                    placeholder = "Digite as permissões"
                                    rules={[
                                        {message:"No máximo 4000 caracteres", max:4000}
                                    ]}
                                    fieldProps={{
                                        showCount: true,
                                    }}
                                    disabled={refTelaProtegida.current}
                                />
                            </ProForm.Group>

                        </div>
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        <NavegadorRegistro
                            api={navegadorRefApi}
                            registros={registros}
                            navegaPara={navegaParaRegistro}
                        />
                    </Space>
                    <Space>
                        {modoExecucao.isAlteracao() ?
                            <Popconfirm
                                title="Confirma a exclusão deste registro?"
                                onConfirm={async () => {
                                    await doExcluir();
                                }}
                                okText="Sim, excluir"
                                cancelText="Não"
                            >
                                <Button type={"danger"} className={"excluir"}>
                                    Excluir
                                </Button>
                            </Popconfirm>
                            :null}
                        &nbsp;
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()} disabled={refTelaProtegida.current}>
                            Salvar
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default EditarIntegrador;
