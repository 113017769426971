import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import './style.scss';

import React, {useState, useEffect} from 'react';
import ReactModal from 'react-modal';
import {Button} from 'antd';

const Apresentacao = ( props ) => {

    const { exibir, onRequestClose } = props;

    const [etapaAtual, setEtapaAtual] = useState(0);

    useEffect(() => {

        if(exibir === true) {
            setEtapaAtual(0);
        }

        props.onExibeAcao("escondeDropdownUsuario");
        props.onExibeAcao("escondeDropdownChecklist");
        props.onExibeAcao("escondeMenuTransacao");

    }, [exibir]);

    const encerrar = () => {
        props.onExibeAcao("escondeDropdownUsuario");
        props.onExibeAcao("escondeDropdownChecklist");
        props.onExibeAcao("escondeMenuTransacao");
        setEtapaAtual(0);
    }

    const SLIDE_ANTERIOR =
        <div className={"seta seta-anterior"}>
            <Button onClick={() => {setEtapaAtual(etapaAtual - 1)}}>
                Slide anterior
            </Button>
        </div>;

    const PROXIMO_SLIDE =
        <div className={"seta seta-proximo"}>
            <Button onClick={() => {setEtapaAtual(etapaAtual + 1)}}>
                Próximo slide
            </Button>
        </div>;

    const FOOTER =
        <div className={"footer"}>
            <Button type={"default"}
                    onClick={() => {
                        encerrar();
                        onRequestClose();
                    }}
            >
                Encerrar a apresentação
            </Button>
        </div>;

    const etapas = [
    <>
        <div className={"body"}>
            <div className={"display"}>
                <div className={"seta"}>
                </div>
                <div className={"etapa inicial"}>
                    Seja bem-vindo ao Portal IY2B!
                    A seguir uma breve explicação sobre usabilidade da plataforma.
                    Você pode exibir esta apresentação sempre que você desejar.
                    Basta abrir o menu "O que desejo fazer".
                </div>
                {PROXIMO_SLIDE}
            </div>
            {FOOTER}
        </div>
    </>, <>
        <div className={"body"}>
            <div className={"display"} style={{top:405}}>
                {SLIDE_ANTERIOR}
                <div className={"etapa perfilUsr"}>
                    Acesse o seu perfil de usuário, na barra superior, canto direito da tela.
                    O seu perfil permite ajustar seus dados pessoais e outras tarefas conforme o seu nível de permissão dentro da plataforma.
                </div>
                {PROXIMO_SLIDE}
            </div>
            {FOOTER}
        </div>
    </>, <>
        <div className={"body"}>
            <div className={"display"} style={{top:105}}>
                {SLIDE_ANTERIOR}
                <div className={"etapa checklist"}>
                    Acesse a opção da barra superior "O que desejo fazer", ao lado do nome da sua empresa.
                    Nesta funcionalidade é possível pesquisar a forma de efetuar as mais diversas operações dentro do Portal. De forma orientada e direta para a rápida execução da tarefa desejada.
                </div>
                {PROXIMO_SLIDE}
            </div>
            {FOOTER}
        </div>
    </>, <>
        <div className={"body"}>
            <div className={"display"} style={{top:105}}>
                {SLIDE_ANTERIOR}
                <div className={"etapa menususpenso"}>
                    Acesse o menu de operações, é a opção do canto esquerdo da barra superior, o "famoso hamburguer".
                    É aqui aonde você encontra todas as tarefas/operações que você tem acesso na plataforma. Basta navegar e encontrar o que você precisa fazer.
                </div>
                {PROXIMO_SLIDE}
            </div>
            {FOOTER}
        </div>
    </>, <>
        <div className={"body"}>
            <div className={"display"}>
                {SLIDE_ANTERIOR}
                <div className={"etapa final"}>
                    Este foi uma breve apresentação das formas de acesso as funcionalidades do Portal. Navegue e descubra !!!
                </div>
                <div className={"seta"}>
                </div>
            </div>
            {FOOTER}
        </div>
    </>];

    if(etapaAtual === 0) {
        props.onExibeAcao("escondeDropdownUsuario");
        props.onExibeAcao("escondeDropdownChecklist");
        props.onExibeAcao("escondeMenuTransacao");
    } else if(etapaAtual === 1) {
        props.onExibeAcao("exibeDropdownUsuario");
        props.onExibeAcao("escondeDropdownChecklist");
    } else if(etapaAtual === 2) {
        props.onExibeAcao("escondeDropdownUsuario");
        props.onExibeAcao("exibeDropdownChecklist");
        props.onExibeAcao("escondeMenuTransacao");
    } else if(etapaAtual === 3) {
        props.onExibeAcao("escondeDropdownChecklist");
        props.onExibeAcao("exibeMenuTransacao");
    } else if(etapaAtual === 4) {
        props.onExibeAcao("escondeDropdownUsuario");
        props.onExibeAcao("escondeDropdownChecklist");
        props.onExibeAcao("escondeMenuTransacao");
    }

    return (
        <ReactModal
            isOpen={exibir}
            className={"modalContent"}
            overlayClassName={"modalOverlay"}
            bodyOpenClassName={"apresentacaoModal"}
            closeTimeoutMS={500}
            shouldFocusAfterRender={true}
            onRequestClose={() => onRequestClose()}
            style={{
                content: {
                    left: '0px',
                    top: '0px',
                }
            }}
        >
            <div className={"containerApresentacaoModal"}>
                {etapas[etapaAtual]}
            </div>
        </ReactModal>
    );

}

export default Apresentacao;
