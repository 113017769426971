import "antd/dist/antd.css";
import '../assets/css/tema.scss';

import React, { useState, useEffect, Suspense } from 'react';
import {connect} from "react-redux";
import {Layout,Alert} from "antd";
import AguardandoTransacao from "./AguardandoTransacao";

const { ErrorBoundary } = Alert;

const { Content } = Layout;

// import {ErrorBoundary} from 'react-error-boundary'
// FallbackComponent={OurFallbackComponent}
const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
    return (
        <div>
            <h1>Infelizmente um erro ocorreu: {error.message}</h1>
            <button onClick={resetErrorBoundary}>Clique aqui para tentar novamente</button>
        </div>
    );
};

const PageContent = props => {

    const [maxHeight, setMaxHeight] = useState(0);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        setMaxHeight(props.availableHeight);

        return () => {

        };

    }, []);

    return (
        <Layout style={{...props.style, height:maxHeight}} id={props.id||""}>
            <Content>
                <ErrorBoundary>
                    <Suspense fallback={<AguardandoTransacao />}>
                        {React.cloneElement(props.children, {loadingArea:  "tc-area-" + props.tcKey})}
                    </Suspense>
                </ErrorBoundary>
            </Content>
        </Layout>
    );
};

const mapStateToProps = function( { userSession, dataProvider } ) {

    return {
        userSession: userSession,
        dataProvider: dataProvider
    };

};

export default connect(mapStateToProps)(PageContent);
