import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/IY2BModal.scss';

import React, { useEffect } from 'react';
import {campoInformado} from "../lib/iy2b-javascript";

const IY2BModalBody = props => {

    const GAP = 40;

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("useEffect inicializa IY2BModalBody");

        return () => {

            console.log("final useEffect inicializa IY2BModalBody");

        };

    }, []);

    if(campoInformado(props.renderBody)) {

        return props.renderBody({
            insideModal: true,
            availableHeight: props.availableHeight - GAP - 4
        });

    } else {

        return (
            <div className={"iy2b-modal-body"}
                 style={{
                     height: props.availableHeight - GAP
                 }}
            >
                {React.cloneElement(props.children, { insideModal: true, availableHeight: props.availableHeight - GAP - 4 })}
            </div>
        );

    }

};

export default IY2BModalBody;
