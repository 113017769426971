import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/headerWS.scss';
import logo from "../assets/images/header-logo.png";

import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import {Layout, Image, Avatar, Row, Col, Button, notification} from 'antd';
import UsuarioDropdown from "../componentes/UsuarioDropdown";
import MenuSuspenso from "./MenuSuspenso";
import EmpresaDropdown from "../componentes/EmpresaDropdown";
import { setActive, setActiveAt } from "../reducer/transacaoManager";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import ChecklistProcessoDropdown from "../componentes/CheckListProcessoDropdown/checklistProcessoDropdown";
import {setChecklistProcesso} from "../reducer/suite";
import BotaoWizard from "../componentes/BotaoWizard/botaoWizard";

const { Header } = Layout;

const HeaderWS = props => {

    const { habilitaWizard, onClickWizard } = props ;

    const breaks = useBreakpoint();
    const refMenuSuspensoApi = useRef();
    const usrDropdownApi = useRef();
    const chklDropdownApi = useRef();

    useEffect(() => {

        console.log("HeaderWS rencer inicial concluido");

        props.api.current = {
            clearSession: () => {
                refMenuSuspensoApi.current.resetMenu();
            },
            exibeDropdownUsuario: () => {
                usrDropdownApi?.current?.exibeDropdown();
            },
            escondeDropdownUsuario: () => {
                usrDropdownApi?.current?.escondeDropdown();
            },
            exibeDropdownChecklist: () => {
                chklDropdownApi?.current?.exibeDropdown();
            },
            escondeDropdownChecklist: () => {
                chklDropdownApi?.current?.escondeDropdown();
            },
            exibeMenuTransacao: () => {
                refMenuSuspensoApi?.current?.openMenu();
            },
            escondeMenuTransacao: () => {
                refMenuSuspensoApi?.current?.closeMenu();
            },
        };

        return () => {

        };

    }, []);

    const onSelectEmpresa = (empresa) => {

        if(props.suite.empresaAtual.pessoaEmp.cdPessoa === empresa.pessoaEmp.cdPessoa) {
            // nada
        } else {
            props.onEmpresaChange(empresa);
        }

    }

    const logoSize = (breaks.xs===true)?46:54;
    const btnEmpresaWidth = (breaks.xs===true)?"70px":"425px";

    return (
        <Header id={"headerWS"}>
            <Row align={"middle"} justify={"space-between"} gutter={0}>
                <Col flex={"70px"} >
                    <MenuSuspenso
                        api={refMenuSuspensoApi}
                    />
                </Col>
                <div className={"right-headerws"}>
                    <Col flex={"70px"} className={["display-contents","app-logo"]}>
                        <Image
                            className={"logo"}
                            preview={false}
                            src={logo}
                            width={logoSize} height={logoSize}
                        />
                    </Col>
                    <Col flex={"auto"} className={"align-end"}>
                    </Col>
                    {/*
                    <Col flex={"70px"} className={"align-center"}>
                        <AdsDropdown dsPeca={props.suite.listaPeca} onVerPeca={props.onVerPeca}/>
                    </Col>
                    */}
                    {(habilitaWizard===true)?
                        <Col flex={"70px"} className={"align-center headerContainerBotaoWizard"}>
                            <BotaoWizard
                                opcoes = {props.opcoesWizard}
                                onClick = {onClickWizard}
                            />
                        </Col>
                        : null
                    }
                    <Col flex={"70px"} className={"align-center"}>
                        <ChecklistProcessoDropdown
                            onVerCheckList={(checklist) => {
                                props.onVerCheckList(checklist);
                            }}
                            onIniciarApresentacao={() => {
                                props.onIniciarApresentacao();
                            }}
                            api={chklDropdownApi}
                        />
                    </Col>
                    {/*
                    <Col flex={"70px"} className={"align-center"}>
                        <NotificacaoDropdown />
                    </Col>
                    */}
                    <Col flex={btnEmpresaWidth} className={"align-center"}>
                        <EmpresaDropdown
                            empresaAtual={props.suite.empresaAtual}
                            dataSource={props.suite.listaEmp}
                            onSelect={onSelectEmpresa}
                        />
                    </Col>
                    <Col flex={"72px"} className={"align-center"}>
                        <UsuarioDropdown
                            suite={props.suite}
                            userSession={props.userSession}
                            acessosEspeciais={props.transacaoManager.acessosEspeciais}
                            onLogout={props.onLogout}
                            onMeuPerfil={() => props.setActive(85061)}
                            onMeuPlano={() => props.setActive(85062)}
                            onMinhasEmpresas={() => props.setActive(85063)}
                            onMeusUsuarios={() => props.setActive(85064)}
                            api={usrDropdownApi}
                        />
                    </Col>
                </div>
            </Row>
        </Header>
    );

};

const mapStateToProps = function( { userSession, suite, transacaoManager } ) {

    return {
        userSession: userSession,
        suite: suite,
        transacaoManager: transacaoManager
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setActive: (key) => {
            return dispatch( setActive(key) );
        },
        setActiveAt: (position, key) => {
            return dispatch( setActiveAt(position, key) );
        },
        setChecklistProcesso: (checklist) => {
            return dispatch( setChecklistProcesso(checklist) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWS);

