import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
import "./index.scss";

import React, {useEffect, useRef, useState} from "react";

import {ProFormSelect} from '@ant-design/pro-form' ;
import {useGridWidth} from "../../../lib/iy2b-react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {campoInformado} from "../../../lib/iy2b-javascript";

const defaultRender = item => ({
    value: item.value,
    label: item.label,
    data: item.data
});

const ComboBox = (props) => {

    const [ gridWidth ] = useGridWidth();

    const refCmb = useRef();

    const { formRef, options = [], onSelect, onChange, onClear, renderOptions = defaultRender, visible=true, disabled=false, name, numRow=null, ffrDisabled=undefined, dynamic=false } = props;

    const forceUpdate = useForceUpdate();

    const [localDisabled, setLocalDisabled] = useState(disabled);

    const myWidth = (props.width==="auto") ? (
            (gridWidth.compare(window._SM_)<=0) ? "lg"
                : (gridWidth.compare(window._LG_)<=0) ? "md"
                    : (gridWidth.compare(window._XL_)<0) ? "lg"
                        : "xl")
        : ((props.width==="auto-3") ? (
                (gridWidth.compare(window._SM_)<=0) ? "lg"
                    : (gridWidth.compare(window._LG_)<=0) ? "sm"
                        : (gridWidth.compare(window._XL_)<=0) ? "md"
                            : "lg")
            : props.width) ;

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                setFieldValue: setFieldValue,
                focus: () => {
                    setTimeout(() => {
                        refCmb.current.focus();
                    }, 1);
                }
            }
        };

        if(props.dinApi) {
            if(campoInformado(props.dinApi.current)===false) {
                props.dinApi.current = {} ;
            }
            props.dinApi.current[props.name] = {
                refreshOptions: () => {
                    console.log(name, props.options);
                    forceUpdate();
                }
            }
        };

        return () => {

        };

    }, []);

    useEffect(() => {

        if(props.hasOwnProperty("ffrDisabled")===false) {
            setLocalDisabled(disabled);
        }

        return () => {

        };

    }, [disabled]);

    if(props.hasOwnProperty("ffrDisabled")===true) {
        const ffrDisabledValue = ffrDisabled();
        if(ffrDisabledValue !== localDisabled) setLocalDisabled(ffrDisabledValue);
    }

    const setFieldValue = (value) => {

        const clearValue = { };

        // clearValue[ props.name ] = undefined;

        clearValue[ props.name ] = null;

        formRef.current.setFieldsValue(clearValue);

        const children = options.filter(item => item !== null && item!=undefined)
            .map(renderOptions)
            .filter(item => item.value === value)
        ;

        if(children.length > 0) {

            const newValue = { };

            newValue[ props.name ] = children[0];

            formRef.current.setFieldsValue(newValue);

            return children[0];

        } else {

            return null;

        }

    }

    useEffect(() => {

        // console.log(name, props.options);

    }, [props.options]);

    useEffect(() => {

        // console.log(props.value);

    }, [props.value]);

    useEffect(() => {

        // console.log(props.defaultValue);

    }, [props.defaultValue]);

    useEffect(() => {

        // console.log(props.inputValue);

    }, [props.inputValue]);

    const children = options.filter(item => item!==null && item!==undefined).map(renderOptions);

    const className = ["iy2b-pro-field", "iy2b-combo-box"];

    if(visible === false) className.push("not-visible");

    return (
        <div
            className = {className.toClassName()}
        >
            <ProFormSelect
                label = {props.label}
                name = {props.name}
                tooltip = {props.tooltip}
                rules = {props.rules}
                width = {myWidth}
                placeholder = {props.placeholder}
                className = {className.toClassName()}
                fieldProps = {{
                    labelInValue: true,
                    showSearch: true,
                    allowClear: true,
                    filterOption: true,
                    disabled: localDisabled,
                    onSelect(value, option) {
                        if(campoInformado(onSelect) === true) onSelect(value, option);
                        if(campoInformado(onChange) === true) onChange(value, option);
                        return null;
                    },
                    onClear() {
                        if(campoInformado(onClear) === true) onClear();
                        if(campoInformado(onChange) === true) onChange(null, null);
                        return null;
                    },
                    ref: refCmb
                }}
                options={children}
            >
            </ProFormSelect>
        </div>
    );

}

export default ComboBox;
