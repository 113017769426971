import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import '@ant-design/pro-form/dist/form.css' ;
import '@ant-design/pro-layout/dist/layout.css' ;
import "./wizNovoUsuario.scss";

import ReactModal from "react-modal";
import {Button, notification, Space} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {notificacaoErroValidForm, useWindowSize} from "../../lib/iy2b-react";
import {campoInformado, scrollToTop} from "../../lib/iy2b-javascript";
import SinglePageLoadingIndicator from "../../componentes/SinglePageLoadingIndicator";
import IY2BProFormWizard from "../../componentes/IY2BProFormWizard";
import {StepsForm} from "@ant-design/pro-form";
import StepDados from "./StepDados";
import StepAcessos from "./StepAcessos";
import {getPerfilAcessoMenu} from "../../reducer/wsDataProvider";
import {connect} from "react-redux";
import {postJSON} from "../../lib/RequestAPI";
import {atualizarInfoComplUsr} from "../../reducer/userSession";
import {onAfterClose, onAfterOpen} from "../../componentes/IY2BReactModal";
import ProForm from "@ant-design/pro-form";

const WizNovoUsuario = (props) => {

    const windowSize = useWindowSize();

    const MARGIN_LEFT = 30;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = (windowSize.height-((windowSize.height<640)?572:672))/2;

    const { exibePopUp, onRequestClose, cdPessoaEmpMaster, onUsuarioAdicionado } = props;

    const [dpPerfilAcesso, setPerfilAcesso] = useState([]);
    const [dpEmpresas, setEmpresas] = useState( []);

    const formRef = useRef();

    useEffect(() => {

        props.getPerfilAcessoMenu(props.userSession?.planoContratado).then(resultado => {

            setPerfilAcesso(resultado.map(perfil => {
                return {value: perfil.cdPerfilAcesso, label: perfil.noPerfilAcesso}
            }));

        });

        const {planoContratado, planos} = props.userSession;

        const empsPlanoAtual = planos.filter(plano => plano.cdPessoaEmpMaster === planoContratado.cdPessoaEmpMaster);

        setEmpresas(empsPlanoAtual.map(emp => {

            const empresa = props.suite.listaEmp.filter(empresa => empresa.pessoaEmp.cdPessoa === emp.cdPessoaEmp);

            return {key: empresa[0].pessoaEmp.cdPessoa, title: empresa[0].noOficial}

        }));

        return () => {
        };
    }, []);

    const closePopUp = () => {
        onRequestClose();
    };

    const onFinishStepFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const onFinishWizard = async () => {

        const dadosUsr = formRefDados.current?.getFieldsValue();
        const permissoes = formRefAcessos.current?.getFieldsValue();

        const payload = {
            acaoAExecutar: "acaoCRUD",
            "origin": window.location.origin,
            registro: {
                cdPessoaUsr: dadosUsr.cdPessoaUsr,
                noPessoaUsr: dadosUsr.noUsuario,
                noFantasiaUsr: dadosUsr.noApelido,
                nrCGCCICUsr: dadosUsr.nrCPF.retiraCaracteres(".-/"),
                noEmail: dadosUsr.noEMail,
                nrTelCelular: dadosUsr.nrTelCelular.retiraCaracteres("() -"),
                cdPessoaEmp: permissoes.empresasComAcesso[0]
            },
            cdPerfilAcesso: permissoes.perfilAcesso.value,
            cdPessoaEmpMaster: cdPessoaEmpMaster,
            empresas: permissoes.empresasComAcesso
        };

        try {

            const resposta = await postJSON({
                sistema: "smk",
                projeto: "musuariopiy2b",
                acaoWebAExecutar: campoInformado(dadosUsr.cdPessoaUsr)?"alterar":"incluir"
            }, payload);

            notification.success({message: "Sucesso", description:"Novo usuário incluído na plataforma com sucesso !"})

            await props.atualizarInfoComplUsr();

            onRequestClose();

            onUsuarioAdicionado();

        } catch (e) {

            if(e?.data?.id === "ErroDeAcao") {

                notification.error({message: "Ooops!", description: e.data.descricao});

            } else {

                console.log(e);

                notification.error({message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

            }

        }

    }

    const onCurrentStepChange = (position, currentKey) => {

    };

    const formRefAcessos = useRef();
    const formRefAcessosApi = useRef();

    const stepFormAcessos =
        <StepsForm.StepForm
            formRef = { formRefAcessos }
            key={"stepFormAcessos"}
            name = "stepFormAcessos"
            title = "Permissão de acesso"
            stepProps = {{
                description: "Definição das permissões de acesso",
                disabled: false
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                if(await formRefAcessosApi.current?.onFinish() === false) return false;
                return true;
            }}
            onValuesChange = {async (changed, all) => {
                formRefAcessosApi.current?.onValuesChange(changed, all);
            }}
        >
            <StepAcessos
                api = { formRefAcessosApi }
                formRef = { formRefAcessos }
                dpPerfilAcesso = { dpPerfilAcesso }
                dpEmpresas = { dpEmpresas }
            />
        </StepsForm.StepForm>
    ;

    const formRefDados = useRef() ;
    const formRefDadosApi = useRef();

    const stepFormDados =
        <StepsForm.StepForm
            formRef = { formRefDados }
            key={"stepFormDados"}
            name = "stepFormDados"
            title = "Dados usuário"
            stepProps = {{
                description: "Preenchimento de informações do usuário",
                disabled: false
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                if(await formRefDadosApi.current?.onFinish() === false) return false;
                return true;
            }}
            onValuesChange = {async (changed, all) => {
                formRefDadosApi.current?.onValuesChange(changed, all);
            }}
        >
            <StepDados
                api = { formRefDadosApi }
                formRef = { formRefDados }
                cdPessoaEmpMaster = { cdPessoaEmpMaster }
            />
        </StepsForm.StepForm>
    ;

    const wizSteps = <>
        {stepFormDados}
        {stepFormAcessos}
    </>;

    return (
        <ReactModal
            isOpen={exibePopUp}
            className={"modalContent"}
            overlayClassName={"modalOverlay"}
            bodyOpenClassName={"popUpWizNovoUsuario"}
            closeTimeoutMS={500}
            onRequestClose={closePopUp}
            style={{
                content: {
                    left: MARGIN_LEFT + 'px',
                    width: MODAL_CONTENT_WIDTH + "px",
                    top: MODAL_CONTENT_TOP + "px"
                }
            }}
            onAfterOpen={() => {
                onAfterOpen();
            }}
            onAfterClose={() => {
                onAfterClose();
            }}
        >
            <div className={"modal-header"}>
                <label>Adicionar novo usuário</label>
                <Space align={"baseline"} style={{float:"right"}}>
                    <Button onClick={closePopUp}>
                        Cancelar
                    </Button>
                </Space>
            </div>
            <div className={"box-body"}>
                <SinglePageLoadingIndicator />
                <IY2BProFormWizard
                    formRef = { formRef }
                    onFinishWizard = { onFinishWizard }
                    onCurrentStepChange = { onCurrentStepChange }
                    stepsSize = "small"
                    steps = { wizSteps }
                >
                </IY2BProFormWizard>
            </div>
            <div className={"modal-footer"}>
            </div>
        </ReactModal>
    )

};

const mapStateToProps = function( { userSession, suite } ) {

    return {
        suite: suite,
        userSession: userSession
    };

};

const mapDispatchToProps = dispatch => {
    return {
        getPerfilAcessoMenu: (planoContrVenda) => {
            return dispatch ( getPerfilAcessoMenu(planoContrVenda) )
        },
        atualizarInfoComplUsr: () => {
            return dispatch ( atualizarInfoComplUsr() );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WizNovoUsuario);
