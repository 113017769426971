import "antd/dist/antd.css";
import '@ant-design/pro-layout/dist/layout.css' ;
import '@ant-design/pro-form/dist/form.css' ;
import '../../assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHatWizard, faTimes} from "@fortawesome/free-solid-svg-icons";
import fontawesome from "@fortawesome/fontawesome";
import {AutoComplete, Button, Col, Input, notification, Row, Tooltip} from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {offSetFromDocument} from "../../lib/iy2b-javascript";
import ReactModal from "react-modal";

fontawesome.library.add(faHatWizard);

const iconWizard = <FontAwesomeIcon icon={faHatWizard} color={"#FFF"}/>;
const iconFechar = <FontAwesomeIcon icon={faTimes} color={"#333"}/>;

const BotaoWizard = ( props ) => {

    const { onClick, opcoes } = props ;

    const [options, setOptions] = useState([]);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const [aberto, setAberto] = useState(false);
    const [dropDownOffSet, setDropDownOffSet] = useState({top:0, left:0, width: 0});
    const [acWidth, setAcWidth] = useState(400);

    const breaks = useBreakpoint();

    useEffect(() => {

        setAcWidth(document.getElementById('btnEmpresa').getBoundingClientRect().width + 150 - 60);

        const offset = offSetFromDocument(document.getElementById('botaoWizard'));

        offset.width = document.getElementById('btnWizard').getBoundingClientRect().width;

        setDropDownOffSet (offset);

        if(props.api) {
            props.api.current = {
                exibeDropdown: () => {
                    setAberto(true);
                    setTooltipVisible(false);
                },
                escondeDropdown: () => {
                    setAberto(false);
                    setTooltipVisible(false);
                }
            };
        }

        return () => {

        };

    }, []);

    useEffect(() => {

        setOptions(opcoes.map(item => {

            return {
                value: item.idWizard,
                label: item.noWizard,
                data: {...item}
            }

        }));

        return () => {

        };

    }, [opcoes]);

    const onAutocompleteSelect = (value, option) => {
        if(value === null) return;
        setAberto(false);
        onClick(option.data);
    };

    const currentDropDownOffSet = { ...dropDownOffSet };

    currentDropDownOffSet.left = (breaks.xs===true)?10:dropDownOffSet.left;

    const classNameContainerBtnWizard = ["containerBtnWizard"];

    if(aberto === true) {
        classNameContainerBtnWizard.push("aberto");
    }

    const acPlaceHolder =(options.isEmpty() === true) ? "Este assistente está em desenvolvimento" : "Digite o auxílio que você deseja";

    return (
        <>
            <Tooltip placement="bottom" title={"Assistente da operação atual"} open={tooltipVisible}>
                <div id={"botaoWizard"} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                    <div className={classNameContainerBtnWizard.toClassName()}>
                        <div id={"btnWizard"}>
                            <a  onClick={async () => {
                                setTooltipVisible(false);
                                setAberto(!aberto);
                                if(options.isEmpty() === true) {
                                    notification.warning({message:"Atenção", description:"Este assistente está em desenvolvimento!"});
                                }
                            }}>
                                <div className={"containerIcon"}>{iconWizard}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </Tooltip>
            <ReactModal
                isOpen={aberto}
                className={"modalContent"}
                overlayClassName={"modalOverlay"}
                bodyOpenClassName={"botaoWizardModal"}
                closeTimeoutMS={500}
                shouldFocusAfterRender={true}
                onRequestClose={() => setAberto(false)}
                style={{
                    content: {
                        left: (currentDropDownOffSet.left + 1) + 'px',
                    }
                }}
            >
                <div className={"containerDropdownBtnWizard"}
                     style={{width: acWidth + 80}}
                >
                    <Row>
                        <Col span={24}>
                            <Button type={"default"} className={"btnFechar ant-btn-lg ant-btn-icon-only"}
                                    onClick={() => setAberto(false)}
                                    style={{left: acWidth - 1}}
                            >
                                <div className={"containerIconFechar"}>
                                    <span role="img" aria-label="search" className="anticon anticon-times">
                                        {iconFechar}
                                    </span>
                                </div>
                            </Button>
                            <AutoComplete id={"acBotaoWizard"}
                                          autoFocus
                                          popupClassName={"dropdownSearchBotaoWizard"}
                                          style={{ width: acWidth }}
                                          options={options}
                                          onSelect={onAutocompleteSelect}
                                          filterOption={(input, option) => {
                                              if(option.hasOwnProperty("options")) {
                                                  return false;
                                              } else {
                                                  return option.value.indexOf(input.toLowerCase()) >= 0;
                                              }
                                          }}
                            >
                                <Input.Search size="large" placeholder={acPlaceHolder} />
                            </AutoComplete>
                        </Col>
                    </Row>
                </div>
            </ReactModal>
        </>
    );

}

export default BotaoWizard;
