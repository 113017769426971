import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/menuSuspenso.scss';

import React, {useState, useEffect, useTransition} from 'react';
import { connect } from 'react-redux';
import {Button, Layout, Input, List, Row, Select, Col, AutoComplete, Modal, Tooltip} from 'antd';
import ReactModal from 'react-modal';
import {setActive} from "../reducer/transacaoManager";

import { faBars, faHome, faTimes, faCheck, faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar} from '@fortawesome/free-regular-svg-icons';

import fontawesome from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useGridWidth, useStateRef} from "../lib/iy2b-react";
import {postJSON} from "../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "./Workspace/workspace";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import eventBus from "../lib/eventBus";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";

const { Header, Sider, Content } = Layout;

fontawesome.library.add(faBars, faHome, faTimes, faCheck, farStar, fasStar);

const iconStar = <FontAwesomeIcon icon={farStar} />;
const iconStarSolido = <FontAwesomeIcon icon={fasStar} />;
const iconFecharMenu = <FontAwesomeIcon icon={faTimes} />;
const iconAbrirMenu = <FontAwesomeIcon icon={faBars} />;

const ABRIR_SEMPRE_NO_FAVORITAS = false;

const calcHeight = () => {

    const fullHeight = document.getElementById("mainWorkspace").clientHeight;
    const headerHeight = document.getElementById("headerWS").clientHeight;

    const height = fullHeight - headerHeight;

    return height;

};

const preparaSubChild = (item) => {

    const resultado = [];

    const nI = {...item};

    nI.childs = [];

    resultado.push(nI);

    item.childs.forEach(child => {

        if(child.subParentKey === null || child.subParentKey === undefined) child.subParentKey = item.key;

        if(child.parentKey === null || child.parentKey === undefined) child.parentKey = item.parentKey;

        if(child.childs === undefined || child.childs.length === 0) {

            nI.childs.push(child);

        } else {

            const novoItemPlano = preparaSubChild(child);

            resultado.addAll(novoItemPlano);

        }

    });

    return resultado;
};

const buildCacheMenu = (menuLateral) => {

    const cache = [];

    menuLateral.forEach(item => {

        const dpAuxP = [];
        const dpAuxS = [];

        if(item.hasOwnProperty("childs")) {

            item.childs.forEach(subItem => {
                if(subItem.parentKey === null || subItem.parentKey === undefined) subItem.parentKey = item.key;
                if(subItem.childs === undefined || subItem.childs.length === 0) {
                    dpAuxP.push(subItem);
                } else {
                    const novoItemPlano = preparaSubChild(subItem);
                    dpAuxS.addAll(novoItemPlano);
                }
            });

        }

        cache[item.key] = {
            key: item.key,
            title: item.title,
            colPrincipal: dpAuxP,
            subMenus: dpAuxS
        }

    });

    return cache;

};

const buildSearchItensAutocomplete = (cache) => {

    const itens = [];

    const keys = Object.keys(cache);

    keys.forEach(key => {

        if (key === "favoritas") return;

        const cacheItem = cache[key];

        const item = {
            label: cacheItem.title,
            options: []
        };

        itens.push(item);

        cacheItem.colPrincipal.forEach(menu => {
            item.options.push({
                key: menu.key,
                value: menu.title.toLowerCase(),
                label: menu.title
            });
        });

        cacheItem.subMenus.forEach(subMenu => {

            subMenu.childs.forEach(menu => {
                item.options.push({
                    key: menu.key,
                    value: menu.title.toLowerCase(),
                    label: menu.title
                });
            });

        });

    });

    return itens;

}

const GAP_MENU_LATERAL = 70;
const GAP_MENU_CONTENT = 80;
const GAP_VERSAO = 20;

const MenuSuspenso = props => {

    const [tooltipVisible, setTooltipVisible] = useState(false);

    const [menuInicializado, setMenuInicializado] = useState(false);
    const [menuAberto, setMenuAberto] = useState(false);
    const [cacheMenu, setCacheMenu] = useState(null);
    const [options, setOptions] = useState([]);

    const [dpLateral, setDpLateral] = useState([]);
    const [dpColPrincipal, setDpColPrincipal] = useState([]);
    const [dpSubMenus, setDpSubMenus] = useState([]);

    const [selectedLateralItem, setSelectedLateralItem] = useState (null);

    const [menuHeight, setMenuHeight] = useState(0);

    const [ gridWidth ] = useGridWidth();

    const [, setExibeFavoritas, refExibeFavoritas] = useStateRef(false);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("MenuSuspenso render inicial concluido");

        setMenuHeight(calcHeight());

        props.api.current = {
            closeMenu: () => {
           //     console.log("closeMenu");
                setTooltipVisible(false);
                setMenuAberto(false);
            },
            openMenu: () => {
             //   console.log("openMenu");
                setTooltipVisible(false);
                setMenuAberto(true);
            },
            resetMenu: () => {
               // console.log("resetMenu");
                setTooltipVisible(false);
                setMenuAberto(false);
                setMenuInicializado(false);
            },
            rebuildMenu: () => {
                //console.log("rebuildMenu");
                setTooltipVisible(false);
                setMenuAberto(false);
                setMenuInicializado(false);
                inicializaMenu();
                setExibeFavoritas(true);
            }
        };

        eventBus.on("menuSuspenso/rebuild", () => {

            console.log("received menuSuspenso/rebuild");

            props.api.current.rebuildMenu();

        });

        return () => {

        };

    }, []);

    const inicializaMenu = () => {

        const dpAux = [];

        if(props.exibeFavoritas === false) {

        } else {

            dpAux.push({
                key: "favoritas",
                title: "Favoritas",
                active: true,
                icon: iconStar,
                childs: props.transacaoManager.disponiveis.filter(trn => (trn.favorito === true))
            });

        }

        props.transacaoManager.menu.forEach((item) => {

            if(item.tpTransacao === "M") {

                const menuItem = {...item};

                dpAux.push(menuItem);

            }

        });

        setDpLateral(dpAux);

        const auxCache = buildCacheMenu(dpAux);

        setCacheMenu(auxCache);

        setOptions(buildSearchItensAutocomplete(auxCache));

        setMenuInicializado(true);

    };

    if(menuInicializado === false) {

        if(props.transacaoManager.menu.length > 0) {

            inicializaMenu();

        }

    }

    const trataMenu = () => {
        setTooltipVisible(false);
        const novoStatus = (! menuAberto);
        // console.log("trataMenu " + novoStatus)
        setMenuAberto(novoStatus);
        if(novoStatus === true) {
            if(refExibeFavoritas?.current===true) {
                if(dpLateral.length > 0) onClickLateral(dpLateral[0]);
                setExibeFavoritas(false);
                return;
            }
            if(ABRIR_SEMPRE_NO_FAVORITAS === true) {
                if(dpLateral.length > 0) onClickLateral(dpLateral[0]);
            } else {
                if(selectedLateralItem === null) { // primeira vez
                    if(dpLateral.length > 0) onClickLateral(dpLateral[0]);
                }
            }
        }
    }

    const onClickFavorito = async item => {

        let acaoTrnFav = null;

        try {

            if(item.favorito === true) {

                acaoTrnFav = "EXCLUSAO";

            } else {

                acaoTrnFav = "INCLUSAO";

            }

            const resultado = await postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "smk",
                projeto: "pnltrnfav",
                rest: false
            }, {
                acaoAExecutar: "mantem",
                cdPessoaUsr: props.userSession.cdPessoaUsr,
                cdTransacao: item.cdTransacao,
                acaoTrnFav: acaoTrnFav,
                nrExec: dpLateral[0].childs + 1,
                tpInclusao: "M"
            }, WORKSPACE_LOADING_INDICATOR_AREA);

            if(item.favorito === true) {

                item.favorito = false;

                cacheMenu[dpLateral[0].key].colPrincipal.removeItem(item) ;

            } else {

                item.favorito = true;

                cacheMenu[dpLateral[0].key].colPrincipal.push(item) ;

            }

            setCacheMenu(cacheMenu);

            onClickLateral(selectedLateralItem);

        } catch (error) {

            console.log(error);

            Modal.error({
                title: 'Marcar como favorita',
                content: "Nao foi possivel no momento, por favor tente mais tarde !",
            });

        }

    }

    const [ , startTransition] = useTransition();

    const onClickMenu = item => {

        startTransition(() => {

            props.api.current.closeMenu();

            setTimeout(() => {

                props.setActive(item.key).then(result => {

                });

            }, 1);

        });

    }

    const onClickLateral = item => {

        const dpAuxL = dpLateral.map(item => {
            item.active = false;
            return item;
        });

        const menu = cacheMenu[item.key];

        const dpAuxP = menu.colPrincipal.map(item => item);
        const dpAuxS = menu.subMenus.map(item => item);

        item.active = true;

        setDpLateral(dpAuxL);

        setSelectedLateralItem(item);

        setDpColPrincipal(dpAuxP);

        setDpSubMenus(dpAuxS);

    };

    const renderItemTransacao = (item) => {

        const iconeFavorito = item.favorito===true?iconStarSolido:iconStar;
        const classeFavorito = item.favorito===true?"favoritado":"";

        const linkFavorito = (props.exibeFavoritas!==false) ?
            <a onClick={() => onClickFavorito(item)} className={["icone", classeFavorito].toClassName()}>{iconeFavorito}</a>
            : null;

        return (
            <List.Item key={item.key}>
                {linkFavorito}
                <a onClick={() => onClickMenu(item)} className={"opcao"}>{item.title}</a>
            </List.Item>
        );

    };

    const tamColunaSubMenu = gridWidth.compare(window._XXL_) >= 0 ? 6 :
        gridWidth.compare(window._LG_) >= 0 ? 8 :
        gridWidth.compare(window._MD_) >= 0 ? 12 :
        24
    ;

    const contentSubMenu = dpSubMenus.map(item => {

        return (
            <Col span={tamColunaSubMenu} key={"col_" + item.key} >
                <div className={"subMenuHeader"}>{item.title}</div>
                <List
                    dataSource={item.childs}
                    renderItem={subItem => {
                        return renderItemTransacao(subItem);
                    }}
                >
                </List>
            </Col>
        );

    });

    let layoutColsContent = null;

    if(selectedLateralItem !== null) {

        if(selectedLateralItem.key === "favoritas") {

            layoutColsContent = (
                <Row gutter={[0, 0]} className={"contentFavoritas"}>
                    <Col span={24} className={"colPrincipal"}>
                        <Row gutter={[0, 0]}>
                            {dpColPrincipal.map(item => {
                                return (
                                    <Col span={tamColunaSubMenu} key={"col_" + item.key}>
                                        {renderItemTransacao(item)}
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            );

        } else {

            const menuColPrincipalVazio = (dpColPrincipal.length === 0);
            const subMenuVazio = (dpSubMenus.length === 0);

            if(subMenuVazio === true) {

                layoutColsContent = (
                    <Row gutter={[0, 0]}>
                        <Col span={24} className={"colPrincipal"}>
                            <Row gutter={[0, 0]}>
                                {dpColPrincipal.map(item => {
                                    return (
                                        <Col span={tamColunaSubMenu} key={"col_" + item.key}>
                                            {renderItemTransacao(item)}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                );

            } else if (menuColPrincipalVazio === true) {

                layoutColsContent = (
                    <Row gutter={[0,0]}>
                        <Col span={24} className={"colSubMenu"}>
                            <Row>
                                {contentSubMenu}
                            </Row>
                        </Col>
                    </Row>
                );

            } else {

                const princSpan = gridWidth.compare(window._MD_) >= 0 ? 6 : 12;
                const subSpan = princSpan===6 ? 18 : 12;

                layoutColsContent = (
                    <Row gutter={[0,0]}>
                        <Col span={princSpan} className={"colPrincipal"}>
                            <List
                                dataSource={dpColPrincipal}
                                renderItem={item => {
                                    return renderItemTransacao(item);
                                }}
                            >
                            </List>
                        </Col>
                        <Col span={subSpan} className={"colSubMenu"}>
                            <Row>
                                {contentSubMenu}
                            </Row>
                        </Col>
                    </Row>
                );

            }

        }

    }

    const currentIcon = (menuAberto===true) ? iconFecharMenu : iconAbrirMenu;
    const classNameStatusMenu = (menuAberto===true) ? "open" : "close";
    const headerTitle = selectedLateralItem != null ? selectedLateralItem.title : "";
    const headerIcon = selectedLateralItem != null ? selectedLateralItem.icon : "";

    const onAutocompleteSelect = (value, option) => {
        onClickMenu(option);
    };

    const siderWidth =
        gridWidth.compare(window._LG_) >= 0 ? 275 :
        gridWidth.compare(window._MD_) >= 0 ? 200 :
        150
    ;

    const breaks = useBreakpoint();

    const acMenuWidth = (breaks.xs===true)?350:400;

    return (
        <Tooltip placement="bottom" title={"Menu"} open={tooltipVisible}>
            <div id={"menuSuspenso"} className={[classNameStatusMenu]} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                <Button id={"btnMenu"} icon={currentIcon} onClick={trataMenu} block type={"text"}/>
                <ReactModal
                    isOpen={menuAberto === true}
                    className={"modalContent"}
                    overlayClassName={"modalOverlay"}
                    bodyOpenClassName={"menuSuspensoModal"}
                    closeTimeoutMS={500}
                    shouldFocusAfterRender={true}
                    onRequestClose={(e) => setMenuAberto(false)}
                >
                    <Layout>
                        <Sider width={siderWidth}>
                            <AutoComplete id={"acMenuSuspenso"}
                                          autoFocus
                                          popupClassName={"dropdownSearchMenuSuspenso"}
                                          dropdownMatchSelectWidth={acMenuWidth}
                                          style={{ width: (siderWidth-18) }}
                                          options={options}
                                          onSelect={onAutocompleteSelect}
                                          filterOption={(input, option) => {
                                              if(option.hasOwnProperty("options")) {
                                                  return false;
                                              } else {
                                                  return option.value.indexOf(input.toLowerCase()) >= 0;
                                              }
                                          }}
                            >
                                <Input.Search size="large" placeholder="Pesquisar opções" />
                            </AutoComplete>
                            <div id={"listaLateralScroller"} style={{height: (menuHeight - GAP_MENU_LATERAL - GAP_VERSAO), width: (siderWidth-18)}}>
                                <List
                                    dataSource={dpLateral}
                                    renderItem={item => {
                                        const itemClassName = item.active ? "active": "";
                                        return (
                                            <List.Item key={item.key} className={itemClassName}>
                                                <a onClick={() => onClickLateral(item)} className={"opcao"}>{item.title}</a>
                                            </List.Item>
                                        )
                                    }}
                                >
                                </List>
                            </div>
                            <div className="versao">
                                {props.appConfig.nome} v{props.appConfig.versao}
                            </div>
                        </Sider>
                        <Layout>
                            <Header>
                                <span>{headerIcon}</span> {headerTitle}
                            </Header>
                            <Content>
                                <div id={"contentScroller"} style={{height: menuHeight - GAP_MENU_CONTENT}}>
                                    {layoutColsContent}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </ReactModal>
            </div>
        </Tooltip>
    );

};

const mapStateToProps = function( { userSession, transacaoManager, appConfig } ) {

    return {
        userSession: userSession,
        transacaoManager: transacaoManager,
        appConfig: appConfig
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setActive: async (key) => {
            return dispatch( setActive(key) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSuspenso);

