import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import './style.scss';

import React, { useState, useEffect, useRef } from 'react';
import {Layout, Button, notification, Space, Tree} from "antd";
import {campoInformado, campoInformadoString, confirm} from "../../lib/iy2b-javascript";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBan,
    faCheck, faExclamationTriangle,
    faEye,
    faPlay,
    faQuestion,
    faQuestionCircle, faSync,
    faTimes,
    faVideo
} from "@fortawesome/free-solid-svg-icons";
import fontawesome from "@fortawesome/fontawesome";
import {canIUseIt, setActiveAt} from "../../reducer/transacaoManager";
import {connect} from "react-redux";
import ReIF from "../../componentes/REIf/reIF";
import BotaoToolTip from "../../componentes/BotaoToolTip/botaoToolTip";
import {postJSON} from "../../lib/RequestAPI";
import {notificacaoErroRequest, useGridWidth, useStateRef} from "../../lib/iy2b-react";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../Workspace/workspace";
import Animate from "rc-animate";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {skinPrimaryColor} from "../../assets/css/skins/StyleSkin";

const { Sider } = Layout;

fontawesome.library.add(faEye, faPlay, faQuestionCircle, faVideo, faQuestion, faCheck, faExclamationTriangle, faSync, faTimes, faBan);

const iconFechar = <FontAwesomeIcon icon={faTimes} color={"#333"}/>;
const iconExecTrn = <FontAwesomeIcon icon={faPlay} color={"#333"}/>;
const iconHelp = <FontAwesomeIcon icon={faQuestionCircle} color={"#333"}/>;
const iconVideo = <FontAwesomeIcon icon={faVideo} color={"#333"}/>;
const iconFaltaVerif = <FontAwesomeIcon icon={faQuestion} color={"#999"}/>;
const iconVerifOk = <FontAwesomeIcon icon={faCheck} color={"#3ec744"}/>;
const iconVerifErr = <FontAwesomeIcon icon={faExclamationTriangle} color={"#E00000"}/>;
const iconRefreshCheck = <FontAwesomeIcon icon={faSync} color={skinPrimaryColor}/>;
const iconDesabilitado = <FontAwesomeIcon icon={faTimes} color={"#E00000"}/>;
const iconDesabilitar = <FontAwesomeIcon icon={faBan} color={"#E00000"}/>;
const iconHabilitar = <FontAwesomeIcon icon={faCheck} color={"#3ec744"}/>;
const iconHabilitado = <FontAwesomeIcon icon={faCheck} color={"#333"}/>;

const SiderChecklist = ( props ) => {

    const { checkList, userSession } = props ;

    const usrMaster = (userSession?.master === "S");

    const [ gridWidth ] = useGridWidth();

    const [aberto, setAberto] = useState(false);

    const [tableHeight, setTableHeight] = useState(80);

    const [arvore, setArvore, refArvore] = useStateRef([ ]);

    const [currentCheckList, setCheckList] = useState(null);

    const forceUpdate = useForceUpdate();

    const calcTableHeight = () => {

        const formHeight = 0;

        const height = document.getElementById('containerContent').clientHeight;

        const tableLayout = 70;
        const GAP = 0;
        const GAP_MOBILE = gridWidth.compare(window._XS_) === 0 ? 20 : 0;

        const tableHeight = height - formHeight - tableLayout - GAP - GAP_MOBILE;

        setTableHeight (tableHeight);

    };

    useEffect(() => {

        setAberto(campoInformado(props.checkList));
        setCheckList(props.checkList);

        if(campoInformado(props.checkList) === true) {

            pesquisarChecklist(props.checkList);

        }

    }, [props.checkList]);

    const className = [];

    if(aberto === false) {
        className.push("fechado");
    } else {
        className.push("aberto");
    }

    const onRequestClose = () => {
        props.onRequestClose();
    }


    const pesquisarChecklist = async (checkListProcesso) => {

        if(tableHeight === 80) calcTableHeight();

        try {

            const fakeDataCache = {
                100: [{
                    id:"modelo",
                    modeloPadrao: {
                        cdGrupoChecklist: 1,
                        cdChecklist: 100,
                        noChecklist: "Trocar a foto do meu perfil",
                        itens:[{
                            key: "100_1",
                            nrItem: 1,
                            transacao: {
                                cdTransacao: 10000001,
                                noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            },
                            cdChecklistRef: null,
                            cdParametro: null,
                            noItem: "Acessar o seu perfil e trocar a foto",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "N",
                            children: []
                        }]
                    }
                }],
                101: [{
                    id:"modelo",
                    modeloPadrao: {
                        cdGrupoChecklist: 1,
                        cdChecklist: 101,
                        noChecklist: "Habilitar um novo usuário da minha empresa",
                        itens: [{
                            key: "101_1",
                            nrItem: 1,
                            transacao: {
                                cdTransacao: 10000004,
                                noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            },
                            cdChecklistRef: null,
                            cdParametro: null,
                            noItem: "Acessar a opção 'Usuarios' e clicar no botão 'Adcionar novo usuário'",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "N",
                            children: []
                        }]
                    }
                }],
                200: [{
                    id:"modelo",
                    modeloPadrao: {
                        cdGrupoChecklist: 2,
                        cdChecklist: 200,
                        noChecklist: "Já tenho tudo o q preciso pra pagar uma conta ?",
                        itens: [{
                            key: "200_1",
                            cdChecklist: 200,
                            nrItem: 1,
                            transacao: {
                                cdTransacao: 85010,
                                noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            },
                            cdChecklistRef: null,
                            cdParametro: null,
                            noItem: "As contas bancárias ja estão cadastradas ?",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "S",
                            fakeResultadoVerif: {
                                mensagens: [{
                                    id:"MensagemIdentificada.Ok",
                                    mensagem: "Ok"
                                }]
                            },
                            children: []
                        }, {
                            key: "200_2",
                            nrItem: 2,
                            transacao: {
                                cdTransacao: 85017,
                                noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            },
                            cdChecklistRef: null,
                            cdParametro: null,
                            noItem: "O credor que tenho que pagar ja está cadastrado ? Verifique consultado o cadastro de credores.",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "N",
                            children: []
                        }, {
                            key: "200_3",
                            nrItem: 3,
                            transacao: {
                                cdTransacao: 85014,
                                noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            },
                            cdChecklistRef: null,
                            cdParametro: "UTILIZA_PROJETOCM",
                            noParametro: "(aqui será exibido o nome do parametro q habilita ou desabilita a utilização de PROJETO nesta empresa)",
                            flgHabilitado: "S",
                            noItem: "O projeto desta despesa ja está cadastrado ?",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "S",
                            fakeResultadoVerif: {
                                mensagens: [{
                                    id: "falha",
                                    "class": "falhaDeValidacao",
                                    descricao: "Não existem projetos cadastrados"
                                }]
                            },
                            children: []
                        }]
                    }
                }],
                201: [{
                    id:"modelo",
                    modeloPadrao: {
                        cdGrupoChecklist: 2,
                        cdChecklist: 201,
                        noChecklist: "Quero pagar um boleto",
                        itens: [{
                            key: "201_1",
                            cdChecklist: 201,
                            nrItem: 1,
                            cdChecklistRef: 200,
                            cdParametro: null,
                            noItem: "Já tenho tudo o q preciso pra pagar uma conta ?",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "N",
                            children: [{
                                key: "201_1_200_1",
                                cdChecklist: 200,
                                nrItem: 1,
                                transacao: {
                                    cdTransacao: 85010,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: null,
                                noItem: "As contas bancárias ja estão cadastradas ?",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "S",
                                fakeResultadoVerif: {
                                    mensagens: [{
                                        id:"MensagemIdentificada.Ok",
                                        mensagem: "Ok"
                                    }]
                                },
                                children: []
                            }, {
                                key: "201_1_200_2",
                                cdChecklist: 200,
                                nrItem: 2,
                                transacao: {
                                    cdTransacao: 85017,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: null,
                                noItem: "O credor que tenho que pagar ja está cadastrado ? Verifique consultado o cadastro de credores.",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "N",
                                children: []
                            }, {
                                key: "201_1_200_3",
                                cdChecklist: 200,
                                nrItem: 3,
                                transacao: {
                                    cdTransacao: 85014,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: "UTILIZA_PROJETOCM",
                                noParametro: "(aqui será exibido o nome do parametro q habilita ou desabilita a utilização de PROJETO nesta empresa)",
                                flgHabilitado: "S",
                                noItem: "O projeto desta despesa ja está cadastrado ?",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "S",
                                fakeResultadoVerif: {
                                    mensagens: [{
                                        id: "falha",
                                        "class": "falhaDeValidacao",
                                        descricao: "Não existem projetos cadastrados"
                                    }]
                                },
                                children: []
                            }]
                        }, {
                            key: "201_2",
                            cdChecklist: 201,
                            nrItem: 2,
                            transacao: {
                                cdTransacao: 85016,
                                noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            },
                            cdChecklistRef: null,
                            cdParametro: null,
                            noItem: "Agora, já é possível lançar o pagamento. Clique no 'play' ao lado.",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "N",
                            children: []
                        }]
                    }
                }],
                202: [{
                    id:"modelo",
                    modeloPadrao: {
                        cdGrupoChecklist: 2,
                        cdChecklist: 202,
                        noChecklist: "Como lançar a despesa do cartão de crédito ?",
                        itens: [{
                            key: "202_1",
                            cdChecklist: 202,
                            nrItem: 1,
                            cdChecklistRef: 200,
                            cdParametro: null,
                            noItem: "Já tenho tudo o q preciso pra pagar uma conta ?",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "N",
                            children: [{
                                key: "202_1_200_1",
                                cdChecklist: 200,
                                nrItem: 1,
                                transacao: {
                                    cdTransacao: 85010,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: null,
                                noItem: "As contas bancárias ja estão cadastradas ?",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "S",
                                fakeResultadoVerif: {
                                    mensagens: [{
                                        id:"MensagemIdentificada.Ok",
                                        mensagem: "Ok"
                                    }]
                                },
                                children: []
                            }, {
                                key: "202_1_200_2",
                                cdChecklist: 200,
                                nrItem: 2,
                                transacao: {
                                    cdTransacao: 85017,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: null,
                                noItem: "O credor que tenho que pagar ja está cadastrado ? Verifique consultado o cadastro de credores.",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "N",
                                children: []
                            }, {
                                key: "202_1_200_3",
                                cdChecklist: 200,
                                nrItem: 3,
                                transacao: {
                                    cdTransacao: 85014,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: "UTILIZA_PROJETOCM",
                                noParametro: "(aqui será exibido o nome do parametro q habilita ou desabilita a utilização de PROJETO nesta empresa)",
                                flgHabilitado: "N",
                                noItem: "O projeto desta despesa ja está cadastrado ?",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "S",
                                fakeResultadoVerif: {
                                    mensagens: [{
                                        id:"MensagemIdentificada.Ok",
                                        mensagem: "Ok"
                                    }]
                                },
                                children: []
                            }]
                        }, {
                            key: "202_2",
                            cdChecklist: 202,
                            nrItem: 2,
                            transacao: {
                                cdTransacao: 85015,
                                noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            },
                            cdChecklistRef: null,
                            cdParametro: null,
                            noItem: "Já cadastrei o cartão de crédito ?",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "S",
                            fakeResultadoVerif: {
                                mensagens: [{
                                    id: "falha",
                                    "class": "falhaDeValidacao",
                                    descricao: "Não existem cartao de credito cadastrado"
                                }]
                            },
                            children: []
                        }, {
                            key: "202_3",
                            cdChecklist: 202,
                            nrItem: 3,
                            transacao: {
                                cdTransacao: 85016,
                                noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            },
                            cdChecklistRef: null,
                            cdParametro: null,
                            noItem: "Agora, já é possível lançar o pagamento. Clique no 'play' ao lado.",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "N",
                            children: []
                        }]
                    }
                }],
                300: [{
                    id:"modelo",
                    modeloPadrao: {
                        cdGrupoChecklist: 3,
                        cdChecklist: 300,
                        noChecklist: "Não consegui pagar uma conta!",
                        itens: [{
                            key: "300_1",
                            cdChecklist: 300,
                            nrItem: 1,
                            cdChecklistRef: 200,
                            cdParametro: null,
                            noItem: "Já tenho tudo o q preciso pra pagar uma conta ?",
                            txUrlHelp: null,
                            txUrlVideo: null,
                            flgTemVerificacao: "N",
                            children: [{
                                key: "300_1_200_1",
                                cdChecklist: 200,
                                nrItem: 1,
                                transacao: {
                                    cdTransacao: 85010,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: null,
                                noItem: "As contas bancárias ja estão cadastradas ?",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "S",
                                fakeResultadoVerif: {
                                    mensagens: [{
                                        id:"MensagemIdentificada.Ok",
                                        mensagem: "Ok"
                                    }]
                                },
                                children: []
                            }, {
                                key: "300_1_200_2",
                                cdChecklist: 200,
                                nrItem: 2,
                                transacao: {
                                    cdTransacao: 85017,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: null,
                                noItem: "O credor que tenho que pagar ja está cadastrado ? Verifique consultado o cadastro de credores.",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "N",
                                children: []
                            }, {
                                key: "300_1_200_3",
                                cdChecklist: 200,
                                nrItem: 3,
                                transacao: {
                                    cdTransacao: 85014,
                                    noHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                    txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                                },
                                cdChecklistRef: null,
                                cdParametro: "UTILIZA_PROJETOCM",
                                noParametro: "(aqui será exibido o nome do parametro q habilita ou desabilita a utilização de PROJETO nesta empresa)",
                                flgHabilitado: "S",
                                noItem: "O projeto desta despesa ja está cadastrado ?",
                                txUrlHelp: null,
                                txUrlVideo: null,
                                flgTemVerificacao: "S",
                                fakeResultadoVerif: {
                                    mensagens: [{
                                        id:"MensagemIdentificada.Ok",
                                        mensagem: "Ok"
                                    }]
                                },
                                children: []
                            }]
                        }, {
                            key: "300_2",
                            cdChecklist: 300,
                            nrItem: 2,
                            cdChecklistRef: null,
                            cdParametro: null,
                            noItem: "Ainda não conseguiu, abra um ticket de suporte utilizando a opção de 'atendimento' na 'Area do Usuario'.",
                            txUrlHelp: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            txUrlVideo: "https://s3.us-east-2.amazonaws.com/ajuda.portal.iy2b.com/exemplo-help-Dashboard+de+notas+fiscais.pdf",
                            flgTemVerificacao: "N",
                            children: []
                        }]
                    }
                }],
            };

            const fakeData = {
                mensagens: fakeDataCache[checkListProcesso.cdChecklist]
            };

            const dpAux = [];

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mchklstprocesso"
            }, {
                "acaoAExecutar": "pesquisaProcesso",
                filtro: {
                    txtCdChecklist: checkListProcesso.cdChecklist
                },
                "fakeData": fakeData
            }, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    console.log(msg.modeloPadrao);

                    dpAux.addAll(msg.modeloPadrao.itens);

                } else {

                    console.log(msg);

                }

            });

            setArvore(dpAux);
            forceUpdate();

            setTimeout(() => {

                executaVerificacaoItens(dpAux);

            }, 1000 * 2);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e);

        }

    }

    const visitaTree = (parent, leaf, fn) => {
        if(leaf.hasOwnProperty("children")) {
            leaf.children.forEach(child => visitaTree(leaf, child, fn));
        } else if(leaf.hasOwnProperty("childs")) {
            leaf.childs.forEach(child => visitaTree(leaf, child, fn));
        }
        fn(parent, leaf);
    }

    const executaVerificacaoItens = async (tree) => {

        const paraVerificar = tree.getByTree("flgTemVerificacao", "S").filter(item => !(item.flgHabilitado==="N")).filter(item => campoInformado(item.nrItemChecklist));

        if(paraVerificar.isEmpty() === true) return;

        const dpAux = refArvore.current.clone();

        for (const item of paraVerificar) {

            item.flgSituacaoCheck = "F";

            let resultado = null;

            try {

                resultado = await postJSON({
                    acaoWebAExecutar: "pesquisar",
                    sistema: "sgr",
                    projeto: "mchklstprocesso"
                }, {
                    "acaoAExecutar": "verificarItemChkLstProcesso",
                    filtro: {
                        txtCdChecklist: item.cdChecklist,
                        txtNrItem: item.nrItem,
                    },
                    "fakeData": item.fakeResultadoVerif
                }, WORKSPACE_LOADING_INDICATOR_AREA);

            } catch (e) {

                resultado = {
                    mensagens: [e.data]
                };

            }

            if(campoInformado(resultado) === true) {

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "MensagemIdentificada.Ok") {

                        item.flgSituacaoCheck = "S";

                    } else if(msg["class"]==="falhaDeValidacao") {

                        item.flgSituacaoCheck = "N";
                        item.toolTipErr = msg.descricao;

                    } else {

                        console.log(msg);

                    }

                });

            }

            dpAux.getByTree("key", item.key).forEach(leaf => {
                leaf.flgSituacaoCheck = item.flgSituacaoCheck;
                leaf.toolTipErr = item.toolTipErr;
            });

        }

        const atualizaSituacao = (parent, leaf) => {
            if(leaf.flgSituacaoCheck === "S") {
                parent.flgTemVerificacao = "S";
                if(parent.flgSituacaoCheck === "N") {
                } else {
                    parent.flgSituacaoCheck = "S";
                }
            } else if(leaf.flgSituacaoCheck === "N") {
                parent.flgTemVerificacao = "S";
                parent.flgSituacaoCheck = "N";
                if(campoInformado(parent.toolTipErr) === false) parent.toolTipErr = "";
                parent.toolTipErr += leaf.toolTipErr + " ";
            }
        }

        for (const item of dpAux) {
            item.children.forEach(leaf => visitaTree(item, leaf, atualizaSituacao));
        }

        setArvore(dpAux);

        forceUpdate();

    }

    const executarTransacao = async (transacao) => {

        props.setActiveAt(1, transacao.cdTransacao);

    }

    const desabilitarItemCheck = async (registro) => {

        const doDesabilitar = async () => {

            try {

                const resultado = await postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "sgr",
                    projeto: "mchklstprocesso"
                }, {
                    "acaoAExecutar": "desabilitarItemChkLstProcesso",
                    cdChecklist: registro.data.cdChecklist,
                    nrItem: registro.data.nrItem,
                    "fakeData": {
                        mensagens:[{
                            id:"MensagemIdentificada.Ok",
                            mensagem:"Desabilitado com sucesso !"
                        }]
                    }
                }, WORKSPACE_LOADING_INDICATOR_AREA);

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "MensagemIdentificada.Ok") {

                        notification.success({message:"Desabilitar", description:"Processo concluído com sucesso !"})

                    } else {

                        console.log(msg);

                    }

                });

                const dpAux = refArvore.current.clone();

                dpAux.getByTree("key", registro.key).forEach(leaf => {
                    leaf.flgHabilitado = "N";
                    leaf.flgSituacaoCheck = "F";
                    leaf.toolTipErr = null;
                });

                const atualizaSituacao = (parent, leaf) => {
                    if(leaf.flgSituacaoCheck === "S") {
                        parent.flgTemVerificacao = "S";
                        if(parent.flgSituacaoCheck === "N") {
                        } else {
                            parent.flgSituacaoCheck = "S";
                        }
                    } else if(leaf.flgSituacaoCheck === "N") {
                        parent.flgTemVerificacao = "S";
                        parent.flgSituacaoCheck = "N";
                        if(campoInformado(parent.toolTipErr) === false) parent.toolTipErr = "";
                        parent.toolTipErr += leaf.toolTipErr + " ";
                    }
                }

                for (const item of dpAux) {
                    item.children.forEach(leaf => visitaTree(item, leaf, (parent, leaf) => { parent.flgSituacaoCheck = "F";}));
                }

                for (const item of dpAux) {
                    item.children.forEach(leaf => visitaTree(item, leaf, atualizaSituacao));
                }

                setArvore(dpAux);

            } catch (e) {

                console.log(e);

                notificacaoErroRequest(e);

            }

        }

        confirm({
            message:"Desabilitar caracteristica",
            description: "Confirma desabilitar '" + registro.data.noParametro + "' ?",
            onOk: async () => {
                await doDesabilitar();
            }
        });

    }

    const habilitarItemCheck = async (registro) => {

        const doHabilitar = async () => {

            try {

                const resultado = await postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "sgr",
                    projeto: "mchklstprocesso"
                }, {
                    "acaoAExecutar": "habilitarItemChkLstProcesso",
                    cdChecklist: registro.data.cdChecklist,
                    nrItem: registro.data.nrItem,
                    "fakeData": {
                        mensagens:[{
                            id:"MensagemIdentificada.Ok",
                            mensagem:"Habilitado com sucesso !"
                        }]
                    }
                }, WORKSPACE_LOADING_INDICATOR_AREA);

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "MensagemIdentificada.Ok") {

                        notification.success({message:"Habilitar", description:"Processo concluído com sucesso !"})

                    } else {

                        console.log(msg);

                    }

                });

                const dpAux = refArvore.current.clone();

                dpAux.getByTree("key", registro.key).forEach(leaf => {
                    leaf.flgHabilitado = "S";
                    leaf.flgSituacaoCheck = "F";
                    leaf.toolTipErr = null;
                });

                const atualizaSituacao = (parent, leaf) => {
                    if(leaf.flgSituacaoCheck === "S") {
                        parent.flgTemVerificacao = "S";
                        if(parent.flgSituacaoCheck === "N") {
                        } else {
                            parent.flgSituacaoCheck = "S";
                        }
                    } else if(leaf.flgSituacaoCheck === "N") {
                        parent.flgTemVerificacao = "S";
                        parent.flgSituacaoCheck = "N";
                        if(campoInformado(parent.toolTipErr) === false) parent.toolTipErr = "";
                        parent.toolTipErr += leaf.toolTipErr + " ";
                    }
                }

                for (const item of dpAux) {
                    item.children.forEach(leaf => visitaTree(item, leaf, (parent, leaf) => { parent.flgSituacaoCheck = "F";}));
                }

                for (const item of dpAux) {
                    item.children.forEach(leaf => visitaTree(item, leaf, atualizaSituacao));
                }

                setArvore(dpAux);

                setTimeout(() => {

                    executaVerificacaoItens(dpAux);

                }, 500);

            } catch (e) {

                console.log(e);

                notificacaoErroRequest(e);

            }

        }

        confirm({
            message:"Habilitar caracteristica",
            description: "Confirma habilitar '" + registro.data.noParametro + "' ?",
            onOk: async () => {
                await doHabilitar();
            }
        });

    }

    const treeItemFormatter = ( item ) => {

        const resultado = {
            key: item.key,
            descricao: item.noItem,
            isLeaf: true,
            isOpen: false,
            data: {...item},
            flgSituacaoCheck: "I"
        }

        if(item.flgTemVerificacao==="S") {
            resultado.flgSituacaoCheck = item.flgSituacaoCheck||"F";
            resultado.toolTipErr = item.toolTipErr;
        }

        delete resultado.data.children;

        if(item.hasOwnProperty("children")) {
            resultado.isLeaf = (item.children?.length === 0);
        }

        return resultado;

    };

    const renderAcoesDisps = (registro) => {

        const botoes = [];

        if(campoInformado(registro.data.transacao?.cdTransacao) === true) {

            botoes.push(
                <BotaoToolTip
                    key={"btnExecTrn_" + registro.key}
                    tooltip={"Executa o processo deste item"}
                    icon={iconExecTrn}
                    onClick={async () => {
                        const canI = await props.canIUseIt(registro.data.transacao.cdTransacao);
                        if(canI === true) {
                            await executarTransacao(registro.data.transacao);
                        } else {
                            notification.warning({message:"Executar tarefa", description:"Você não tem a permissão necessária para executar esta operação na plataforma!"})
                        }
                    }}
                />
            );

        } else {

            botoes.push(
                <BotaoToolTip
                    key={"btnExecTrn_" + registro.key}
                    onClick={async () => {
                    }}
                />
            );

        }

        if(campoInformadoString(registro.data.txUrlHelp) === true) {

            botoes.push(
                <BotaoToolTip
                    key={"btnHelpTrn_" + registro.key}
                    tooltip={"Visualizar a ajuda deste item"}
                    icon={iconHelp}
                    onClick={async (evt) => {
                        const pane = {
                            title: registro.descricao,
                            noHelp: registro.data.txUrlHelp,
                            key: registro.key,
                        };
                        props.onExibeAjuda(evt, pane);
                        //window.open(registro.data.txUrlHelp,'_blank');
                    }}
                />
            );

        } else if(campoInformadoString(registro.data.transacao?.noHelp) === true) {

            botoes.push(
                <BotaoToolTip
                    key={"btnHelpTrn_" + registro.key}
                    tooltip={"Visualizar a ajuda deste item"}
                    icon={iconHelp}
                    onClick={async (evt) => {
                        const pane = {
                            title: registro.descricao,
                            noHelp: registro.data.transacao?.noHelp,
                            key: registro.data.transacao?.cdTransacao,
                        };
                        props.onExibeAjuda(evt, pane);
                        //window.open(registro.data.transacao?.noHelp,'_blank');
                    }}
                />
            );

        } else {

            botoes.push(
                <BotaoToolTip
                    key={"btnHelpTrn_" + registro.key}
                    onClick={async () => {
                    }}
                    disabled
                />
            );

        }

        if(campoInformadoString(registro.data.txUrlVideo) === true) {

            botoes.push(
                <BotaoToolTip
                    key={"btnVideoTrn_" + registro.key}
                    tooltip={"Visualizar um video de ajuda deste item"}
                    icon={iconVideo}
                    onClick={async () => {
                        window.open(registro.data.txUrlVideo,'_blank');
                    }}
                />
            );

        } else if(campoInformadoString(registro.data.transacao?.txUrlVideo) === true) {

            botoes.push(
                <BotaoToolTip
                    key={"btnVideoTrn_" + registro.key}
                    tooltip={"Visualizar um video de ajuda deste item"}
                    icon={iconVideo}
                    onClick={async () => {
                        window.open(registro.data.transacao?.txUrlVideo,'_blank');
                    }}
                />
            );

        } else {

            botoes.push(
                <BotaoToolTip
                    key={"btnVideoTrn_" + registro.key}
                    onClick={async () => {
                    }}
                    disabled
                />
            );

        }

        if(registro.data.flgHabilitado === "S" && usrMaster === true) {

            botoes.push(
                <BotaoToolTip
                    key={"btnDesabilitaCheck_" + registro.key}
                    tooltip={"Desabilitar a utilização desta caracteristica"}
                    icon={iconDesabilitar}
                    onClick={async () => {
                        await desabilitarItemCheck(registro);
                    }}
                />
            );

        } else if(registro.data.flgHabilitado === "N" && usrMaster === true) {

            botoes.push(
                <BotaoToolTip
                    key={"btnHabilitaCheck_" + registro.key}
                    tooltip={"Habilitar a utilização desta caracteristica"}
                    icon={iconHabilitar}
                    onClick={async () => {
                        await habilitarItemCheck(registro);
                    }}
                />
            );

        } else {

            if(campoInformado(registro.data.transacao?.cdTransacao) === true) {

                botoes.push(
                    <BotaoToolTip
                        key={"btnHabilitadoCheck_" + registro.key}
                        tooltip={"Utilização convencional obrigatória"}
                        onClick={async () => {
                        }}
                        disabled
                    />
                );

            }

        }

        return (
            <Space size={4} align="center">
                {botoes}
            </Space>
        );

    }

    const renderCheckDoItem = (registro) => {

        const botoes = [];

        if(registro.flgSituacaoCheck === "F") {

            if(registro.data.flgHabilitado === "N") {

                botoes.push(
                    <BotaoToolTip
                        key={"btnCheckDesab_" + registro.key}
                        tooltip={"Este item não esta em utillização"}
                        icon={iconDesabilitado}
                    />
                );

            } else {

                botoes.push(
                    <BotaoToolTip
                        key={"btnFaltaCheck_" + registro.key}
                        tooltip={"Este item ainda não foi verificado"}
                        icon={iconFaltaVerif}
                    />
                );

            }

        } else if(registro.flgSituacaoCheck === "S") {

            botoes.push(
                <BotaoToolTip
                    key={"btnCheckOk_" + registro.key}
                    tooltip={"Este item foi verificado e está de acordo."}
                    icon={iconVerifOk}
                />
            );

        } if(registro.flgSituacaoCheck === "N") {

            botoes.push(
                <BotaoToolTip
                    key={"btnCheckErr_" + registro.key}
                    tooltip={registro.toolTipErr}
                    icon={iconVerifErr}
                />
            );

        }

        return (
            <Space size={4} align="center">
                {botoes}
            </Space>
        );

    }

    const treeTitleRender = (record) => {

        const item = treeItemFormatter ( record );

        const acoes = renderAcoesDisps(item);

        const check = renderCheckDoItem(item);

        return (
            <div className={"tree-item"}>
                {check}
                <div>{item.descricao}</div>
                {acoes}
            </div>
        );

    };

    return (
        <Animate component=""
                 transitionName="fadeSiderCheckList"
                 exclusive
        >
            {aberto?
                <Sider id="sider-checklist" width={500} data-show={aberto} className={className.toClassName()}>
                    <div className={"content"} style={{position:"relative"}}>
                        <div className={"header"}>
                            <Button type={"default"}
                                onClick={() => onRequestClose()}
                            >
                                {iconFechar}
                            </Button>
                        </div>
                        <div className={"title"}>
                            {checkList?.noChecklist}
                        </div>
                        <div className={"containerRefresh"}>
                            <ReIF hidden={(currentCheckList?.flgSuporte==="S")}>
                                <BotaoToolTip
                                    key={"btnRefreshCheck"}
                                    tooltip={"Clique aqui para atualizar a situação das dicas/orientações"}
                                    icon={iconRefreshCheck}
                                    onClick={async () => {
                                        await pesquisarChecklist(currentCheckList);
                                    }}
                                />
                            </ReIF>
                        </div>
                        <div>
                            Checklist
                        </div>
                        <div className={"arvore"}>
                            <Tree
                                treeData={arvore}
                                height={tableHeight}
                                showLine={{
                                    showLeafIcon: false
                                }}
                                checkable={false}
                                selectable={false}
                                titleRender={treeTitleRender}
                            />
                        </div>
                    </div>
                </Sider>
            :null}
        </Animate>
    )

}

const mapStateToProps = function( { userSession, dataProvider, suite } ) {

    return {
        userSession: userSession,
        dataProvider: dataProvider,
        suite: suite
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setActiveAt: (position, key) => {
            return dispatch( setActiveAt(position, key) );
        },
        canIUseIt: (cdTransacao) => {
            return dispatch( canIUseIt(cdTransacao) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SiderChecklist);
