import React, {Suspense} from 'react';
import {Provider} from "react-redux";
import Store from "./store";
import {ConnectedRouter} from "connected-react-router";
import {history} from "./reducer";
import {HashRouter, Switch} from "react-router-dom";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import AguardandoTransacao from "./framework/componentes/AguardandoTransacao";
import LoginRoute from "./framework/routes/LoginRoute";
import ExactPathRoute from "./framework/routes/ExactPathRoute";
import ResetSenhaUsuario from "./framework/pages/ResetSenhaUsuario";
import AuthRoute from "./framework/routes/AuthRoute";
import Login from "./framework/pages/Login";
import IY2BHotKeys from "./framework/componentes/IY2BHotKeys";
import {lazyRetry} from "./framework/lib/iy2b-react";
import {useIntl} from "@ant-design/pro-provider";

const Workspace = React.lazy(() => lazyRetry(() => import('./framework/pages/Workspace/workspace')));
const Contratar = React.lazy(() => lazyRetry(() => import('./pages/Contratar')));
const Assinar = React.lazy(() => lazyRetry(() => import('./pages/Assinar/Assinar.js')));
const VisualizarDocumento = React.lazy(() => lazyRetry(() => import('./pages/VisualizarDocumento/visualizarDocumento')));

const App = ( props ) => {

    const intl = useIntl();

    console.log("App", intl);

    return (
        <Provider store={Store}>
            <ConnectedRouter history={history}>
                <HashRouter>
                    <ErrorBoundary>
                        <Suspense fallback={<AguardandoTransacao />}>
                            <IY2BHotKeys>
                                <Switch>
                                    <LoginRoute path="/" exact={true} />
                                    <ExactPathRoute path="/assinar">
                                        <Assinar />
                                    </ExactPathRoute>
                                    <ExactPathRoute path="/contratar">
                                        <Contratar />
                                    </ExactPathRoute>
                                    <ExactPathRoute path="/resetsenhausr">
                                        <ResetSenhaUsuario />
                                    </ExactPathRoute>
                                    <ExactPathRoute path="/visualizar">
                                        <VisualizarDocumento />
                                    </ExactPathRoute>
                                    <AuthRoute path="/login" type="guest">
                                        <Login />
                                    </AuthRoute>
                                    <AuthRoute path="/ws" type="private">
                                        <Workspace />
                                    </AuthRoute>
                                </Switch>
                            </IY2BHotKeys>
                        </Suspense>
                    </ErrorBoundary>
                </HashRouter>
            </ConnectedRouter>
        </Provider>
    );

}

export default App;
