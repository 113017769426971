import '../assets/css/tema.scss';
import '../assets/css/containerContent.scss';
import "antd/dist/antd.css";

import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {Layout} from 'antd';
import {scrollToTop} from "../lib/iy2b-javascript";
import {lazyRetry} from "../lib/iy2b-react";

// import TransacaoLoadingIndicator from "../componentes/LoadingIndicator/TransacaoLoadingIndicator";
const TransacaoLoadingIndicator = React.lazy(() => lazyRetry(() => import('../componentes/LoadingIndicator/TransacaoLoadingIndicator')));

const { Content } = Layout;

export const TransacaoContentContext = React.createContext(null);

const TransacaoContent = ( props ) => {

    const { withFooterToolbar = false } = props;

    const [layoutHeight, setLayoutHeight] = useState(0);

    const [loaderHeight, setLoaderHeight] = useState(0);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("useEffect inicializa TransacaoContent", withFooterToolbar);

        const height = document.getElementById('containerContent').clientHeight;

        // gap do tabHeader = 50
        setLayoutHeight(height - 50 - (withFooterToolbar===true?45:0) );

        setLoaderHeight(height - 50 );

        return () => {

        };

    }, []);

    const layoutId = "transacaoContent-" + props.tcKey;

    const contextValue = {
        containerName: layoutId,
        containerHeight: () => {
            const height = document.getElementById(layoutId)?.clientHeight;
            return height;
        },
        scrollToTop: () => {
            scrollToTop(layoutId);
        }
    };

    return (
        <Layout id={layoutId} className={"transacaoContent"} style={{
            height: layoutHeight
        }}>
            <TransacaoContentContext.Provider value={contextValue}>
                <TransacaoLoadingIndicator height={loaderHeight} area={"tc-area-" + props.tcKey} />
                <Content className={"tc-body"}>
                    {React.cloneElement(props.content, { availableHeight: layoutHeight })}
                </Content>
            </TransacaoContentContext.Provider>
        </Layout>
    );

};

const mapStateToProps = function( {appConfig, userSession} ) {

    return {
        appConfig: appConfig,
        userSession: userSession
    };

};

export default connect(mapStateToProps)(TransacaoContent);
