import "antd/dist/antd.css";
import '@ant-design/pro-layout/dist/layout.css' ;
import '@ant-design/pro-form/dist/form.css' ;

import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef} from 'react';
import { ProFormText } from "@ant-design/pro-form";
import {Col, notification, Row} from "antd";
import FormEndereco from "../../framework/componentes/FormEndereco";
import {campoInformado} from "../../framework/lib/iy2b-javascript";
import {postWS} from "../../framework/lib/RequestAPI";
import {useStateRef} from "../../framework/lib/iy2b-react";

const StepCadEmpresa = props => {

    const formEnderecoApi = useRef();

    const [_1, setPrimeiraExibicao, refPrimeiraExibicao] = useStateRef(true);
    const [_2, setCnpjJaCadastrado, refCnpjJaCadastrado] = useStateRef(false);

    const { exibeMeiosContato = false } = props;

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        if(props.api) {
            props.api.current = {
                onValuesChange: onValuesChange,
                onPageShow: onPageShow,
                onFinish: onFinish
            };
        }

        if(props.cookies) {

            const cookieCadEmp = props.cookies.get("cadEmp");

            if(campoInformado(cookieCadEmp)) {

                props.formRef.current?.setFieldsValue(cookieCadEmp);

            }

        }

        return () => {

            console.log("final useEffect inicializa StepCadEmpresa");

        };

    }, []);

    useEffect(() => {

        const newValue = {
            noRazaoSocial:props.certificado?.noPessoa,
            nrCNPJ: props.certificado?.nrDocto
        };

        props.formRef.current?.setFieldsValue(newValue);

    }, [props.certificado]);

    const onFinish = async () => {

        if(refCnpjJaCadastrado.current === true) {
            notification.error({message:"Ja é cliente", description:"Este CNPJ ja esta cadastrado como assinante da plataforma. Por favor faça o login para acessar o sistema."})
            return false;
        }

        return true;
    }

    const onPageShow = async () => {

        if(refPrimeiraExibicao.current === true) {

            const { nrCNPJ } = props.formRef.current?.getFieldsValue();

            const nrCGCCIC = nrCNPJ.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");

            const payload = {
                nrCGCCIC,
                "tipoPesq": "emp"
            };

            let jaExiste = false;

            const resposta = await postWS({
                sistema: "sgr",
                projeto: "mwizcontratacaobuild",
                acaoWebAExecutar: "pesquisar",
                acaoAExecutar: "pesquisaNrCGCCIC"
            }, payload);

            resposta.mensagens.forEach(msg => {

                if(msg.id === "pessoa") {
                    jaExiste = true;
                    notification.error({message:"Ja é cliente", description:"Este CNPJ ja esta cadastrado como assinante da plataforma. Por favor faça o login para acessar o sistema."})
                }

            });

            setCnpjJaCadastrado(jaExiste);

        }

        setPrimeiraExibicao(false);

    }

    const onValuesChange = (changed, all) => {

        formEnderecoApi.current?.onValuesChange(changed, all);

    }

    return (
        <div className={"containerStepCadEmpresa"}>
            <Row gutter={16}>
                <Col xs={16} md={12}>
                    <ProFormText
                        width={"lg"}
                        name = "noRazaoSocial"
                        label = "Razão social"
                        tooltip = "Razão social da empresa"
                        disabled
                    />
                </Col>
                <Col xs={24} md={8} lg={6}>
                    <ProFormText
                        width={"sm"}
                        name = "nrCNPJ"
                        label = "CNPJ"
                        tooltip = "CNPJ da empresa"
                        disabled
                    />
                </Col>
                <Col xs={24} md={8} lg={6}>
                    <ProFormText
                        width={"sm"}
                        name = "noFantasia"
                        label = "Nome fantasia"
                        tooltip = "Nome fantasia da empresa"
                        placeholder={"Digite o nome fantasia"}
                        rules={[ {required: true, message:"Informe o nome fantasia da sua empresa"}, {message:"No máximo 20 letras", max:20}]}
                    />
                </Col>
            </Row>
            <FormEndereco
                formRef = { props.formRef }
                api = { formEnderecoApi }
                exibeInscricoes = { true }
                exibeMeiosContato = { exibeMeiosContato }
            />
        </div>
    );

}

export default StepCadEmpresa;
