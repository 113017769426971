import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import '@ant-design/pro-form/dist/form.css' ;
import '@ant-design/pro-layout/dist/layout.css' ;
import './style.scss';

import React, {useState, useEffect, useRef, useContext} from 'react';
import {connect} from "react-redux";
import ProForm , {
    ProFormText ,
} from '@ant-design/pro-form' ;
import {FooterToolbar} from "@ant-design/pro-layout";
import {Button, Col, Form, notification, Popconfirm, Row, Select, Transfer} from "antd";
import TooltipOnIcon from "../../componentes/TooltipOnIcon";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import fontawesome from "@fortawesome/fontawesome";
import WizNovoUsuario from "./wizNovoUsuario";
import {getPerfilAcessoMenu} from "../../reducer/wsDataProvider";
import {postJSON} from "../../lib/RequestAPI";
import IY2BProFormMasked from "../../componentes/IY2BProFormMasked";
import IY2BHiddenRow from "../../componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../componentes/IY2BProFormHidden";
import {TransacaoContentContext} from "../../pages/TransacaoContent";
import {atualizarInfoComplUsr} from "../../reducer/userSession";

fontawesome.library.add(faUser);

const iconUser = <FontAwesomeIcon icon={faUser} />;

const telCelPatterns = [{
    pattern: "(00) 0000-0000",
    length: 10, // sem mascara
    validator: (value) => {
        return {valido:true, message:"mensagem de erro qdo for invalido"};
    }
}, {
    pattern: "(00) 0-0000-0000",
    length: 11, // sem mascara
    validator: (value) => {
        return {valido:true, message:"mensagem de erro qdo for invalido"};
    }
}];

const cpfPatterns = [{
    pattern: "000.000.000-00",
    length: 11, // sem mascara
    validator: async (value) => {
        return {valido:true, message:"mensagem de erro qdo for invalido"};
    }
}];

const MeusUsuarios = (props) => {

    const { scrollToTop } = useContext(TransacaoContentContext);

    const {loadingArea} = props;

    const formRef = useRef();

    const [dpUsuarios, setDpUsuarios] = useState([]);
    const [usuarioAtual, setUsuarioAtual] = useState(null);
    const [campoAlterado, setCampoAlterado] = useState(false);
    const [protegeTela, setProtegeTela] = useState(true);

    const [exibeWizNovoUsuario, setExibeWizNovoUsuario] = useState(false);

    const [dpPerfilAcesso, setPerfilAcesso] = useState([]);
    const [dpEmpresas, setEmpresas] = useState( []);
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    const usrMaster = props.userSession?.master;

    const { nrMaxUsrs, nrTotUsrs } = props.userSession?.planoContratado;

    const cdPessoaEmpMaster = props.userSession?.planoContratado?.cdPessoaEmpMaster;

    useEffect(() => {

        props.getPerfilAcessoMenu(props.userSession?.planoContratado).then(resultado => {

            setPerfilAcesso(resultado.map(perfil => {
                return {value: perfil.cdPerfilAcesso, label: perfil.noPerfilAcesso}
            }));

        });

        const {planoContratado, planos} = props.userSession;

        const empsPlanoAtual = planos.filter(plano => plano.cdPessoaEmpMaster === planoContratado.cdPessoaEmpMaster);

        setEmpresas(empsPlanoAtual.map(emp => {

            const empresa = props.suite.listaEmp.filter(empresa => empresa.pessoaEmp.cdPessoa === emp.cdPessoaEmp);

            return {key: empresa[0].pessoaEmp.cdPessoa, title: empresa[0].noOficial}

        }));

        pesquisaUsuarios ();

        return () => {
        };
    }, []);

    const pesquisaUsuarios = () => {

        const {planoContratado} = props.userSession;

        const pesquisar = async () => {

            const payload = {
                acaoAExecutar: "pesquisaUsuarios",
                cdPessoaEmpMaster: planoContratado.cdPessoaEmpMaster
            }

            try {

                const resposta = await postJSON({
                    sistema: "smk",
                    projeto: "musuariopiy2b",
                    acaoWebAExecutar: "pesquisar"
                }, payload, loadingArea);

                resposta.mensagens.forEach(msg => {

                    if(msg.id === "listaUsuario") {

                        setDpUsuarios(msg.lista.map(usuario => {
                            return {
                                value: usuario.cdPessoaUsr,
                                label: usuario.noPessoa,
                                data: usuario
                            }
                        }));

                    } else {

                        console.log(msg);

                    }

                });

            } catch (e) {

                console.log(e);

                notification.error({message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

            }

        }

        pesquisar();

    }

    const onChangeUsuario = (value) => {

        setUsuarioAtual (value) ;

        pesquisaPK(value);

    }

    const pesquisaPK = async (registro) => {

        const {planoContratado} = props.userSession;

        const pesquisar = async () => {

            const payload = {
                acaoAExecutar: "pesquisaPkUsuario",
                cdPessoaEmpMaster: planoContratado.cdPessoaEmpMaster,
                cdPessoaUsr: registro.value
            }

            try {

                const resposta = await postJSON({
                    sistema: "smk",
                    projeto: "musuariopiy2b",
                    acaoWebAExecutar: "pesquisar"
                }, payload, loadingArea);

                resposta.mensagens.forEach(msg => {

                    if(msg.id === "pkUsuario") {

                        setRegistroNaTela(msg.modeloPadrao);

                    } else {

                        console.log(msg);

                    }

                });

            } catch (e) {

                console.log(e);

                notification.error({message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

            }

        }

        await pesquisar();

    }

    const setRegistroNaTela = (registro) => {

        const perfilAtual = dpPerfilAcesso.filter(perfil => perfil.value === registro.cdPerfilAcesso);

        const registroNaTela = {
            cdPessoaUsr: registro.cdPessoaUsr,
            nrCPF: registro.nrCGCCIC.trim(),
            noApelido: registro.noFantasia.trim(),
            noUsuario: registro.noPessoa.trim(),
            noLogin: registro.noLogin.trim(),
            noEMail: registro.noEmail.trim(),
            nrTelCelular: registro.nrTelCelular.trim(),
            cdPerfilAcesso: perfilAtual[0],
            empresasComAcesso: registro.empresas
        };

        formRef.current?.setFieldsValue(registroNaTela);

        setCampoAlterado(false);

        setProtegeTela ( false );

    }

    const btnAdicionarUsr_Click = () => {

        if(nrMaxUsrs > nrTotUsrs) {

            setExibeWizNovoUsuario(true);

        } else {

            notification.error({duration:8, message:"Limite contratado atingido", description:"A cota de usuários cadastrados chegou ao seu limite. Se necessário troque o seu plano de assinatura."})

        }

    }

    const limpaTela = () => {

        setUsuarioAtual (null) ;

        const registroNaTela = {
            cdPessoaUsr: null,
            nrCPF: "",
            noApelido: "",
            noUsuario: "",
            noLogin: "",
            noEMail: "",
            nrTelCelular: "",
            cdPerfilAcesso: null,
            empresasComAcesso: []
        };

        formRef.current?.setFieldsValue(registroNaTela);

        setCampoAlterado(false);

        setProtegeTela ( true );

    }

    const excluirUsuario = async () => {

        scrollToTop();

        const {planoContratado} = props.userSession;

        const payload = {
            acaoAExecutar: "acaoCRUD",
            cdPessoaEmpMaster: planoContratado.cdPessoaEmpMaster,
            lista:[ {
                cdPessoaUsr: usuarioAtual.value
            }]
        };

        try {

            const resultado = await postJSON({
                sistema: "smk",
                projeto: "musuariopiy2b",
                acaoWebAExecutar: "excluir"
            }, payload, loadingArea);

            setCampoAlterado(false);

            limpaTela();

            await props.atualizarInfoComplUsr();

            pesquisaUsuarios();

            notification.success({message: "Sucesso", description:"Usuário excluído da plataforma com sucesso !"})

        } catch (e) {

            notification.error({message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

            console.log(e);

        }

    }

    const salvarUsuario = async () => {

        if(campoAlterado === false) {
            notification.warning({message:"Salvar", description:"Nenhuma informação foi alterada. Não é necessário salvar !"})
            return false;
        }

        scrollToTop();

        const camposDoForm = await formRef.current?.validateFields();

        const {planoContratado} = props.userSession;

        const payload = {
            acaoAExecutar: "atualizarPermissoes",
            cdPessoaEmpMaster: planoContratado.cdPessoaEmpMaster,
            cdPessoaUsr: camposDoForm.cdPessoaUsr,
            cdPerfilAcesso: camposDoForm.cdPerfilAcesso.value,
            empresas: camposDoForm.empresasComAcesso
        }

        try {

            const resposta = await postJSON({
                sistema: "smk",
                projeto: "musuariopiy2b",
                acaoWebAExecutar: "processarnegocio"
            }, payload, loadingArea);

            setCampoAlterado(false);

            notification.success({message: "Sucesso", description:"Permissões do usuário alteradas na plataforma com sucesso !"})

        } catch (e) {

            console.log(e);

            notification.error({message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})
        }

    }

    const onUsuarioAdicionado = () => {

        pesquisaUsuarios ();

    }

    const handleChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys( [...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    return (
        <div className={"meus-usuario-container"}>
            <Row gutter={16} style={{marginBottom: 8}}>
                <Col xs={24} md={16} lg={12} xl={8}>
                    <TooltipOnIcon label={"Usuário"} title={"Escolha o usuário para editar as informações"} />
                    <Select style={{width:"100%"}}
                            labelInValue
                            onChange={onChangeUsuario}
                            placeholder={"Escolha um usuário para editar as informações"}
                            options={dpUsuarios}
                            value={usuarioAtual}
                    />
                </Col>
                {(usrMaster==="S")?
                    <Col xs={24} md={6} lg={4} xl={2}>
                        <label>&nbsp;</label>
                        <Button type={"default"} icon={iconUser} onClick={ btnAdicionarUsr_Click }>&nbsp;Adicionar novo usuário</Button>
                    </Col>
                    : null
                }
            </Row>
            <ProForm
                formRef = { formRef }
                submitter = {{
                    render : () =>
                        <FooterToolbar
                            extra={
                                <>
                                    {(usrMaster==="S" && usuarioAtual!=null)?
                                        <Popconfirm
                                            title = "Confirma a exclusão deste usuário da plataforma ?"
                                            okText = "Sim, excluir este usuário !"
                                            cancelText="Não"
                                            onConfirm={excluirUsuario}
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        >
                                            <Button type={"danger"}>Excluir este usuário</Button>
                                        </Popconfirm>
                                        :null}
                                </>
                            }
                        >
                            {(usuarioAtual!=null)?<Button type={"primary"} onClick={salvarUsuario}>Salvar usuário</Button>:null}
                        </FooterToolbar>
                }}
                onFieldsChange = {(changedFields) => {
                    setCampoAlterado(changedFields.length > 0);
                }}
            >
                <Row gutter={16}>
                    <Col xs={{span:18}} md={{span:24}}>
                        <ProForm.Group>
                            <IY2BProFormMasked
                                width={"sm"}
                                label={"CPF"}
                                name="nrCPF"
                                tooltip = "CPF do usuário"
                                rules={[{ required: true, message: 'Por favor, informe o CPF!' }]}
                                patterns={cpfPatterns}
                                disabled
                            />
                            <ProFormText
                                width={"lg"}
                                name = "noLogin"
                                label = "Login no sistema"
                                tooltip = "Infome o email de login do usuário na plataforma"
                                placeholder={"Digite o email de login do usuário na plataforma"}
                                rules={[
                                    {required: true, message:"Informe o email do usuário"},
                                    {message:"Informe um email válido", type:"email"},
                                    {message:"No máximo 60 caracteres", max:60}
                                ]}
                                disabled
                            />
                        </ProForm.Group>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={{span:18}} md={{span:24}}>
                        <ProForm.Group>
                            <ProFormText
                                width={"md"}
                                name = "noApelido"
                                label = "Apelido"
                                placeholder={"Digite o nome apelido"}
                                tooltip = "Nome abreviado / apelido do usuário"
                                rules={[ {required: true, message:"Informe o nome abreviado"}, {message:"No máximo 20 caracteres", max:20}]}
                                disabled
                            />
                            <ProFormText
                                width={"lg"}
                                name = "noUsuario"
                                label = "Nome do usuário"
                                placeholder={"Digite o nome usuário"}
                                tooltip = "Nome do usuário"
                                rules={[ {required: true, message:"Informe o nome completo"}, {message:"No máximo 60 caracteres", max:60}]}
                                disabled
                            />
                        </ProForm.Group>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={{span:18}} md={{span:24}}>
                        <ProForm.Group>
                            <ProFormText
                                width={"lg"}
                                name = "noEMail"
                                label = "E-Mail de contato"
                                tooltip = "Infome o email de contato do usuário"
                                placeholder={"Digite o email do usuário"}
                                rules={[
                                    {required: true, message:"Informe o email do usuário"},
                                    {message:"Informe um email válido", type:"email"},
                                    {message:"No máximo 60 caracteres", max:60}
                                ]}
                                disabled
                            />
                            <IY2BProFormMasked
                                width={"sm"}
                                label={"Telefone celular"}
                                name="nrTelCelular"
                                tooltip = "Nro. telefone celular do usuario"
                                rules={[{ required: true, message: 'Por favor, informe o nro de telefone celular !' }]}
                                patterns={telCelPatterns}
                                disabled
                            />
                        </ProForm.Group>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24}>
                        <ProForm.Item
                            name="cdPerfilAcesso"
                            label="Perfil de acesso"
                            tooltip={"Escolha o perfil de acesso deste novo usuário. Define as operações disponíveis para o usuário"}
                            rules={[
                                {required: true, message:"Informe o nível de permissão de acesso"},
                            ]}
                        >
                            <Select style={{width:"100%"}}
                                    labelInValue
                                    placeholder={"Escolha um perfil de acesso as informações deste usuário"}
                                    options={dpPerfilAcesso}
                                    disabled={protegeTela}
                            />
                        </ProForm.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24}>
                        <ProForm.Item
                            name="empresasComAcesso"
                            label="Seleção de empresas com permissão de acesso"
                            tooltip={"Coloque no lado direito as empresas que este novo usuário tem permissão de acesso. Define em quais empresas o usuário consegue executar as operações"}
                            valuePropName={"targetKeys"}
                            rules={[
                                {required: true, message:"Escolha ao menos uma empresa com acesso para este usuário"},
                            ]}
                        >
                            <Transfer
                                dataSource={dpEmpresas}
                                titles={['Disponíveis', 'Com acesso']}
                                targetKeys={targetKeys}
                                selectedKeys={selectedKeys}
                                onChange={handleChange}
                                onSelectChange={handleSelectChange}
                                render={item => item.title}
                                oneWay
                                disabled={protegeTela}
                            />
                        </ProForm.Item>
                    </Col>
                </Row>
                <IY2BHiddenRow>
                    <IY2BProFormHidden name={"cdPessoaUsr"}/>
                </IY2BHiddenRow>
            </ProForm>
            <WizNovoUsuario
                exibePopUp={exibeWizNovoUsuario}
                onRequestClose={() => setExibeWizNovoUsuario(false)}
                appConfig = { props.appConfig }
                cdPessoaEmpMaster = { cdPessoaEmpMaster }
                onUsuarioAdicionado = { onUsuarioAdicionado }
            />
        </div>
    );

}

const mapStateToProps = function( { appConfig, userSession, suite } ) {

    return {
        appConfig: appConfig,
        suite: suite,
        userSession: userSession
    };

};

const mapDispatchToProps = dispatch => {
    return {
        getPerfilAcessoMenu: (planoContrVenda) => {
            return dispatch ( getPerfilAcessoMenu(planoContrVenda) )
        },
        atualizarInfoComplUsr: () => {
            return dispatch ( atualizarInfoComplUsr() );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MeusUsuarios);
