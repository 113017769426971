import {Button, Tooltip} from "antd";
import React from "react";

const BotaoToolTip = ( props ) => {

    const { tooltip, placement="rightBottom", type="text", icon, onClick=null, disabled=false, label=null, block=false, hidden=false } = props;

    const componente = (hidden===true) ? null : ((label === null) ?
        <Tooltip title={tooltip} placement={placement}>
            <Button type={type} icon={icon}
                    onClick={async (evt) => {
                        if(onClick !== null) {
                            await onClick(evt);
                        }
                    }}
                    disabled={disabled}
            />
        </Tooltip>
    :
        <Tooltip title={tooltip} placement={placement}>
            <Button type={type}
                    onClick={async (evt) => {
                        if(onClick !== null) {
                            await onClick(evt);
                        }
                    }}
                    disabled={disabled}
                    block={block}
            >
                {icon}&nbsp;{label}
            </Button>
        </Tooltip>)
    ;

    return componente;

}

export default BotaoToolTip;
