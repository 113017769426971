import { useAppConfig as useAppConfigRequest, useStore as useStoreRequest, useUserSession as useUserSessionRequest } from "../lib/RequestAPI";
import { useAppConfig as useAppConfigLogin, useStore as useStoreLogin, useUserSession as useUserSessionLogin } from "../lib/LoginAPI";

console.log("configManager")

export function useSetupConfig ({store, appConfig}) {
    useStore(store);
    useAppConfig(appConfig);
}

export function useStore (store) {
    useStoreRequest(store);
    useStoreLogin(store);
}

export function useAppConfig (appConfig) {
    useAppConfigRequest(appConfig);
    useAppConfigLogin(appConfig);
}

export function useUserSession (session) {

    console.log(session);

    useUserSessionRequest(session);

    useUserSessionLogin(session);

}
