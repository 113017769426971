import React from "react";

import TransacaoContent from "../pages/TransacaoContent";
import PageContent from "../componentes/PageContent";
import transacaoToPage from "../../reducer/transacaoToPage";

import {faListUl} from '@fortawesome/free-solid-svg-icons';
import fontawesome from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from "qs";
import {lazyRetry} from "../lib/iy2b-react";


import MeuPerfil from "../transacoes/MeuPerfil";
import MeuPlano from "../transacoes/MeuPlano";
import MinhasEmpresas from "../transacoes/MinhasEmpresas";
import MeusUsuarios from "../transacoes/MeusUsuarios";

const CheckListProcesso = React.lazy(() => lazyRetry(() => import('../transacoes/ChecklistProcesso/checkListProcesso')));

fontawesome.library.add(faListUl);

const iconDefaultMenu = <FontAwesomeIcon icon={faListUl} />;

const INICIALIZA_TRANSACOES = 'smk/trnManager/INICIALIZA_TRANSACOES';

const TENHO_PERMISSAO = 'smk/trnManager/TENHO_PERMISSAO';

const SET_ACTIVE = 'smk/trnManager/SET_ACTIVE';
const SET_ACTIVE_AT = 'smk/trnManager/SET_ACTIVE_AT';
const CLOSE = 'smk/trnManager/CLOSE';
const CLOSE_ALL = 'smk/trnManager/CLOSE_ALL';

const initialState = {
    current: null,
    menu: [],
    disponiveis: [],
    emExecucao: [],
    acessosEspeciais: {
        empresas: false,
        usuarios: false
    }
};

const findContent = (state, item) => {

    item.key = item.cdTransacao;
    item.title = item.noTransacao;

    if(item.tpTransacao === "P") {

        item.paramWeb = item.paramWeb ? qs.parse(item.paramWeb) : null;

        let trnPage = transacaoToPage.get(item.cdTransacao, item.paramWeb);

        if(trnPage === null || trnPage === undefined) {
            trnPage =
                <PageContent
                    tcKey={item.cdTransacao}
                    style={{
                        "border": "solid red 1px",
                        "minHeight": "100%",
                    }}
                ><>{item.noTransacao} NÃO encontrada no build. Por favor verificar !</>
                </PageContent>
            ;
        }

        item.favorito = false;
        item.closable = true;
        item.content =
            <TransacaoContent
                tcKey={item.cdTransacao}
                content={trnPage}
            />
        ;

    } else {

        if(item.icon == undefined) {
            item.icon = iconDefaultMenu;
        }

    }

    if(item.hasOwnProperty("childs")) {
        item.childs.sort((a,b) => a.nrOrdem - b.nrOrdem);
        return item.childs.map((child) => {
            return findContent(state, child);
        });
    } else {
        state.disponiveis.push(item);
        return item;
    }

};

const marcarFavoritos = (state, item) => {

    if(item.hasOwnProperty("childs")) {

        item.childs.forEach(child => marcarFavoritos(state, child) );

    } else {

        state.disponiveis.forEach(trn => (trn.key === item.cdTransacao) ? trn.favorito = true : false);

    }

}

const adicionarDisponiveisDoBuild = (state) => {

    state.disponiveis.push({
        key: "85061",
        title: "Meu perfil",
        favorito: false,
        closable: true,
        content:
            <TransacaoContent
                tcKey="meuPerfil"
                withFooterToolbar
                content={
                    <PageContent
                        tcKey={"meuPerfil"}
                        id={"meuPerfil"}
                    ><MeuPerfil />
                    </PageContent>
                }
            />
    });

    state.disponiveis.push({
        key: "85062",
        title: "Meu plano",
        favorito: false,
        closable: true,
        content:
            <TransacaoContent
                tcKey="meuPlano"
                withFooterToolbar
                content={
                    <PageContent
                        tcKey={"meuPlano"}
                        id={"meuPlano"}
                    ><MeuPlano />
                    </PageContent>
                }
            />
    });

    state.disponiveis.push({
        key: "85063",
        title: "Minhas empresas",
        favorito: false,
        closable: true,
        content:
            <TransacaoContent
                tcKey="minhasEmpresas"
                withFooterToolbar
                content={
                    <PageContent
                        tcKey={"minhasEmpresas"}
                        id={"minhasEmpresas"}
                    ><MinhasEmpresas />
                    </PageContent>
                }
            />
    });

    state.disponiveis.push({
        key: "85064",
        title: "Usuários",
        favorito: false,
        closable: true,
        content:
            <TransacaoContent
                tcKey="meusUsuarios"
                withFooterToolbar
                content={
                    <PageContent
                        tcKey={"meusUsuarios"}
                        id={"meusUsuarios"}
                    ><MeusUsuarios />
                    </PageContent>
                }
            />
    });

/*
    state.disponiveis.push({
        key: "10000005",
        title: "O que desejo fazer ...",
        favorito: false,
        closable: true,
        content:
            <TransacaoContent
                tcKey="checkListProcesso"
                withFooterToolbar
                content={
                    <PageContent
                        tcKey={"checkListProcesso"}
                        id={"checkListProcesso"}
                    ><CheckListProcesso />
                    </PageContent>
                }
            />
    });
*/

}

export default function transacaoManager (state = initialState, action) {

    let running;
    let newState = {...state};

    switch (action.type) {

        case INICIALIZA_TRANSACOES:

            const cdTransacaoEspeciais = parseInt(process.env.REACT_APP_CDTRANSACAO_ESPECIAIS);

            newState.menu.clear();
            newState.disponiveis.clear();

            newState.menu.addAll(
                action.payload.arvore.filter(item => {
                    if(item.cdTransacao === cdTransacaoEspeciais) return false;
                    return true;
                })
            );

            newState.menu.map(item => {
                return findContent(newState, item);
            });

            if(action.payload.arvoreFav) action.payload.arvoreFav.forEach(item => marcarFavoritos(newState, item) );

            adicionarDisponiveisDoBuild (newState);

            const menuEspeciais = action.payload.arvore.filter(item => {
                return (item.cdTransacao === cdTransacaoEspeciais);
            })

            if(menuEspeciais.length > 0) {
                newState.acessosEspeciais.empresas = menuEspeciais[0].childs.filter(trn => trn.cdTransacao === parseInt(process.env.REACT_APP_CDTRANSACAO_ESPECIAIS_EMPRESAS)).length > 0 ;
                newState.acessosEspeciais.usuarios = menuEspeciais[0].childs.filter(trn => trn.cdTransacao === parseInt(process.env.REACT_APP_CDTRANSACAO_ESPECIAIS_USUARIOS)).length > 0 ;
            }

            return newState;

        case CLOSE:

            running = newState.emExecucao.filter(transacao => transacao.key == action.payload.key);

            if(running.length > 0) {

                newState.emExecucao.removeItem(running[0]);

            }

            if(action.payload.key == newState.current) {
                if(newState.emExecucao.length > 0) {
                    newState.current = newState.emExecucao[0].key;
                } else {
                    newState.current = null;
                }
            }

            return newState;

        case CLOSE_ALL:

            newState.emExecucao.clear();

            newState.current = null;

            return newState;

        case SET_ACTIVE_AT:

            running = newState.emExecucao.filter(transacao => transacao.key == action.payload.key);

            if(running.length == 0) {

                const available = newState.disponiveis.filter(transacao => transacao.key == action.payload.key);

                newState.current = available[0].key;

                newState.emExecucao.splice(action.payload.position, 0, available[0]);

            } else {

                newState.current = running[0].key;

            }

            return newState;

        case SET_ACTIVE:

            running = newState.emExecucao.filter(transacao => transacao.key == action.payload.key);

            if(running.length == 0) {

                const available = newState.disponiveis.filter(transacao => transacao.key == action.payload.key);

                newState.current = available[0].key;

                newState.emExecucao.push(available[0]);

            } else {

                newState.current = running[0].key;

            }

            return newState;

        default:
            return state;

    }

};

// Action Creators
export function setActive(key) {

    return (dispatch, getState) => {

        dispatch({
            type: SET_ACTIVE,
            payload: {
                key: parseInt(key)
            }
        });

        return Promise.resolve(getState().transacaoManager);

    };

}

export function setActiveAt(position, key) {

    return (dispatch, getState) => {

        dispatch({
            type: SET_ACTIVE_AT,
            payload: {
                position: parseInt(position),
                key: parseInt(key)
            }
        });

        return Promise.resolve(getState().transacaoManager);

    };

}

export function close(key) {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE,
            payload: {
                key: parseInt(key)
            }
        });

        return Promise.resolve(getState().transacaoManager);

    };

}

export function closeAll(key) {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE_ALL
        });

        return Promise.resolve(getState().transacaoManager);

    };

}

export function canIUseIt(cdTransacao) {

    return (dispatch, getState) => {

        return new Promise(async (resolve, reject) => {

            const trnManager = await Promise.resolve(getState().transacaoManager);

            const resposta = (trnManager.disponiveis.filter(item => item.cdTransacao === cdTransacao).isEmpty() === false);

            resolve(resposta);

        });

    };

}


export {
    INICIALIZA_TRANSACOES
}
