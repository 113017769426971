import {postJSON} from "./RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../pages/Workspace/workspace";

const cache = { };

const cachedPostJSON = async (props, loadingArea) => {

    const {sistema="sgr", projeto="mmoeda", acaoWebAExecutar="pesquisar", postKey="body", payload} = props;

    const { acaoAExecutar } = payload;

    let resultado = null;

    if(cache.hasOwnProperty(projeto)) {

        const cacheProjeto = cache[projeto];

        if(cacheProjeto.hasOwnProperty(sistema)) {

            const cacheSistema = cacheProjeto[sistema];

            if(cacheSistema.hasOwnProperty(acaoAExecutar)) {

                const cacheAExecutar = cacheSistema[acaoAExecutar];

                if(cacheAExecutar.hasOwnProperty(acaoWebAExecutar)) {

                    const cacheWebAExecutar = cacheAExecutar[acaoWebAExecutar];

                    if(cacheWebAExecutar.hasOwnProperty(postKey)) {

                        resultado = cache[projeto][sistema][acaoAExecutar][acaoWebAExecutar][postKey];

                    } else {

                        cache[projeto][sistema][acaoAExecutar][acaoWebAExecutar][postKey] = null;

                    }

                } else {

                    cache[projeto][sistema][acaoAExecutar][acaoWebAExecutar] = { };

                }

            } else {

                cache[projeto][sistema][acaoAExecutar] = { };

            }

        } else {

            cache[projeto][sistema] = { };

        }

    } else {
        cache[projeto] = { };
        cache[projeto][sistema] = { };
        cache[projeto][sistema][acaoAExecutar] = { };
        cache[projeto][sistema][acaoAExecutar][acaoWebAExecutar] = { };
        cache[projeto][sistema][acaoAExecutar][acaoWebAExecutar][postKey] = null;
    }

    if(resultado === null) {

        cache[projeto][sistema][acaoAExecutar][acaoWebAExecutar][postKey] = await postJSON({
            acaoWebAExecutar: acaoWebAExecutar,
            sistema: sistema,
            projeto: projeto,
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado = cache[projeto][sistema][acaoAExecutar][acaoWebAExecutar][postKey];

    }

    return resultado;

}

export {
    cachedPostJSON
}
