import "antd/dist/antd.css";
import '../assets/css/inputMasked.scss';

import React, {useEffect, useRef, useState} from "react";
import {Input, notification} from "antd";
import {FakeEvent, isRedo, isUndo, keyPressPropName, setSelection} from "../lib/InputHelpers";
import InputMask from "../../framework/3rdpart/inputmask-core";
import {campoInformado, campoInformadoString, isAlphaNumeric} from "../lib/iy2b-javascript";
import {useStateRef} from "../lib/iy2b-react";
import delay from "../lib/delay";

// 0 - numero
// A - alfanumerico
// S,L,U - caracter
// placa    UUU-0000
// cep      00000-000
// telefone (00) 0000-0000
// cnpj     00.000.000\/0000-00
// cpf      000.000.000-00

// DE-PARA IY2B para
//    1 - number
//    a - letter
//    A - letter, forced to upper case when entered
//    * - alphanumeric
//    # - alphanumeric, forced to upper case when entered

const InputMasked = ( props ) => {

    const inputRef = useRef();

    const [masks, setMasks, refMasks] = useStateRef([]);
    const [previousValue, setPreviousValue] = useState(null);
    const [value, setValue] = useState(null);
    const [currentMask, setCurrentMask, currentMaskRef] = useStateRef(null);
    const [ultValorValido, setUltValorValido, refUltValorValido] = useStateRef(null);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        // console.log("useEffect inicializa MaskedInput");

        // console.log(inputRef, props.api);

        if(props.api) {
            props.api.current = {
                focus: inputRef.current.focus,
                blur: inputRef.current.blur,
                validate: validate,
                rawValue: rawValue,
                defaultValidator: defaultValidator
            };
        }

        setMasks ( props.patterns.map(item => {

            const _pattern = item.pattern
                .replaceAll("0", "1")
                .replaceAll("A", "*")
                .replaceAll("S", "a")
                .replaceAll("L", "a")
                .replaceAll("U", "A")
            ;

            const formatter = new InputMask({pattern: _pattern});

            return {
                formatter: formatter,
                rawLength: item.length,
                validator: item.validator
            };

        }));

        inputRef.current.input.classList[ 'add' ]('iy2b-ant-input-mask');

        return () => {

            console.log("final useEffect inicializa MaskedInput");

        };

    }, []);

    const updateFromValue = (value) => {

        if(value !== previousValue || (value === "" || value === null)) {

            const incomingValue = value||"";

            let maxLength = 0;
            let _current = null;

            masks.forEach(mask => {

                mask.formatter.setValue("");

                _updateMaskSelection(mask.formatter, {start: 0, end: 0});

                // por causa da mascara do telefone com '(' e ')' .... ???
                // mask.formatter.paste(incomingValue);

                mask.formatter.setValue(incomingValue);

                const rawValue = mask.formatter.getRawValue();

                // console.log(rawValue, maxLength);

                if (rawValue.length > maxLength) {
                    maxLength = rawValue.length;
                    _current = mask;
                }

            });

            setCurrentMask(_current);

            if(_current !== null) {
                setInputValue(_current.formatter.getValue());
                _updateInputSelection(_current);
                //console.log(_current, _current.formatter.getValue());
            } else {
                setInputValue(null);
                // if(campoInformadoString(incomingValue)) console.log("_current null para " + incomingValue);
            }

        }

    }

    useEffect(() => {

        updateFromValue(props.defaultValue);

    }, [props.defaultValue]);

    useEffect(() => {

        if(inputRef.current?.input.value !== props.value) {

            updateFromValue(props.value);

        }

    }, [props.value]);

    useEffect(() => {

        if(props.hasOwnProperty("defaultValue")) {

            updateFromValue(props.defaultValue);

        } else if(props.hasOwnProperty("value")) {

            updateFromValue(props.value);

        }

    }, [masks]);

    const defaultValidator = async (rule, value) => {
        if(props.api) {
            const result = await props.api.current.validate();
            if(result.valido === false) throw new Error(result.message);
        }
        return true;
    }

    const rawValue = () => {

        if(currentMaskRef === null) return "";
        if(currentMaskRef.current === null) return "";

        return currentMaskRef.current.formatter.getRawValue();

    }

    const validate = async () => {

        if(currentMaskRef === null) return {valido:true}; // nulo não é incompleto
        if(currentMaskRef.current === null) return {valido:true}; // nulo não é incompleto
        if(currentMaskRef.current.formatter.getRawValue().length === 0) return {valido:true}; // nulo não é incompleto

        let valorValido = (currentMaskRef.current.formatter.getRawValue().length === currentMaskRef.current.rawLength);
        let msgValido = "Campo formatado incompleto !";

        if(valorValido === true) {

            const currentRawValue = currentMaskRef.current.formatter.getRawValue();

            if(refUltValorValido?.current !== currentRawValue) {

                const resultValid = await currentMaskRef.current.validator(currentRawValue);

                if(resultValid.valido === false) {
                    valorValido = false;
                    msgValido = resultValid.message;

                    if(resultValid.clearValue === true) {
                        deleteKeyDown(new FakeEvent());
                        notification.error({message:"Valor inválido !", description: msgValido})
                    }

                } else {
                    setUltValorValido(currentRawValue);
                }

            }

        }

        setTimeout(() => {
            if(valorValido === true) {
                inputRef.current?.input?.classList[ 'add' ]('is-valid');
                inputRef.current?.input?.classList[ 'remove' ]('is-invalid');
            } else {
                inputRef.current?.input?.classList[ 'remove' ]('is-valid');
                inputRef.current?.input?.classList[ 'add' ]('is-invalid');
            }
        }, 100);

        const resultadoValidacao = {valido:valorValido, message:msgValido};

        return resultadoValidacao;

    }

    const _updateMaskSelection = (mask, selection) => {
        mask.setSelection(selection);
    }

    const _updateInputSelection = (mask) => {
        setSelection(inputRef.current.input, mask.formatter.selection);
    }

    const _getDisplayValue = (mask) => {
        let value = mask.getValue();
        return value === mask.emptyValue ? '' : value;
    }

    const setInputValue = (value) => {
        inputRef.current.input.value = value;
        setValue(value);
    };

    const dispatchOnChange = (e, mask) => {
        if (props.hasOwnProperty("onChange")) {
            const event = {...e};
            event.rawValue = mask.getRawValue();
            if(previousValue != event.rawValue) {
                setPreviousValue(event.rawValue);
                props.onChange(event.rawValue);
            }
        }
    }

    const onInputChange = (e) => {

        const incomingValue = e.target.value;

        masks.forEach(mask => {
            mask.formatter.setValue("");
            _updateMaskSelection(mask.formatter, {start: 0, end: 0});
            mask.formatter.paste(incomingValue);
        });

        const displayValue = _getDisplayValue(currentMask.formatter);

        setInputValue(displayValue);
        _updateInputSelection(currentMask);
        dispatchOnChange(e, currentMask.formatter);

    }

    const deleteKeyDown = (e) => {

        e.preventDefault();

        if(masks.length > 0) {
            masks.forEach(mask => {
                mask.formatter.setValue("");
                _updateMaskSelection(mask.formatter, {start: 0, end: 0});
            });
        } else {
            refMasks.current?.forEach(mask => {
                mask.formatter.setValue("");
                _updateMaskSelection(mask.formatter, {start: 0, end: 0});
            });
        }

        if(currentMask !== null) {
            setInputValue(_getDisplayValue(currentMask.formatter));
            _updateInputSelection(currentMask);
            dispatchOnChange(e, currentMask.formatter);
        } else {
            if(currentMaskRef.current !== null) {
                setInputValue(_getDisplayValue(currentMaskRef.current.formatter));
                _updateInputSelection(currentMaskRef.current);
                dispatchOnChange(e, currentMaskRef.current.formatter);
            }
        }

    }

    const onInputKeyDown = (e) => {

        setTimeout(() => {
            inputRef.current.input.classList[inputRef.current.input.value ? 'add' : 'remove']('has-value');
        }, 100);

        if (isUndo(e)) {
            e.preventDefault();
            masks.forEach(mask => {
                mask.formatter.undo();
            });
            setInputValue(_getDisplayValue(currentMask.formatter));
            _updateInputSelection(currentMask);
            dispatchOnChange(e, currentMask.formatter);
        } else if (isRedo(e)) {
            e.preventDefault();
            masks.forEach(mask => {
                mask.formatter.redo();
            });
            setInputValue(_getDisplayValue(currentMask.formatter));
            _updateInputSelection(currentMask);
            dispatchOnChange(e, currentMask.formatter);
        } else if (e.key === 'Backspace') {
            e.preventDefault();
            masks.forEach(mask => {
                mask.formatter.backspace();
            });
            let value = _getDisplayValue(currentMask.formatter);
            setInputValue(value);
            if (value) {
                _updateInputSelection(currentMask);
            }
            dispatchOnChange(e, currentMask.formatter);
        } else if (e.key === 'Delete') {
            deleteKeyDown(e);
        }

    }

    const onInputPaste = (e) => {

        e.preventDefault();

        let textData = "";

        const charData = e.clipboardData.getData('Text').split('');

        charData.forEach(key => {
            if(isAlphaNumeric(key)) {
                textData += key;
            }
        });

        let maxLength = 0;
        let _current = null;

        masks.forEach(mask => {

            mask.formatter.paste(textData);

            const rawValue = mask.formatter.getRawValue();

            if (rawValue.length > maxLength) {
                maxLength = rawValue.length;
                _current = mask;
            }

        });

        setCurrentMask(_current);

        if(_current != null) {

            // getData value needed for IE also works in FF & Chrome
            // @ts-ignore
            setInputValue(_current.formatter.getValue());
            // Timeout needed for IE
            setTimeout(() => _updateInputSelection(_current), 0);
            dispatchOnChange(e, _current.formatter);

        }

    }

    const onInputKeyPress = (e) => {

        if (e.metaKey || e.altKey || e.ctrlKey || e.key === 'Enter') {
            return;
        }

        e.preventDefault();

        const key = (e.key || e.data);

        let maxLength = 0;
        let _current = null;

        masks.forEach(mask => {

            mask.formatter.input(key);

            const rawValue = mask.formatter.getRawValue();

            if (rawValue.length > maxLength) {
                maxLength = rawValue.length;
                _current = mask;
            }

        });

        if(_current === null) _current = masks[0];

        setCurrentMask(_current);

        if(_current != null) {

            setInputValue(_current.formatter.getValue());
            _updateInputSelection(_current);
            dispatchOnChange(e, _current.formatter);

        }

    }

    const getInputProps = () => {

        let maxLength = 0;

        const inputProps = {
            onChange: onInputChange,
            onKeyDown: onInputKeyDown,
            onPaste: onInputPaste
        };

        inputProps[keyPressPropName()] = onInputKeyPress;

        inputProps.placeholder = "";

        masks.forEach(item => {

            if(inputProps.placeholder !== "") inputProps.placeholder += " ou ";

            inputProps.placeholder += item.formatter.emptyValue;

            if(item.formatter.pattern.length > maxLength) maxLength = item.formatter.pattern.length;

        });

        const { bordered, onPressEnter, disabled, className } = props;

        const resultProps = { maxLength: maxLength,
            bordered, onPressEnter, disabled, className,
            ...inputProps };

        return resultProps;

    }

    return (
        <Input type={"text"} ref={inputRef} {...getInputProps()} value={value} allowClear />
    );

};

export default InputMasked;
