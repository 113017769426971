import React from "react";
import PageContent from "../framework/componentes/PageContent";
import {lazyRetry} from "../framework/lib/iy2b-react";

// React.lazy
// https://reactjs.org/docs/code-splitting.html
const MovimEstq = React.lazy(() => lazyRetry(() => import('../transacoes/MovimEstq/movimEstq')));
const Produto = React.lazy(() => lazyRetry(() => import('../transacoes/Produto/produto')));
const CategoriaProduto = React.lazy(() => lazyRetry(() => import('../transacoes/CategoriaProduto/categoriaProduto')));
const ProgramacaoPagto = React.lazy(() => lazyRetry(() => import('../transacoes/ProgramacaoPagtos/programacaoPagto')));
const TeleProcessamentoCP = React.lazy(() => lazyRetry(() => import('../transacoes/TeleProcessamento/CP/teleProcessamentoCP')));
const TeleProcessamentoCR = React.lazy(() => lazyRetry(() => import('../transacoes/TeleProcessamento/CR/teleProcessamentoCR')));
const DrillDownCR = React.lazy(() => lazyRetry(() => import('../transacoes/DrillDown/CR/drillDownCR')));
const DrillDownCP = React.lazy(() => lazyRetry(() => import('../transacoes/DrillDown/CP/drillDownCP')));
const CResumoFin = React.lazy(() => lazyRetry(() => import('../transacoes/CResumoFin/CResumoFin')));
const GrupoClassifT = React.lazy(() => lazyRetry(() => import('../transacoes/GrupoClassifT/grupoClassifT')));
const GrupoTipoRec = React.lazy(() => lazyRetry(() => import('../transacoes/GrupoTipoRec/grupoTipoRec')));
const Cliente = React.lazy(() => lazyRetry(() => import('../transacoes/Cliente/cliente')));
const GrupoTipoDesp = React.lazy(() => lazyRetry(() => import('../transacoes/GrupoTipoDesp/grupoTipoDesp')));
const ConsultaFluxoCaixa = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaFluxoCaixa/consultaFluxoCaixa')));
const ContratoVenda = React.lazy(() => lazyRetry(() => import('../transacoes/ContratoVenda/contratoVenda')));
const TipoContratoVenda = React.lazy(() => lazyRetry(() => import('../transacoes/TipoContratoVenda/tipoContratoVenda')));
const CustosExtras = React.lazy(() => lazyRetry(() => import('../transacoes/CustosExtras/custosExtras')));
const ConsultaDRE = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaDRE/consultaDRE')));
const FormaRecebimento = React.lazy(() => lazyRetry(() => import('../transacoes/FormaRecebimento/formaRecebimento')));
const PedidoVenda = React.lazy(() => lazyRetry(() => import('../transacoes/PedidoVenda/pedidoVenda')));
const CondicaoPagto = React.lazy(() => lazyRetry(() => import('../transacoes/CondicaoPagto/condicaoPagto')));
const ServicoPrestado = React.lazy(() => lazyRetry(() => import('../transacoes/ServicoPrestado/servicoPrestado')));
const TipoReceita = React.lazy(() => lazyRetry(() => import('../transacoes/TipoReceita/tipoReceita')));
const TipoServico = React.lazy(() => lazyRetry(() => import('../transacoes/TipoServico/tipoServico')));
const CItemSP01 = React.lazy(() => lazyRetry(() => import('../transacoes/CItemSP01/cItemSP01')));
const Dashboard02 = React.lazy(() => lazyRetry(() => import('../transacoes/Dashboard02/dashboard02')));
const ClassifT = React.lazy(() => lazyRetry(() => import('../transacoes/ClassifT/classifT')));
const Credor = React.lazy(() => lazyRetry(() => import('../transacoes/Credor/credor')));
const ManutencaoSP = React.lazy(() => lazyRetry(() => import('../transacoes/ManutencaoSP/msp')));
const CartaoCredito = React.lazy(() => lazyRetry(() => import('../transacoes/CartaoCredito/cartaoCredito')));
const ProjetoCM = React.lazy(() => lazyRetry(() => import('../transacoes/ProjetoCM/projetoCM')));
const AreaCC = React.lazy(() => lazyRetry(() => import('../transacoes/AreaCC/areaCC')));
const TipoDespesa = React.lazy(() => lazyRetry(() => import('../transacoes/TipoDespesa/tipoDespesa')));
const ConciliacaoBancaria = React.lazy(() => lazyRetry(() => import('../transacoes/ConciliacaoBancaria/conciliacaoBancaria')));
const ContaBancaria = React.lazy(() => lazyRetry(() => import('../transacoes/ContaBancaria/contaBancaria')));
const GeracaoSPRecorrente = React.lazy(() => lazyRetry(() => import('../transacoes/GeracaoSPRecorrente/geracaoSPRecorrente')));
const ModeloSP = React.lazy(() => lazyRetry(() => import('../transacoes/ModeloSP/ModeloSP')));
const Dashboard01 = React.lazy(() => lazyRetry(() => import('../transacoes/Dashboard01')));
const ConsultaNotas = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaNotas')));
const ReguaCobEmp = React.lazy(() => lazyRetry(() => import('../transacoes/ReguaCobEmp/reguaCobEmp')));
const FaturaCartCredCP = React.lazy(() => lazyRetry(() => import('../transacoes/FaturaCartCredCP/faturaCartCredCP')));
const TipoOrdemServico = React.lazy(() => lazyRetry(() => import('../transacoes/TipoOrdemServico/tipoOrdemServico')));
const TarefaOrdemServico = React.lazy(() => lazyRetry(() => import('../transacoes/TarefaOrdemServico/tarefaOrdemServico')));
const NovaOrdemServico = React.lazy(() => lazyRetry(() => import('../transacoes/NovaOrdemServico/novaOrdemServico')));
const Almoxarifado = React.lazy(() => lazyRetry(() => import('../transacoes/Almoxarifado/almoxarifado')));
const Atendimento = React.lazy(() => lazyRetry(() => import('../transacoes/Atendimento/atendimento')));
const PautaTrabalho = React.lazy(() => lazyRetry(() => import('../transacoes/PautaTrabalho/pautaTrabalho')));
const OrcamentoFin = React.lazy(() => lazyRetry(() => import('../transacoes/OrcamentoFin/orcamentoFin')));
const ConsultaOrcamento = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaOrcamento/consultaOrcamento')));
const EnviarFaturamento = React.lazy(() => lazyRetry(() => import('../transacoes/EnviarFaturamento/enviarFaturamento')));
const ControleFechamentos = React.lazy(() => lazyRetry(() => import('../transacoes/ControleFechamentos/controleFechamentos')));
const ExtratoBancario = React.lazy(() => lazyRetry(() => import('../transacoes/ExtratoBancario/extratoBancario')));
const MovimentacaoIndividualCR = React.lazy(() => lazyRetry(() => import('../transacoes/MovIndivTitCR/movimentacaoIndividualCR')));
const SerieTipoVendaEmp = React.lazy(() => lazyRetry(() => import('../transacoes/SerieTipoVendaEmp/serieTipoVendaEmp')));
const VendaExpressaServico = React.lazy(() => lazyRetry(() => import('../transacoes/VendaExpressaServico/vendaExpressaServico')));

const listaTransacoes = [{
        cdTransacao: 85001,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ConsultaNotas
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85002,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><Dashboard01
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85007,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ModeloSP
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85009,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><GeracaoSPRecorrente
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85010,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ContaBancaria
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85011,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ConciliacaoBancaria
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85012,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><TipoDespesa
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85013,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><AreaCC
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85014,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ProjetoCM
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85015,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><CartaoCredito
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85016,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ManutencaoSP
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85017,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><Credor
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85018,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ClassifT
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85019,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><Dashboard02
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85020,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><CItemSP01
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85021,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><TipoReceita
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85022,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><TipoServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85023,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ServicoPrestado
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85024,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><CondicaoPagto
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85031,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><PedidoVenda
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
}, {
    cdTransacao: 85032,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><FormaRecebimento
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85033,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><ConsultaDRE
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85034,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><CustosExtras
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85035,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><TipoContratoVenda
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85036,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><ContratoVenda
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85037,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><ConsultaFluxoCaixa
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85038,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><GrupoTipoDesp
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85039,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><Cliente
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85040,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><GrupoTipoRec
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85041,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><GrupoClassifT
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85042,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><CResumoFin
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85043,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><DrillDownCP
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85044,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><DrillDownCR
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85045,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><TeleProcessamentoCR
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85046,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><TeleProcessamentoCP
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85047,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><ProgramacaoPagto
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85048,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><CategoriaProduto
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85049,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><Produto
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85050,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><PedidoVenda
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85052,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                    <MovimEstq
                        cdTransacao={cdTransacao}
                        paramWeb={paramWeb}
                    />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85054,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ReguaCobEmp
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85055,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <FaturaCartCredCP
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85057,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <TipoOrdemServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85058,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <TarefaOrdemServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85060,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <NovaOrdemServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85066,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <Almoxarifado
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85068,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <Atendimento
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85069,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <PautaTrabalho
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85070,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <OrcamentoFin
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85071,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ConsultaOrcamento
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85072,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <EnviarFaturamento
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85073,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ControleFechamentos
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85074,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ExtratoBancario
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85075,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <MovimentacaoIndividualCR
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85076,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <SerieTipoVendaEmp
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85078,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <VendaExpressaServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}];


const transacaoToPage = {
    get: (cdTransacao, paramWeb) => {
        const paraRodar = listaTransacoes.filter(item => item.cdTransacao === cdTransacao);
        if(paraRodar.length === 1) {
            return paraRodar[0].page(cdTransacao, paramWeb);
        } else {
            return null;

        }
    }
};


export default transacaoToPage;
