import "antd/dist/antd.css";
import "./style.scss";

import {Button, Popconfirm} from "antd";
import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fontawesome from "@fortawesome/fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";

fontawesome.library.add( faFacebookSquare );

const iconProvedor = <FontAwesomeIcon icon={faFacebookSquare} />;

const BotaoFacebook = (props) => {

    const { onClickVincular, onClickReVincular, onClickDesvincular, block=true, cdProvedorIdentidade, jaVinculado, labelLogin=false, disabled=false } = props;

    const onClick = async () => {

        if(jaVinculado) {

            await onClickReVincular({cdProvedorIdentidade});

        } else {

            await onClickVincular({cdProvedorIdentidade});

        }

        return true;
    }

    const title = (disabled===true)?"Desabilitado":(labelLogin===true)?"Facebook":(jaVinculado===true)?"Facebook vinculado":"Vincular ao Facebook";

    const btnDesvinc = (disabled===false && jaVinculado===true)?
        <Popconfirm
            title="Confirma desvincular o acesso via Facebook？"
            okText="Sim, desvincular !"
            cancelText="Não"
            onConfirm={() => { onClickDesvincular({cdProvedorIdentidade})} }
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
            <Button
                type={"danger"} icon={<DeleteOutlined />}
            />
        </Popconfirm>
        : null;

    const boxClassName = [];

    if(btnDesvinc != null) boxClassName.push("tem-desvinc");

    return (
        <div className={boxClassName.toClassName()}>
            <Button className={"facebook"} type={"default"} onClick={onClick} icon={iconProvedor} block={block} disabled={disabled}>&nbsp;{title}</Button>
            {btnDesvinc}
        </div>
    );

}

export default BotaoFacebook;
