import "antd/dist/antd.css";
import '@ant-design/pro-form/dist/form.css' ;
import '../../../assets/css/tema.scss';
import "./style.scss";

import {ProFormRadio} from "@ant-design/pro-form";
import React from "react";

const RadioGroup = ( props ) => {

    const { width="md", name, options, label="", tooltip="", rules=[], onChange=null, forceUpdate=null, disabled=false } = props;

    const _OnChange = (evt) => {

        if(onChange !== null) onChange(evt);

        if(forceUpdate !== null) forceUpdate();

    };

    const contentClassName = ["pro-radio-group"];

    contentClassName.push("pro-field-" + width);

    return (

        <ProFormRadio.Group
            width = {width}
            name = {name}
            label = {label}
            tooltip = { tooltip }
            rules = {rules}
            options = {options}
            onChange = {(evt) => _OnChange(evt)}
            fieldProps = {{
                className: contentClassName.toClassName(),
                disabled: disabled
            }}
        />

    );

}

export default RadioGroup ;
