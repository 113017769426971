import '@ant-design/pro-form/dist/form.css' ;
import "../assets/css/alteraSenhaPopUp.scss";

import ProForm , {
    DrawerForm ,
    ProFormText ,
    ProFormCheckbox
} from '@ant-design/pro-form' ;

import {useEffect, useRef, useState} from "react";
import {inputPreventPaste, notificacaoErroValidForm} from "../lib/iy2b-react";
import {isDigit, isLowerLetter, isUpperLetter} from "../lib/iy2b-javascript";
import {notification} from "antd";

const isCharEspecial = (c) => {
    if(c=='!') return true;
    if(c=='@') return true;
    if(c=='#') return true;
    if(c=='$') return true;
    if(c=='%') return true;
    if(c=='&') return true;
    if(c=='*') return true;
    if(c=='(') return true;
    if(c==')') return true;
    return false;
}

const AlteraSenhaPopUp = props => {

    const formRef = useRef() ;
    const inputRefNovaSenha = useRef();

    useEffect(() => {

        setTimeout(() => {

            inputRefNovaSenha.current?.focus();

        }, 250);

        return () => {

        };

    }, []);

    const onFinish = async values => {

        if(values.confirmaNovaSenha === values.novaSenha)  {

            const resultado = {
                novaSenha: values.novaSenha
            };

            if(props.exigeSenhaAtual === true) {
                resultado.senhaAtual = values.senhaAtual;
            }

            props.onTrocaSenha(resultado);

        } else {

            notification.error({message:"Atenção !", description: "Nova senha e a confirmação não são iguais !"})

        }

    };

    const onFinishFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const onValuesChange = ( changeValues, allValues ) => {

        const novaListaReqs = [];

        if(allValues.hasOwnProperty("novaSenha")) {

            if(allValues.novaSenha.length >= 6 && allValues.novaSenha.length <= 9) novaListaReqs.push("len");

            const chars = allValues.novaSenha.split('');

            if(chars.filter( c=> isDigit(c) ).length > 0) novaListaReqs.push("number");
            if(chars.filter( c=> isUpperLetter(c) ).length > 0) novaListaReqs.push("upper");
            if(chars.filter( c=> isLowerLetter(c) ).length > 0) novaListaReqs.push("lower");
            if(chars.filter( c=> isCharEspecial(c) ).length > 0) novaListaReqs.push("especial");

        }

        if(allValues.hasOwnProperty("confirmaNovaSenha")) {

            if(allValues.confirmaNovaSenha === allValues.novaSenha) novaListaReqs.push("confirmacao");

        }

        formRef.current.setFieldsValue({requisitos:novaListaReqs});

    }

    const listaRequisitos = [
        {label:'Entre 6 e 9 caracteres', value:"len"},
        {label:'1 Letra MAISÚCULA', value:"upper"},
        {label:'1 Letra minúscula', value:"lower"},
        {label:'1 Número', value:"number"},
        {label:'1 Caracter especial dos seguintes: ! @ # $ % & * ( )',value:"especial"},
        {label:"Confirmação verificada com sucesso", value:"confirmacao"}
    ];

    const inputSenhaAtual = (props.exigeSenhaAtual === true) ?
            <ProForm.Group>
                <ProFormText.Password
                    width={"md"}
                    name = "senhaAtual"
                    label = "Sua senha atual"
                    tooltip = "Digite a sua senha atual"
                    placeholder = "Digite a sua senha atual"
                    rules = { [ { required : true , message : 'Necessário preencher a sua senha atual'} ] }
                />
            </ProForm.Group>
        : null;

    return (
        <DrawerForm open={props.exibe} visible={props.exibe}
            title = {"Alteração de senha"}
            formRef = { formRef }
            drawerProps = {{
                forceRender : true,
                destroyOnClose : true,
                width: 450,
                onClose: (e) => props.onCancel()
            }}
            onFinish = {onFinish}
            onFinishFailed={onFinishFailed}
            submitter = { {
                // Configure button text
                searchConfig : {
                    resetText : 'Cancelar' ,
                    submitText : 'Trocar a senha' ,
                } ,
                // Configure the properties of the button
                resetButtonProps : { } ,
                submitButtonProps : { },
                onReset: (e) => props.onCancel()
            }}
            onValuesChange = {onValuesChange}
        >
            {inputSenhaAtual}
            <ProForm.Group>
                <ProFormText.Password
                    width={"md"}
                    name = "novaSenha"
                    label = "Nova senha"
                    tooltip = "Senha com 8 caracteres, com os requisitos necessários"
                    placeholder = "Digite a sua nova senha"
                    maxLength={8}
                    rules = { [ { required : true , message : 'Necessário preencher a nova senha', min:6, max:9 } ] }
                    fieldProps={{
                        ref: inputRefNovaSenha
                    }}
                />
            </ProForm.Group>
            <ProForm.Group>
                <ProFormText.Password
                    width={"md"}
                    name = "confirmaNovaSenha"
                    label = "Confirmação da nova senha"
                    tooltip = "Digite a mesma senha"
                    placeholder = "Digite novamente a sua nova senha"
                    maxLength={8}
                    rules = { [ { required : true , message : 'Necessário confirmar a nova senha', min:6, max:9 } ] }
                    fieldProps = {{
                        onPaste: inputPreventPaste
                    }}
                />
            </ProForm.Group>
            <div id={"groupRequisitos"}>
                <ProForm.Group>
                    <ProFormCheckbox.Group
                        width={"lg"}
                        name = "requisitos"
                        label = " Requisitos da nova senha "
                        tooltip = "Requisitos necessários para senha ser aceita"
                        layout = "vertical"
                        options = {listaRequisitos}
                        disabled
                        rules = { [ { required : true , message : 'Revisar requisitos da senha', len: 6, type:"array" } ] }
                    />
                </ProForm.Group>
            </div>
        </DrawerForm>
    );

}

export default  AlteraSenhaPopUp;
