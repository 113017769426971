import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/listaRequisitos.scss';
import ProForm, {ProFormCheckbox} from "@ant-design/pro-form";
import {useEffect} from "react";

const ListaRequisitos = props => {

    useEffect(() => {

        if(props.formRef) {
            if(props.formRef.current) {
                const newValue = { };
                newValue[props.formFieldName] = [];
                (newValue[props.formFieldName]).addAll(props.checkeds);
                props.formRef.current.setFieldsValue(newValue);
            }
        }

    }, [props.checkeds]);

    const rules = [ { required : true , message : 'Revisar a lista de requisitos obrigatórios', len: props.dataSource.length, type:"array" } ];

    return (
        <div className={"iy2b-lista-requisitos"}>
            <ProForm.Group>
                <ProFormCheckbox.Group
                    width={"lg"}
                    name = {props.formFieldName}
                    label = {props.label}
                    tooltip = {props.tooltip}
                    layout = "vertical"
                    options = {props.dataSource}
                    disabled
                    rules = { rules }
                />
            </ProForm.Group>
        </div>
    );

}

export default ListaRequisitos;
