import "antd/dist/antd.css";
import '@ant-design/pro-layout/dist/layout.css';
import '@ant-design/pro-form/dist/form.css';
import '@ant-design/pro-card/dist/card.css';

import '../../framework/assets/css/tema.scss';
import "../../framework/assets/css/formEndereco.scss";

import React, {useEffect, useRef, useState} from 'react';
import ProForm, {ProFormText} from "@ant-design/pro-form";
import {Col, Row} from "antd";
import {pesquisaCEP} from "../lib/iy2b-javascript";
import IY2BProFormMasked from "./IY2BProFormMasked";
import IY2BProFormHidden from "./IY2BProFormHidden";
import IY2BHiddenRow from "./IY2BHiddenRow";


const FormEndereco = (props) => {

    const inputRefNrCEP = useRef();
    const inputRefTxNumero = useRef();
    const { exibeInscricoes = false, exibeMeiosContato = false } = props;
    const [infoCep, setInfoCep] = useState(null);
    const inputRefTelefone = useRef();

    const [protegeTela, setProtegeTela] = useState(false);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        if(props.api) {
            props.api.current = {
                onValuesChange: onValuesChange,
                setFocus: (field) => {
                    if(field === "CEP") {
                        setTimeout(
                            () => {
                                inputRefNrCEP.current.focus();
                            },
                            250
                        );
                    }
                },
                limpaCEP: () => {
                    props.formRef.current.setFieldsValue({
                        nrCEP: null
                    });
                    limpaCEP();
                },
                preencherCEP: async (nrCEP) => {

                    props.formRef.current.setFieldsValue({
                        nrCEP: nrCEP
                    });

                    return await preencherCEP(nrCEP);
                },
                setFieldsValue: (values) => {
                    props.formRef.current.setFieldsValue(values);
                },
                setProtegeTela: (value) => {
                    setProtegeTela(value);
                }
            };
        }

        return () => {

            console.log("final useEffect inicializa FormEndereco");

        };

    }, []);

    const onValuesChange = (changed, all) => {

        // console.log(changed, all);

        if(changed.hasOwnProperty("nrCEP")) {

            if(inputRefNrCEP.current.rawValue().length !== 8) {
                limpaCEP();
            }

        }

    }

    const limpaCEP = () => {

        setInfoCep(null);

        const newValue = {
            noLogradouro: "",
            noCidade: "",
            noBairro: "",
            txNumero: "",
            txComplEnd: ""
        };

        props.formRef.current.setFieldsValue(newValue);

    }

    const preencherCEP = async (nrCEP) => {

        try {

            const resultado = await pesquisaCEP(nrCEP);

            setInfoCep(resultado);

            const newValue = {
                noLogradouro: resultado.logradouro,
                noCidade: resultado.localidade + " / " + resultado.uf,
                noBairro: resultado.bairro
            };

            props.formRef.current.setFieldsValue(newValue);

            inputRefTxNumero.current.focus();

            return true;

        } catch (e) {

            limpaCEP();

            return false;

        }

    }

    const cepPatterns = [{
        pattern: "00000-000",
        length: 8, // sem mascara
        validator: async (value) => {
            const resultado = await preencherCEP(value);
            if(resultado === true) {
                return {valido:true};
            } else {
                return {valido:false, message:"CEP informado não encontrado nos correios!", clearValue: true};
            }
        }
    }];

    const telFixoPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }]

    const camposMeiosContato = (exibeMeiosContato===true)?
        <>
            <Col xs={{span:20}} md={{span:8}} lg={{span:5}}>
                <IY2BProFormMasked
                    width={"sm"}
                    label={"Telefone principal"}
                    name="nrTelefone"
                    tooltip = "Nro. telefone principal da empresa"
                    rules={[{
                        validator: async (rule, value) => {
                            if(inputRefTelefone) {
                                const result = await inputRefTelefone.current.validate();
                                if(result.valido === false) throw new Error(result.message);
                            }
                            return true;
                        }
                    }]}
                    api={inputRefTelefone}
                    patterns={telFixoPatterns}
                    disabled={protegeTela}
                />
            </Col>
            <Col xs={{span:20}} md={{span:16}} lg={{span:9}} >
                <ProFormText
                    width={"lg"}
                    name = "noEmailNF_E"
                    label = "E-Mail recebimento NFe"
                    tooltip = "Infome o email para recebimento da NFe nesta empresa"
                    placeholder={"Digite o email para recebimento da NFe nesta empresa"}
                    rules={[
                        {message:"Informe um email válido", type:"email"},
                        {message:"No máximo 60 caracteres", max:60}
                    ]}
                    disabled={protegeTela}
                />
            </Col>
        </> : null;

    const camposInscricoes = (exibeInscricoes===true)?
        <>
            <Col xs={{span:20}} md={{span:8}} lg={{span:5}}>
                <ProFormText
                    width={"sm"}
                    name = "nrInsEst"
                    label = "Inscrição estadual"
                    tooltip = "Inscrição estadual da empresa"
                    placeholder={"Digite a inscrição estadual da empresa "}
                    rules={[ {message:"No máximo 14 caracteres", max:14}]}
                    disabled={protegeTela}
                />
            </Col>
            <Col xs={{span:20}} md={{span:8}} lg={{span:5}}>
                <ProFormText
                    width={"sm"}
                    name = "nrInsMunicip"
                    label = "Inscrição municipal"
                    tooltip = "Inscrição municipal da empresa. Necessário para pesquisar notas de serviço"
                    placeholder={"Digite a inscrição municipal da empresa "}
                    rules={[ {message:"No máximo 15 caracteres", max:15}]}
                    disabled={protegeTela}
                />
            </Col>
        </> : null;

    return (
        <div className={"form-endereco"}>
            <Row gutter={16} className={"header"}>
                <Col span={24}>
                    <label>Endereço</label>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={{span:10}} md={{span:4}}>
                    <ProForm.Group>
                        <IY2BProFormMasked
                            width={"sm"}
                            label={"CEP"}
                            name="nrCEP"
                            tooltip = "CEP do endereço"
                            rules={[{ required: true, message: 'Por favor, informe o CEP!' },{
                                validator: async (rule, value) => {
                                    if(inputRefNrCEP) {
                                        const result = await inputRefNrCEP.current.validate();
                                        if(result.valido === false) throw new Error(result.message);
                                    }
                                }
                            }]}
                            api={inputRefNrCEP}
                            patterns={cepPatterns}
                            disabled={protegeTela}
                        />
                    </ProForm.Group>
                </Col>
                <Col xs={{span:18}} md={{span:20}}>
                    <ProForm.Group>
                        <ProFormText
                            width={"lg"}
                            name = "noLogradouro"
                            label = "Logradouro"
                            tooltip = "Nome do logradouro"
                            disabled
                        />
                        <ProFormText
                            width={"sm"}
                            name = "txNumero"
                            label = "Número"
                            tooltip = "Número do imóvel"
                            placeholder={"Digite o número do imóvel"}
                            rules={[ {required: true, message:"Informe o número do imóvel"},
                                {message:"No máximo 20 caracteres", max:20}
                            ]}
                            fieldProps={{
                                ref: inputRefTxNumero
                            }}
                            disabled={protegeTela}
                        />
                    </ProForm.Group>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <ProForm.Group>
                        <ProFormText
                            width={"sm"}
                            name = "txComplEnd"
                            label = "Complemento"
                            tooltip = "Existe complemento no endereço ?"
                            placeholder={"Andar, conjunto, sala, apto, etc... "}
                            rules={[ {message:"No máximo 30 caracteres", max:30}]}
                            disabled={protegeTela}
                        />
                        <ProFormText
                            width={"md"}
                            name = "noBairro"
                            label = "Bairro"
                            tooltip = "Nome do bairro"
                            disabled
                        />
                        <ProFormText
                            width={"md"}
                            name = "noCidade"
                            label = "Cidade / UF"
                            tooltip = "Nome da cidade / UF"
                            disabled
                        />
                    </ProForm.Group>
                </Col>
            </Row>
            {(exibeInscricoes===true || exibeMeiosContato===true) ? (
                    <Row gutter={16}>
                        {camposInscricoes}
                        {camposMeiosContato}
                    </Row>
                ) : null
            }
            <IY2BHiddenRow>
                <IY2BProFormHidden name={"nrSeqEnd"}/>
                <IY2BProFormHidden name={"cdCidade"}/>
                <IY2BProFormHidden name={"noContato"}/>
                <IY2BProFormHidden name={"dtCad"}/>
                <IY2BProFormHidden name={"tpEndereco"}/>
                <IY2BProFormHidden name={"cdRegiao"}/>
                <IY2BProFormHidden name={"cdTipoCalcImposto"}/>
                <IY2BProFormHidden name={"dtInicValid"}/>
                <IY2BProFormHidden name={"noEmail"}/>
            </IY2BHiddenRow>
        </div>
    );

}

export default FormEndereco;
