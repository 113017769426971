import {postJSON, postWS} from "./RequestAPI";
import {notificacaoErroRequest} from "./iy2b-react";

export async function pesquisarCidadeDoCep (nrCEP, loadingArea) {

    try {

        let cidade = null ;

        const payload = {
            acaoAExecutar: "pesquisaCidadeDoCEP",
            "nrCEP": nrCEP
        };

        const resposta = await postJSON({
            sistema: "sgr",
            projeto: "cpesqiy2b",
            acaoWebAExecutar: "pesquisar"
        }, payload, loadingArea);

        resposta.mensagens.forEach(msg => {

            if(msg.id === "cidade") {

                cidade = msg.modeloPadrao;

            } else {

                console.log(msg);

            }

        });

        return cidade;

    } catch (e) {

        notificacaoErroRequest(e);

        return null;

    }

}

export async function pesquisarEnderecoDaPessoa (cdPessoa, loadingArea) {

    try {

        const enderecos = [];

        const payload = {
            acaoAExecutar: "pesquisa",
            filtro: {
                "hidCdPessoa": cdPessoa
            }
        };

        const resposta = await postJSON({
            sistema: "sgr",
            projeto: "mendereco",
            acaoWebAExecutar: "pesquisar"
        }, payload, loadingArea);

        resposta.mensagens.forEach(msg => {

            if(msg.id === "listaEndereco") {

                enderecos.addAll(msg.lista);

            } else {

                console.log(msg);

            }

        });

        return enderecos;

    } catch (e) {

        notificacaoErroRequest(e);

        return [];

    }

}


export async function pesquisarPlanoContrVenda (loadingArea) {

    try {

        const planos = [];

        const resposta = await postWS({
            sistema: "svd",
            projeto: "mplanocontrvda",
            acaoWebAExecutar: "pesquisar",
            acaoAExecutar: "pesqPlanoContrVenda"
        }, {}, loadingArea);

        resposta.mensagens.forEach(msg => {

            if(msg.id === "listaPlanoContratoVenda") {

                const FATOR_ANUAL = 1; // 1 ano

                planos.addAll(msg.lista.map(item => {

                    const fatorPagto = 12 / item.nrIntervaloCobr;

                    const plano = {
                        id: item.cdPlanoContrVenda,
                        label: item.noPlanoContrVenda.trim(),
                        descrPlano: item.descrPlanoContrVenda.trim(),
                        noPlano: item.noPlanoContrVenda.trim(),
                        txUrlHomeProduct: item.txUrlHomeProduct,
                        descrItens: item.descrItens,
                        selecionado: false,
                        cdPessoaEmpPCtrV: item.cdPessoaEmp,
                        cdPlanoContrVenda: item.cdPlanoContrVenda,
                        flgTipoPessoa: item.flgTipoPessoa
                    };

                    if(item.flgGratis === "N") {

                        plano.label += " - ";

                        const valorPreco = new Number(item.vlrPreco);

                        const preco = valorPreco.asCurrency();

                        const valorTotal = new Number(item.vlrPreco*fatorPagto);

                        plano.total = valorTotal.asCurrency();

                        const precoBase = new Number(item.vlrPrecoBase);

                        if(precoBase > 0) {
                            if(precoBase > valorPreco) {
                                plano.precoBase = precoBase.asCurrency();
                            } else {
                                plano.precoBase = null;
                            }
                        } else {
                            plano.precoBase = null;
                        }

                        plano.formaPagto = preco ;

                        plano.periodicidade = " /mês";

                        plano.label += " " + preco ;

                    } else {

                        plano.formaPagto = " GRATUITO ";

                        plano.precoBase = null;

                        plano.periodicidade = "";

                        plano.total = " ZERO ";

                    }

                    return plano;

                }));

            } else {

                console.log(msg);

            }

        });

        return planos;

    } catch (e) {

        notificacaoErroRequest(e);

        return [];

    }

}

export async function pesquisarProvedorIdentidade ({ws=false}, loadingArea) {

    try {

        const provedores = [];

        let  resposta = null;

        if(ws === true) {

            resposta = await postWS({
                sistema: "smk",
                projeto: "musuarioprovedorid",
                acaoWebAExecutar: "pesquisar",
                acaoAExecutar: "pesquisaProvedorId"
            }, {
            }, loadingArea);

        } else {

            resposta = await postJSON({
                sistema: "smk",
                projeto: "musuarioprovedorid",
                acaoWebAExecutar: "pesquisar",
            }, {
                acaoAExecutar: "pesquisaProvedorId"
            }, loadingArea);

        }

        resposta.mensagens.forEach(msg => {

            if (msg.id === "listaProvedorIdentidade") {

                provedores.addAll(msg.lista);

            } else {

                console.log(msg);

            }

        });

        return provedores;

    } catch (e) {

        notificacaoErroRequest(e);

        return [];
    }

}

export async function vincularProvedorIdentidadeUsuario ({cdPessoaUsr, chUId, cdProvedorIdentidade, noEmail}, loadingArea) {

    try {

        const registro = {
            cdPessoaUsr, cdProvedorIdentidade, chUId, noEmail
        }

        const resposta = await postJSON({
            sistema: "smk",
            projeto: "musuarioprovedorid",
            acaoWebAExecutar: "incluir",
        }, {
            acaoAExecutar: "acaoCRUD",
            registro: registro
        }, loadingArea);

        resposta.mensagens.forEach(msg => {

            if (msg.id === "modelo") {

                console.log(msg.modeloPadrao);

            } else {

                console.log(msg);

            }

        });

        return true;

    } catch (e) {

        notificacaoErroRequest(e);

        return false;

    }

}

export async function desvincularProvedorIdentidadeUsuario ({cdPessoaUsr, chUId}, loadingArea) {

    try {

        const registro = {
            cdPessoaUsr, chUId
        }

        const resposta = await postJSON({
            sistema: "smk",
            projeto: "musuarioprovedorid",
            acaoWebAExecutar: "excluir",
        }, {
            acaoAExecutar: "acaoCRUD",
            lista: [ registro ]
        }, loadingArea);

        resposta.mensagens.forEach(msg => {

            if (msg.id === "modelo") {

                console.log(msg.modeloPadrao);

            } else {

                console.log(msg);

            }

        });

        return true;

    } catch (e) {

        notificacaoErroRequest(e);

        return false;

    }

}

export async function pesquisarProvedorIdentidadeUsuario ({cdPessoaUsr, chUId, cdProvedorIdentidade, ws=false}, loadingArea) {

    try {

        const filtro = {
            txtCdPessoaUsr: cdPessoaUsr,
            txtChUId: chUId,
            txtCdProvedorIdentidade: cdProvedorIdentidade
        }

        const provedores = [];

        let resposta = null;

        if(ws === true) {

            resposta = await postWS({
                sistema: "smk",
                projeto: "musuarioprovedorid",
                acaoWebAExecutar: "pesquisar",
                acaoAExecutar: "pesquisa",
            }, {
                filtro: filtro
            }, loadingArea);

        } else {

            resposta = await postJSON({
                sistema: "smk",
                projeto: "musuarioprovedorid",
                acaoWebAExecutar: "pesquisar",
            }, {
                acaoAExecutar: "pesquisa",
                filtro: filtro
            }, loadingArea);

        }

        resposta.mensagens.forEach(msg => {

            if (msg.id === "listaUsuarioProvedorId") {

                provedores.addAll(msg.lista);

            } else {

                console.log(msg);

            }

        });

        return provedores;

    } catch (e) {

        notificacaoErroRequest(e);

        return [];

    }

}

export async function pesquisarCertificadoDigital (cdPessoa, loadingArea) {

    try {

        const certificados = [];

        const resposta = await postJSON({
            sistema: "sgr",
            projeto: "mpessoacertifdigital",
            acaoWebAExecutar: "pesquisar",
        }, {
            acaoAExecutar: "pesquisa",
            filtro: {
                txtCdPessoa: cdPessoa
            },
            orderBySql: "PESSOACERTIFDIGITAL.DTFIMVALID DESC"
        }, loadingArea);

        resposta.mensagens.forEach(msg => {

            if (msg.id === "listaPessoaCertifDigital") {

                certificados.addAll(msg.lista);

            } else {

                console.log(msg);

            }

        });

        return certificados;

    } catch (e) {

        notificacaoErroRequest(e);

        return [];

    }

}


