import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/IY2BModal.scss';

import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Modal } from 'antd';

const IY2BModal = props => {

    const [modalHeight, setModalHeight] = useState(0);
    const [modalWidth, setModalWidth] = useState(0);
    const [bodyHeight, setBodyHeight] = useState(0);


    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("useEffect inicializa IY2BModal " + props.id);

        return () => {

            console.log("final useEffect inicializa IY2BModal " + props.id);

        };

    }, []);

    useEffect(() => {

        const elsMask = document.getElementsByClassName("ant-modal-mask");

        if(elsMask.length > 0) {

            const elMask = elsMask[0];

            const height = elMask.clientHeight;

            const auxHeight = (props.peHeight||100) * (height - (props.top || 50)) / 100;

            setModalHeight (auxHeight);

            const width = elMask.clientWidth;

            const auxWidth = (props.peWidth||100) * width  / 100;

            setModalWidth (auxWidth);

        }

        return () => {

        };

    }, [props.visible]);

    useLayoutEffect(() => {

        if(props.visible === false) return;

        let querySelContent = "." + props.className + " .ant-modal-content";

        if(props.id) {
            querySelContent = "." + props.id + " ." + props.className + " .ant-modal-content";
        }

        const elContent = document.querySelector(querySelContent);

        if(elContent) {

            const elHeader = elContent.querySelector(".ant-modal-header");
            const elFooter = elContent.querySelector(".ant-modal-footer");

            let auxHeight = elContent.clientHeight;

            if(elHeader) {
                auxHeight -= elHeader.clientHeight;
            }
            if(elFooter) {
                auxHeight -= elFooter.clientHeight;
            }

            auxHeight -= 0;

            if(auxHeight > 0) {
                if(auxHeight != bodyHeight) {

                    const elBody = elContent.querySelector(".ant-modal-body");

                    setBodyHeight(auxHeight);

                    elBody.setAttribute("style","height:" + auxHeight + "px");

                }
            }

        }

        return () => {

        };

    });

    const modalStyle = {
        height: modalHeight,
        top: (props.top || 50)
    };

    const newProps = {...props};

    delete newProps.visible;

    newProps.open = props.visible;

    return (
        <Modal {...newProps}
            style={modalStyle}
            width={modalWidth}
            wrapClassName={props.id}
        >
                {React.cloneElement(props.children, { insideModal: true, availableHeight: bodyHeight })}
        </Modal>
    );

};

export default IY2BModal;

