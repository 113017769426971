import "antd/dist/antd.css";
import '@ant-design/pro-layout/dist/layout.css' ;
import '@ant-design/pro-form/dist/form.css' ;

import {Col, Row} from "antd";
import React from "react";

const IY2BHiddenRow = ( props ) => {

    return (
        <Row gutter={0} style={{display:"none"}}>
            <Col span={1}>
                {props.children}
            </Col>
        </Row>
    );

}

export default IY2BHiddenRow;
