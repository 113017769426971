import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/usuarioDropdown.scss';

import React, { useState, useEffect } from 'react';
import {Avatar, Tooltip, Carousel} from 'antd';
import ReactModal from 'react-modal';
import {useWindowSize} from "../lib/iy2b-react";
import {CaretDownOutlined} from "@ant-design/icons";

import fontawesome from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignOutAlt, faFileSignature, faCloud, faUser} from '@fortawesome/free-solid-svg-icons';
import {faAddressCard, faQuestionCircle, faBuilding} from '@fortawesome/free-regular-svg-icons';
import Iframe from "react-iframe";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

fontawesome.library.add(faSignOutAlt, faFileSignature, faAddressCard, faCloud, faQuestionCircle, faBuilding, faUser);

const iconSignOutAlt = <FontAwesomeIcon icon={faSignOutAlt} />;
const iconFileSignature = <FontAwesomeIcon icon={faFileSignature} />;
const iconCloud = <FontAwesomeIcon icon={faCloud} />;
const iconUser = <FontAwesomeIcon icon={faUser} />;

const iconAddressCard = <FontAwesomeIcon icon={faAddressCard} />;
const iconBuilding = <FontAwesomeIcon icon={faBuilding} />;
const iconQuestionCircle = <FontAwesomeIcon icon={faQuestionCircle} />;


const UsuarioDropdown = props => {

    const breaks = useBreakpoint();

    const windowSize = useWindowSize();

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [exibeDropdown, setExibeDropDown] = useState(false);

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                exibeDropdown: () => {
                    setExibeDropDown(true);
                    setTooltipVisible(false);
                },
                escondeDropdown: () => {
                    setExibeDropDown(false);
                    setTooltipVisible(false);
                }
            };
        }

        return () => {

        };

    }, []);

    const exibeMenuUsr = () => {
        setExibeDropDown(! exibeDropdown);
        setTooltipVisible(false);
    };

    const handleClose = () => {
        setExibeDropDown(false);
        setTooltipVisible(false);
    }

    const handleLogout = () => {
        handleClose();
        props.onLogout();
    };

    const handleMeuPerfil = () => {
        handleClose();
        props.onMeuPerfil();
    }

    const handleMeuPlano = () => {
        handleClose();
        props.onMeuPlano();
    }

    const handleMinhasEmpresas = () => {
        handleClose();
        props.onMinhasEmpresas();
    }

    const handleMeusUsuarios = () => {
        handleClose();
        props.onMeusUsuarios();
    }

    const classNameStatusDropdown = exibeDropdown ? "open" : "close";

    const { flgGratuito } = props.userSession?.planoContratado;

    const usrMaster = props.userSession?.master;

    const existeEmpCNPJ = props.suite.listaEmp.filter(empresa => empresa.pessoaEmp.flgJuridico==="J").length > 0;

    const scrollerWidth = (breaks.xs===true)?350:430;

    const { acessosEspeciais } = props;

    const adSpace = (flgGratuito==="N") ? null :
        <div id={"adSpace"}>
            <Carousel autoplay>
                {props.suite.listaPeca.map(item => {
                    return (
                        <div key={item.cdPeca}
                             className={"peca"}
                        >
                            <div>
                                <Iframe url={item.url}
                                        width={(scrollerWidth-2) + "px"}
                                        height="238px"
                                        id={"iframe-" + item.cdPeca}
                                />
                            </div>
                        </div>
                    )
                })}
            </Carousel>
        </div>
        ;

    const botaoMeuPlano = (usrMaster==="S")?
        <div className={"boxBotao"}>
            <Tooltip placement="top" title={"Consultar e alterar as informações do seu plano de assinatura da plataforma"} overlayStyle={{zIndex: 3000}} color={"black"}>
                <div className={"botao"} onClick={handleMeuPlano}>
                    <span className={"icone"}>{iconFileSignature}</span>
                    <span className={"label"}>Meu plano</span>
                </div>
            </Tooltip>
        </div>
        : null
    ;

    const botaoMinhasEmpresas = ((usrMaster==="S" || acessosEspeciais.usuarios === true) && existeEmpCNPJ === true)?
        <div className={"boxBotao"}>
            <Tooltip placement="left" title={"Empresas"} overlayStyle={{zIndex: 3000}} color={"black"}>
                <div className={"botao"} onClick={handleMinhasEmpresas}>
                    <span className={"icone"}>{iconBuilding}</span>
                    <span className={"label"}>Empresas</span>
                </div>
            </Tooltip>
        </div>
        :null
    ;

    const botaoUsuarios = (usrMaster==="S" || acessosEspeciais.usuarios === true) ?
        <div className={"boxBotao"}>
            <Tooltip placement="bottom" title={"Usuários"} overlayStyle={{zIndex: 3000}} color={"black"}>
                <div className={"botao"} onClick={handleMeusUsuarios}>
                    <span className={"icone"}>{iconUser}</span>
                    <span className={"label"}>Usuários</span>
                </div>
            </Tooltip>
        </div>
        :null
    ;

    return (
        <Tooltip placement="bottom" title={"Minha conta"} open={tooltipVisible}>
            <div id={"usuarioDropdown"} className={[classNameStatusDropdown]} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                <a onClick={exibeMenuUsr}>
                    <Avatar size={46} id={"usrAvatar"}
                            shape={"circle"}
                            src={props.userSession.urlFoto}
                    >
                    </Avatar>
                    <CaretDownOutlined id={"caretAvatar"} />
                </a>
                <ReactModal
                    isOpen={exibeDropdown}
                    className={"modalContent"}
                    overlayClassName={"modalOverlay"}
                    bodyOpenClassName={"usuarioDropdownModal"}
                    closeTimeoutMS={500}
                    onRequestClose={handleClose}
                    style={{
                        content: {
                            left: (windowSize.width - scrollerWidth) + 'px',
                        }
                    }}
                >
                    <>
                        <div className={"boxLabel"}>
                            <div id={"noPessoaUsr"}>
                                {props.userSession.noPessoa}
                            </div>
                        </div>
                        <div className={"scroller"} style={{width:scrollerWidth}}>
                            <div className={"container"}>
                                <div id={"botoes"}>
                                    <div className={"boxBotao"}>
                                        <Tooltip placement="left" title={"Editar suas informações pessoais"} overlayStyle={{zIndex: 3000}} color={"black"}>
                                            <div className={"botao"} onClick={handleMeuPerfil}>
                                                <span className={"icone"}>{iconAddressCard}</span>
                                                <span className={"label"}>Meu perfil</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    {botaoMeuPlano}
                                    {botaoMinhasEmpresas}
                                    {botaoUsuarios}
                                    <div className={"boxBotao"}>
                                        <Tooltip placement="left" title={"Arquivos gerados pelo sistema disponíveis para download"} overlayStyle={{zIndex: 3000}} color={"black"}>
                                            <div className={"botao"}>
                                                <span className={"icone"}>{iconCloud}</span>
                                                <span className={"label"}>Arquivos</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className={"boxBotao"}>
                                        <Tooltip placement="bottom" title={"Abrir e consultar atendimentos de suporte"} overlayStyle={{zIndex: 3000}} color={"black"}>
                                            <div className={"botao"}>
                                                <span className={"icone"}>{iconQuestionCircle}</span>
                                                <span className={"label"}>Suporte</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className={"boxBotao"}>
                                        <Tooltip placement="bottom" title={"Fechar o sistema e voltar ao login"} overlayStyle={{zIndex: 3000}} color={"black"}>
                                            <div id={"btnEncerrar"} className={"botao"} onClick={handleLogout}>
                                                <span className={"icone"}>{iconSignOutAlt}</span>
                                                <span className={"label"}>Encerrar a sessão</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {adSpace} */}
                    </>
                </ReactModal>
            </div>
        </Tooltip>
    );
};

export default UsuarioDropdown;

