import "antd/dist/antd.css";
import '@ant-design/pro-layout/dist/layout.css' ;
import '@ant-design/pro-form/dist/form.css' ;
import './style.scss';

import ProForm from "@ant-design/pro-form";
import {Input} from "antd";
import {useEffect, useState} from "react";
import {useStateRef} from "../../lib/iy2b-react";
import {campoInformado} from "../../lib/iy2b-javascript";

const IY2BProFormHidden = ( props ) => {

    const [myData, setMyData, refMyData] = useStateRef(null);

    const { formRef, name } = props ;

    const { dataIndex = name, data } = props ;

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                getData: () => refMyData.current,
                setData: (value) => {
                    setMyData(value);
                    if(campoInformado(dataIndex)) {
                        const newValue = { };
                        newValue[name] = (campoInformado(value)) ? value[dataIndex] : null;
                        formRef.current.setFieldsValue(newValue);
                    }
                }
            }
        };

        return () => {

        };

    }, []);

    useEffect(() => {

        setMyData(data);

        return () => {

        };

    }, [data]);


    return (
        <ProForm.Item
                     width={"xs"}
                     name = {name}
                     disabled
        >
            <Input className={"iy2b-pro-form-hidden"} />
        </ProForm.Item>
    )

}

export default IY2BProFormHidden;
