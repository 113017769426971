import {StepsForm} from "@ant-design/pro-form";
import React, {useState} from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {CheckOutlined} from "@ant-design/icons";
import {topFunction} from "../lib/iy2b-javascript";

const IY2BProFormWizard = (props) => {

    const { xs } = useBreakpoint();
    const [currentStep, setCurrentStep] = useState(0);

    const onCurrentChange = (position) => {

        topFunction();

        setCurrentStep(position);

        if(props.hasOwnProperty("onCurrentStepChange")) {

            const step = props.steps.props.children[position];

            props.onCurrentStepChange(position, step.key);

        }

    };

    const stepsRender = () => {

        const layoutVertical = (xs===true) ;

        const passos = [];

        if(Array.isArray(props.steps.props.children)===true) {
            passos.addAll(props.steps.props.children);
        } else {
            passos.push(props.steps.props.children);
        }

        const rcSteps = passos.map((step, i) => {

            const active = (i===currentStep);

            const classesContainer = ["ant-steps-item"];

            if(active) {
                classesContainer.push("ant-steps-item-process");
                classesContainer.push("ant-steps-item-active");
            } else {
                if(i<currentStep) {
                    classesContainer.push("ant-steps-item-finish");
                } else {
                    classesContainer.push("ant-steps-item-wait");
                }
            }

            const icon = (i<currentStep) ? <CheckOutlined /> : <span>{(i+1)}</span>;

            return (
                <div key={step.key} className={classesContainer.toClassName()}>
                    <div className="ant-steps-item-container">
                        <div className="ant-steps-item-tail"></div>
                        <div className="ant-steps-item-icon">
                            <span className="ant-steps-icon">
                                {icon}
                            </span>
                        </div>
                        <div className="ant-steps-item-content">
                            <div className="ant-steps-item-title">{step.props.title}</div>
                            <div className="ant-steps-item-description">{step.props.stepProps.description}</div>
                        </div>
                    </div>
                </div>
            );

        });

        const classesLayout = ["ant-steps", "ant-steps-small"];

        if(layoutVertical) {
            classesLayout.push("ant-steps-vertical");
        } else {
            classesLayout.push("ant-steps-horizontal");
            classesLayout.push("ant-steps-label-horizontal");
        }

        return (
            <div className="iy2b-steps-header ant-pro-steps-form-steps-container" style={{maxWidth: 1160}}>
                <div className={classesLayout.toClassName()}>
                    {rcSteps}
                </div>
            </div>
        );

    };

    return (
        <StepsForm
            formRef = { props.formRef }
            onFinish = { props.onFinishWizard }
            onCurrentChange = { onCurrentChange }
            stepsProps={{
                size: props.stepsSize,
            }}
            stepsRender={stepsRender}
        >
            {props.steps}
        </StepsForm>
    );
};

export default IY2BProFormWizard;
