import {Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import React from "react";
import {campoInformado} from "../../lib/iy2b-javascript";
import "./style.scss";

const TooltipOnIcon = (props) => {

    const { title = "tooltip", color = "black", placement = "top", icon = (<QuestionCircleOutlined />), label, marginTop } = props;

    const classNameBox = ["tooltip-on-icon-box"];

    if(marginTop===true) classNameBox.push("margin-top");

    const labelElement = campoInformado(label) ?
        <label>{label}&nbsp;</label>
        : null
    ;

    return (
        <div className={classNameBox.toClassName()}>
            {labelElement}
            <Tooltip title={title} color={color} className={"tooltip-on-icon"} placement = {placement}>
                {icon}
            </Tooltip>
        </div>
    );

}

export default TooltipOnIcon;
