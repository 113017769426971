import {notificacaoErroRequest} from "./iy2b-react";

export default class Promessas {

    constructor(args) {
        this.promises = [];
        this.done = false;
    }

    debug = () => {
        this.promises.forEach(item => console.log(item));
    }

    add = (executor) => {
        this.promises.push(new Promise( executor ) );
    }

    addAll = (executors) => {
        executors.forEach(executor => this.promises.push(new Promise( executor )));
    }

    run = async () => {

        const resultados = await Promise.all(this.promises);

        this.done = true;

        return resultados;

    }

    addToQueue = (block) => {
        this.promises.push( block );
    }

    queueRun = async () => {

        const resultados = [];

        let i = 0;

        const runPromise = async (promessa) => {

            try {

                i++;

                const resultado = await promessa;

                resultados.push(resultado)

            } catch (e) {

                console.log(e);

                notificacaoErroRequest(e);

                resultados.push(e)

            }

            return true;

        }

        let rodando = this.promises.length > 0;

        while (rodando === true) {

            const jsBlock = this.promises.shift();

            await runPromise(new Promise( jsBlock ));

            rodando = this.promises.length > 0;

        }

        this.done = true;

        return resultados;

    }

    isEmpty = () => this.promises.isEmpty();

    completed = () => this.done;

}


/*

            promessas.add(async (resolve, reject) => {
                try {
                    const resultado = await postJSON({
                        acaoWebAExecutar: "pesquisar",
                        sistema: "smt",
                        projeto: "mmatserv"
                    }, {
                        "acaoAExecutar": "inicializaProjeto",
                    }, loadingArea);

                    resultado.mensagens.forEach(msg => {
                    })
                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e);

                    reject(e);
                }
            });


 */
