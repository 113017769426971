import '../assets/css/tema.scss';
import '../assets/css/uploadLoadingIndicator.scss';
import "antd/dist/antd.css";

import {usePromiseTracker} from "react-promise-tracker";
import React from "react";

import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';

import fontawesome from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useWindowSize} from "../lib/iy2b-react";
import {skinPrimaryColor} from "../assets/css/skins/StyleSkin";

fontawesome.library.add( faSyncAlt );

const iconUpload = <FontAwesomeIcon icon={faSyncAlt} color={skinPrimaryColor} size="4x" pulse/>;

const UploadLoadingIndicator = props => {

    const windowSize = useWindowSize();

    const { promiseInProgress } = usePromiseTracker({area: props.area});

    return (
        promiseInProgress &&
        <div id="backdropLoadingUpload" >
            <div
                style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: ((windowSize.height/2)-80) + "px",
                    right: ((windowSize.width/2)-((windowSize.width/2)/2)) + "px",
                    padding: "10px"
                }}
            >
                <div className={"box-spinner"}>
                    {iconUpload}
                    <label>
                        Por favor aguarde, estamos recebendo o seu arquivo !
                    </label>
                </div>
            </div>
        </div>

    );

};

export default UploadLoadingIndicator;
