import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import './style.scss';

import React, {useRef, useEffect, useState} from 'react';
import {notificacaoErroValidForm, useGridWidth, useStateRef, useWindowSize} from "../../lib/iy2b-react";
import ProForm, {ProFormText} from '@ant-design/pro-form' ;
import {Button, Menu, notification, Space} from "antd";
import IY2BReactModal from "../IY2BReactModal";
import {getProjetosUtilizados} from "../../lib/RequestAPI";
import axios from 'axios';
import qs from 'qs'
import xml2js from 'xml2js';
import {ProTable} from "@ant-design/pro-table";
import {campoInformado} from "../../lib/iy2b-javascript";

const VerDebug = ( props ) => {

    const {exibePopUp, onRequestClose} = props;
    const [items, setItems] = useState([]);

    const windowSize = useWindowSize();
    const [gridWidth] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.compare(window._SM_)<=0) ? 10 : 15;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = 0;

    const modalRefApi = useRef();
    const tableRef = useRef();

    const [tableHeight, setTableHeight] = useState(400);

    const [, setFilePath, filePath] = useStateRef(null);

    const [, setDebugData, refDebugData] = useStateRef([]);
    const [, setCamadas, refCamadas] = useStateRef({});
    const [, setOrigens, refOrigens] = useStateRef({});
    const [, setNiveis, refNiveis] = useStateRef({});
    const [, setNomesClasse, refNomesClasse] = useStateRef({});

    useEffect(() => {

        if(exibePopUp === true) {
            setItems(getProjetosUtilizados());
        }
        return () => {

        };

    }, [exibePopUp]);

    const pesquisar = async () => {

        if(filePath?.current === null || filePath?.current === undefined) return;

        // filePath = /svd/desenv/debug/mpedido/silvania.sestini@lasses.com.br.txt

        setTableHeight(windowSize.height - 315);

        const agora = (new Date()).getTime();

        const body = qs.stringify({
            arqDebug: filePath.current
        });

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        // http://......?debug=/sli/desenv/debug/mimovel/smk.txt&tm=1658951914674

        const host = window.location.protocol + "//" + ((process.env.NODE_ENV === 'development')?window.location.host:process.env.REACT_APP_HOST_BACKEND);

        const url = host + "/systemakers/logs/smkDebugRenderer?Milisegs=" + agora;

        try {

            const resposta = await axios.post(url, body, config);

            return new Promise((resolve, reject) => {

                const lines = resposta.data.split("\n");

                const parser = new xml2js.Parser({cdata: true, trim: true, strict: false, normalizeTags: true,
                    valueProcessors: [
                        (value, name) => {
                            let newValue = value;
                            if (value.indexOf('<![CDATA[') !== -1) {
                                newValue = value.replace(/\<\!\[CDATA\[([\s|\S]*)\]\]\>/i, "$1");
                            }
                            return newValue;
                        },
                    ]
                });

                try {

                    parser.parseString(resposta.data, (err, result) => {

                        if(result != null) {

                            const opcoesCamada = {  };
                            const opcoesNivel = {  };
                            const opcoesOrigem = {  };
                            const opcoesNomeClasse = {  };

                            const debugData = [];

                            if(campoInformado(result.smkdebug?.logdebug) === true) {

                                debugData.addAll( result.smkdebug.logdebug.map((item, id) => {

                                    const resultado = {
                                        key: id
                                    }

                                    if(item.hasOwnProperty("camada")) {
                                        resultado.camada = item.camada[0];
                                        opcoesCamada[resultado.camada] = {text: resultado.camada};
                                    }
                                    if(item.hasOwnProperty("nivel")) {
                                        resultado.nivel = item.nivel[0];
                                        opcoesNivel[resultado.nivel] = {text: resultado.nivel};
                                    }
                                    if(item.hasOwnProperty("origem")) {
                                        resultado.origem = item.origem[0];
                                        opcoesOrigem[resultado.origem] = {text: resultado.origem};
                                    }

                                    if(item.hasOwnProperty("label")) {
                                        resultado.label = item.label[0];
                                    }
                                    if(item.hasOwnProperty("nomeclasse")) {
                                        resultado.nomeClasse = item.nomeclasse[0];
                                        opcoesNomeClasse[resultado.nomeClasse] = {text: resultado.nomeClasse};
                                    }

                                    if(item.hasOwnProperty("horario")) resultado.horario = item.horario[0];
                                    if(item.hasOwnProperty("mensagem")) resultado.mensagem = item.mensagem[0];

                                    return resultado;

                                }) );

                            }

                            setDebugData(debugData);

                            setCamadas(opcoesCamada);

                            setNiveis(opcoesNivel);

                            setOrigens(opcoesOrigem);

                            setNomesClasse(opcoesNomeClasse);

                            resolve( {
                                data: debugData.clone(),
                                success: true,
                            });

                        } else {

                            console.log(err);

                            // console.log(lines);

                            resolve( {
                                data: [],
                                success: false,
                            });
                        }

                    });

                } catch (e) {

                    console.log(e);

                }

            });

        } catch (e) {

            console.log(e);

        }

        //

    }

    const menuOnClick = async (evt) => {

        const projeto = evt.keyPath[0];
        const sistema = evt.keyPath[1];

        // filePath = /svd/desenv/debug/mpedido/silvania.sestini@lasses.com.br.txt

        const filePath = "/" + sistema + "/" + process.env.REACT_APP_CONFIG + "/debug/" + projeto + "/" + props.noLogin + ".txt";

        setFilePath(filePath);

        setDebugData([]);

        // Refresh and clear, the page number will also be reset, excluding the form
        tableRef.current.reloadAndRest();
    }

    const applyLikeFilter = (filter, data, property) => {

        let newData = data.clone();

        if(filter.hasOwnProperty(property) === true) {
            const like = filter[property].toLowerCase();
            newData = newData.filter(item => item[property].toLowerCase().indexOf(like) > -1);
        }

        return newData;

    }

    const applyEqFilter = (filter, data, property) => {

        let newData = data.clone();

        if(filter.hasOwnProperty(property) === true) {
            newData = newData.filter(item => item[property] === filter[property]);
        }

        return newData;

    }

    const filtrar = (params, sort, filter) => {

        return new Promise((resolve, reject) => {

            let newData = refDebugData.current.clone();

            newData = applyEqFilter(params, newData, "camada");

            newData = applyEqFilter(params, newData, "nivel");

            newData = applyEqFilter(params, newData, "origem");

            newData = applyEqFilter(params, newData, "nomeClasse");

            newData = applyLikeFilter(params, newData, "horario");

            newData = applyLikeFilter(params, newData, "label");

            newData = applyLikeFilter(params, newData, "mensagem");

            resolve( {
                data: newData,
                success: true,
            });

        });

    }

    const tabCols = [{
        dataIndex: "horario",
        title: "Data/Hora",
        width: 160,
    }, {
        dataIndex: "camada",
        title: "Camada",
        width: 150,
        valueType: 'select',
        valueEnum: refCamadas.current
    }, {
        dataIndex: "nivel",
        title: "Nivel",
        width: 120,
        valueType: 'select',
        valueEnum: refNiveis.current
    }, {
        dataIndex: "origem",
        title: "Origem",
        width: 120,
        valueType: 'select',
        valueEnum: refOrigens.current
    }, {
        dataIndex: "nomeClasse",
        title: "Classe",
        width: 310,
        valueType: 'select',
        valueEnum: refNomesClasse.current
    }, {
        dataIndex: "label",
        title: "Rótulo",
        width: 150,
    }, {
        dataIndex: "mensagem",
        title: "Mensagem",
        width: 500,
    }];

    const totalWidth = tabCols.reduce((total, item) => total + item.width , 0);

    const requestTableData = async (params, sort, filter) => {

        if(refDebugData.current?.length === 0) {

            return await pesquisar();

        } else {

            return await filtrar(params, sort, filter);

        }

    }


    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={MODAL_CONTENT_TOP}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"popup-ver-debug"}
            overlayClassName={"backdrop-popup-ver-debug"}
            title={"Visualizar debug"}
            api={modalRefApi}
            body={
                <div className={"body-popup-ver-debug"}>
                    <Menu
                        onClick={menuOnClick}
                        style={{
                            width: 256,
                        }}
                        mode="horizontal"
                        items={items}
                    />
                    <ProTable actionRef={tableRef}
                        className={"table-debug-viewer"}
                        headerTitle={filePath.current}
                        cardBordered
                        bordered={true}
                        size="small"
                        columns={tabCols}
                        scroll={{ y: tableHeight, x: totalWidth }}
                        request={requestTableData}
                        search={{
                            filterType: 'light',
                            labelWidth: 'auto',
                        }}
                        pagination={{
                            defaultPageSize: 100,
                            hideOnSinglePage: false,
                            showSizeChanger: true,
                            pageSizeOptions:[100, 200, 300],
                        }}
                    ></ProTable>
                </div>
            }
        />
    );

};

export default VerDebug;
