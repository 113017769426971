import '../assets/css/tema.scss';
import '../assets/css/containerContent.scss';
import "antd/dist/antd.css";

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Layout, Tabs} from 'antd';
import {close, setActive} from '../reducer/transacaoManager';
import {QuestionCircleOutlined} from "@ant-design/icons";
import {campoInformado} from "../lib/iy2b-javascript";
import {useWindowSize} from "../lib/iy2b-react";
import Animate from "rc-animate";

const { Content } = Layout;

export const ContainerContentContext = React.createContext(null);

const ContainerContent = props => {

    const { checkList } = props ;

    const windowSize = useWindowSize();

    const panes = props.transacaoManager.emExecucao;

    const activeKey = props.transacaoManager.current + "";

    const onChange = activeKey => {

        props.setActive(activeKey);

    };

    const onEdit = (targetKey, action) => {

        if(action == "remove") {

            props.close(targetKey);

        }

    };

    const contextValue = {
        isCurrentContent: (cdTransacao) => {
            return (parseInt(activeKey) === cdTransacao)
        },
        habilitaWizard: (cdTransacao, opcoes, exibeMyWizard) => {
            props.onHabilitaWizard(cdTransacao, opcoes, exibeMyWizard);
        },
        desabilitaWizard: (cdTransacao) => {
            props.onDesabilitaWizard(cdTransacao);
        }
    };

    const tabItens = panes.map(pane => {
        return {
            key: pane.key + "",
            closable: pane.closable,
            label:
                <span>
                    {campoInformado(pane.noHelp) ?
                        <QuestionCircleOutlined onClick={evt => props.onExibeAjuda(evt, pane)}/> : null}
                    {pane.abaTitle || pane.title}
                </span>,
            children: pane.content
        }
    });

    return (
        <Content id={"containerContent"}>
            <Animate component=""
                     transitionName="slideWSContent"
                     showProp={"data-slide"}
                     exclusive
            >
                <div data-slide={campoInformado(checkList)}>
                    <ContainerContentContext.Provider value={contextValue}>
                        <Tabs
                            type="editable-card"
                            activeKey={activeKey}
                            onChange={onChange}
                            onEdit={onEdit}
                            hideAdd={true}
                            items={tabItens}
                        >
                        </Tabs>
                    </ContainerContentContext.Provider>
                </div>
            </Animate>
        </Content>
    );

};

// {panes.isEmpty()===true?<AguardandoTransacao />:null}

const mapStateToProps = function( {appConfig, userSession, transacaoManager} ) {

    return {
        appConfig: appConfig,
        userSession: userSession,
        transacaoManager: transacaoManager
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setActive: (key) => {
            return dispatch( setActive(key) );
        },
        close: (key) => {
            return dispatch( close(key) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerContent);
