import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/empresaDropdown.scss';

import React, { useState, useEffect } from 'react';
import {Avatar, Image, List, Tooltip} from "antd";
import {CaretDownOutlined} from "@ant-design/icons";
import ReactModal from 'react-modal';
import {offSetFromDocument} from "../lib/iy2b-javascript";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fontawesome from "@fortawesome/fontawesome";

fontawesome.library.add( faBuilding );

const iconBuilding = <FontAwesomeIcon icon={faBuilding} size={"2x"} color={"white"}/>;

const EmpresaDropdown = props => {

    const breaks = useBreakpoint();

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [exibeDropdown, setExibeDropDown] = useState(false);
    const [dropDownOffSet, setDropDownOffSet] = useState({top:0, left:0, width: 0});

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        const offset = offSetFromDocument(document.getElementById('empresaDropdown'));

        offset.width = document.getElementById('btnEmpresa').getBoundingClientRect().width;

        setDropDownOffSet (offset);

        return () => {

        };

    }, []);


    const onClickEmpresa = (value) => {
        props.onSelect(value);
        setExibeDropDown(false);
    }

    const classNameStatusDropdown = exibeDropdown ? "open" : "close";

    const noEmpresaAtual = props.empresaAtual != null ? props.empresaAtual.noOficial : "";
    const cdPessoaEmpAtual = props.empresaAtual != null ? props.empresaAtual.pessoaEmp.cdPessoa : 0;
    const logoBD = props.empresaAtual != null && props.empresaAtual?.logoBD != null? "data:image/*;base64," + props.empresaAtual.logoBD : null;

    const currentDropDownOffSet = { ...dropDownOffSet };

    currentDropDownOffSet.left = (breaks.xs===true)?10:dropDownOffSet.left;

    const scrollerWidth = (breaks.xs===true)?350:440;

    const conteudoBotaoEmpresa = (breaks.xs===true)?
        iconBuilding
        :
        <div>
            {noEmpresaAtual}
        </div>
    ;

    const logoSize = (breaks.xs===true)?38:46;

    const avatarLogo = (logoBD === null) ? null :
        <Avatar
            size={logoSize}
            shape={"circle"}
            src={logoBD}
        >
        </Avatar>
    ;

    return (
        <Tooltip placement="bottom" title={"Selecione a empresa atual"} open={tooltipVisible}>
            <div id={"empresaDropdown"} className={[classNameStatusDropdown]} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                <div className={"containerBtnEmpresa"}>
                    <div id={"btnEmpresa"}>
                        <a  onClick={() => {
                            if(props.dataSource.length<=1 && (breaks.xs===false)) return;
                            setExibeDropDown(!exibeDropdown);
                            setTooltipVisible(false);
                        }}>
                            {avatarLogo}
                            &nbsp;
                            {conteudoBotaoEmpresa}
                            {(props.dataSource.length>1)?<CaretDownOutlined />:null}
                        </a>
                    </div>
                </div>
                <ReactModal
                    isOpen={exibeDropdown}
                    className={"modalContent"}
                    overlayClassName={"modalOverlay"}
                    bodyOpenClassName={"empresaDropdownModal"}
                    closeTimeoutMS={500}
                    shouldFocusAfterRender={true}
                    onRequestClose={() => setExibeDropDown(false)}
                    style={{
                        content: {
                            left: (currentDropDownOffSet.left + 1) + 'px',
                        }
                    }}
                >
                    <>
                        <div className={"header"}>Empresas disponíveis</div>
                        <div className={"scroller"} style={{width:scrollerWidth}}>
                            <List
                                dataSource={props.dataSource}
                                renderItem={item => {
                                    const itemClassName = (item.pessoaEmp.cdPessoa === cdPessoaEmpAtual) ? "active": "";
                                    const empLogo = (item.logoBD != null) ?  <Image preview={false} width={50} src={`data:image/jpeg;base64,${item.logoBD}`} /> : null;
                                    return (
                                        <List.Item key={item.pessoaEmp.cdPessoa} className={itemClassName}>
                                            <a onClick={() => onClickEmpresa(item)} className={"opcao"}>
                                                {empLogo}
                                                {item.noOficial}
                                            </a>
                                        </List.Item>
                                    )
                                }}
                            >
                            </List>
                        </div>
                    </>
                </ReactModal>
            </div>
        </Tooltip>
    );

};

export default EmpresaDropdown;

