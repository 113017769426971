import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import '@ant-design/pro-form/dist/form.css' ;
import '@ant-design/pro-layout/dist/layout.css' ;
import "./wizNovoContrato.scss";

import ReactModal from "react-modal";
import {Button, notification, Space} from "antd";
import React, {useRef, useState, useEffect} from "react";
import {notificacaoErroRequest, notificacaoErroValidForm, useWindowSize} from "../../lib/iy2b-react";
import {campoInformado, scrollToTop} from "../../lib/iy2b-javascript";
import {StepsForm} from "@ant-design/pro-form";
import StepCertDigital from "../../../pages/Contratar/StepCertDig";
import StepCadEmpresa from "../../../pages/Contratar/StepCadEmpresa";
import SinglePageLoadingIndicator from "../../componentes/SinglePageLoadingIndicator";
import UploadLoadingIndicator from "../../componentes/UploadLoadingIndicator";
import IY2BProFormWizard from "../../componentes/IY2BProFormWizard";
import StepPlanos from "../../../pages/Contratar/StepPlanos";
import {postWS} from "../../lib/RequestAPI";
import {onAfterClose, onAfterOpen} from "../../componentes/IY2BReactModal";

const WizNovoContrato = ( props ) => {

    const windowSize = useWindowSize();

    const MARGIN_LEFT = 30;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = (windowSize.height-((windowSize.height<640)?572:672))/2;

    const { exibePopUp, onRequestClose, dsPlanoCtrVenda, cdPessoaEmpMaster, usuarioAtual, onContratoAdicionado } = props;

    const [infoCertificado, setInfoCertificado] = useState(null);

    const [planoSelecionado, setPlanoSelecionado] = useState(null);

    const [dsPlanos, setDsPlanos] = useState([]);

    const [cadEmpData, setCadEmpData] = useState(null);

    const formRef = useRef();

    useEffect(() => {

        setDsPlanos(dsPlanoCtrVenda.clone());

        return () => {

        };

    }, [props.dsPlanoCtrVenda]);

    const closePopUp = () => {
        onRequestClose();
    };

    const onFinishStepFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const onFinishWizard = async () => {

        scrollToTop("containerStepCadEmpresa");

        console.log(planoSelecionado);

        const infoEmpresa = cadEmpData != null ? cadEmpData : formRefEmpresa.current?.getFieldsValue();

        const payload = {
            acaoAExecutar: "criarNovoContrato",
            "origin": window.location.origin,
            infos: {
                plano: {
                    cdPessoaEmp: props.appConfig.cdPessoaEmpWsToken,
                    cdPlanoContrVenda: planoSelecionado.id
                },
                certificado: {
                    nrSeqCD: infoCertificado.nrSeqCD,
                    flgJuridico: infoCertificado.flgJuridico,
                    noPessoa: infoCertificado.noPessoa,
                    nrCGCCIC: infoCertificado.nrCGCCIC,
                    cdPessoaEmpModeloOrig: props.appConfig.cdPessoaEmpModeloOrig
                },
                usuario: {
                    cdPessoaUsr: usuarioAtual.cdPessoaUsr,
                    noEMail: usuarioAtual.noEMail,
                    noUsuario: usuarioAtual.noUsuario,
                    nrTelCelular: usuarioAtual.nrTelCelular,
                }
            }
        }

        if(infoCertificado.flgJuridico === "J") {
            payload.infos.empresa = {
                noFantasia: infoEmpresa.noFantasia,
                flgOptaSimples: cadEmpData.flgOptaSimples,
                flgMEI: cadEmpData.flgMEI,
                endereco: {
                    nrCEP: infoEmpresa.nrCEP,
                    txNumero: infoEmpresa.txNumero,
                    txComplEnd: infoEmpresa.txComplEnd,
                    nrInsEst: infoEmpresa.nrInsEst,
                    nrInsMunicip: infoEmpresa.nrInsMunicip,
                }
            };
        } else {
            let cadUsrData = { };
            payload.infos.empresa = {
                noFantasia: infoCertificado.noPessoa,
                endereco: {
                    nrCEP: cadUsrData.nrCEP,
                    txNumero: cadUsrData.txNumero,
                    txComplEnd: cadUsrData.txComplEnd,
                }
            };
        }

        console.log(payload);

        try {

            const resposta = await postWS({
                sistema: "sgr",
                projeto: "mwizcontratacaobuild",
                acaoWebAExecutar: "processarnegocio",
                acaoAExecutar: "fazerNovaContratacao"
            }, payload);

            resposta.mensagens.forEach(msg => {

                if(msg.id === "ok") {

                    notification.success({message: "Sucesso", description: msg.mensagem});

                } else {

                    console.log(msg);

                }

            });

            onRequestClose();

            onContratoAdicionado();

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e);

        }

    }

    const onCurrentStepChange = (position, currentKey) => {

        if(currentKey==="stepCadEmp") {
            formRefEmpresaApi.current?.onPageShow();
        }

    };

    const onSelecionaPlano = (plano) => {

        dsPlanoCtrVenda.filter(item => item.selecionado === true).map(item => {
            item.selecionado = false;
            return item;
        })

        plano.selecionado = true;

        setDsPlanos(dsPlanoCtrVenda.clone());

        setPlanoSelecionado(plano);

    }

    const stepFormPlano =
        <StepsForm.StepForm
            key={"stepPlano"}
            name = "stepPlano"
            title = "Plano acesso"
            stepProps = {{
                description: "Escolha o seu plano de acesso"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = { async ( ) => {
                if(planoSelecionado === null) {
                    notification.error({
                        message: 'Escolha um plano',
                        description:
                            'Você deve escolher um plano de assinatura para prosseguir !',
                    });
                    return false ;
                } else {
                    return true ;
                }
            }}
        >
            <StepPlanos
                dataSource={dsPlanos}
                onSelecionaPlano={onSelecionaPlano}
            />
        </StepsForm.StepForm>
    ;

    const onInfoCertificado = (value) => {
        setInfoCertificado(value);
    }

    const stepFormCertDig =
        <StepsForm.StepForm
            key={"stepCertDig"}
            name = "stepCertDig"
            title = "Certificado digital"
            stepProps = {{
                description: "Enviar o certificado digital da nova empresa"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                return true;
            }}
        >
            <StepCertDigital appConfig = { props.appConfig }
                             formRef = { formRef }
                             onInfoCertificado = { onInfoCertificado }
                             semAceiteDosTermos = { true }
            />
        </StepsForm.StepForm>
    ;

    const formRefEmpresa = useRef() ;
    const formRefEmpresaApi = useRef();

    const stepFormEmpresa =
        <StepsForm.StepForm
            formRef = { formRefEmpresa }
            key={"stepCadEmp"}
            name = "stepCadEmp"
            title = "Dados empresa"
            stepProps = {{
                description: "Preenchimento de informações da empresa",
                disabled: false
            }}
            initialValues={{
                flgOptaSimples: "N",
                flgMEI: "N"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                if(await formRefEmpresaApi.current?.onFinish() === false) return false;
                const all = formRefEmpresa.current?.getFieldsValue();
                setCadEmpData(all);
                return true;
            }}
            onValuesChange = {async (changed, all) => {
                formRefEmpresaApi.current?.onValuesChange(changed, all);
            }}
        >
            <StepCadEmpresa
                api = { formRefEmpresaApi }
                formRef = { formRefEmpresa }
                certificado = { infoCertificado }
                exibeMeiosContato = { true }
            />
        </StepsForm.StepForm>
    ;

    const wizSteps =  <>
            {stepFormPlano}
            {stepFormCertDig}
            {stepFormEmpresa}
        </>
    ;

    return (
        <ReactModal
            isOpen={exibePopUp}
            className={"modalContent"}
            overlayClassName={"modalOverlay"}
            bodyOpenClassName={"popUpWizNovoContrato"}
            closeTimeoutMS={500}
            onRequestClose={closePopUp}
            style={{
                content: {
                    left: MARGIN_LEFT + 'px',
                    width: MODAL_CONTENT_WIDTH + "px",
                    top: MODAL_CONTENT_TOP + "px"
                }
            }}
            onAfterOpen={() => {
                onAfterOpen();
            }}
            onAfterClose={() => {
                onAfterClose();
            }}
        >
            <div className={"modal-header"}>
                <label>Fazer nova contratação</label>
                <Space align={"baseline"} style={{float:"right"}}>
                    <Button onClick={closePopUp}>
                        Cancelar
                    </Button>
                </Space>
            </div>
            <div className={"box-body"}>
                <SinglePageLoadingIndicator />
                <UploadLoadingIndicator area={"uploadCertificado"}/>
                <IY2BProFormWizard
                    formRef = { formRef }
                    onFinishWizard = { onFinishWizard }
                    onCurrentStepChange = { onCurrentStepChange }
                    stepsSize = "small"
                    steps = { wizSteps }
                >
                </IY2BProFormWizard>
            </div>
            <div className={"modal-footer"}>
            </div>
        </ReactModal>
    );

}


export default WizNovoContrato;
